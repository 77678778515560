import { Box, Button, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import produce from "immer";
import * as billActions from "../../../actions/bill";
import * as bookingActions from "../../../actions/booking";
import * as clientActions from "../../../actions/client";
import {
  getBillNumberType,
  getTemplateBillItem,
  hasFiscalYear2079Started,
  mergeServicesProducts
} from "../../../actions/helpers/billHelper";
import * as serviceActions from "../../../actions/services";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { tl } from "../../../components/translate";
import useIpdBeds from "../../../hooks/useIpdBeds";
import { BillItemType, DocumentTypes, VisitType } from "../../../interfaces/BillInterfaces";
import { CanActions } from "../../../interfaces/policy";
import { IThunkDispatch, RootState, store } from "../../../store";
import "./BillEditor.scss";
import BillHeader from "./BillHeader";
import BillItems from "./BillItems";
import { generateSubItems } from "./BillProductItem";
import BillSummary, { paymentOptionsEnum } from "./BillSummary";
import BillActions from "./BilllEditorActions";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { RequestForm, getIpdRequestForm } from "../../../api/requestForms";
import { notificationAdd } from "../../../actions/notification";
import { productsGetByIds } from "../../../api/stockProducts";
import QrPaymentPanel from "./Payment/QrPaymentPanel";
import { qrPaymentMethods } from "../../ResourceCentre/ModuleSettings/QrPayment/QrPaymentInfoForm";
import { QrPaymentMethodsEnum } from "../../../enum/billing";

const useStyles = makeStyles((theme) => ({
  itemsSummaryContainer: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
      overflowX: "scroll"
    }
  },
  itemsSummaryWrapper: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "32px"
    }
  }
}));

export const LOCALSTORAGE_BILL = "billDraft";

export enum BILL_EDITOR_WARNING_TYPE {
  COUNTER = "COUNTER"
}

export interface BillEditorWarning {
  message: string;
  type: BILL_EDITOR_WARNING_TYPE | null;
}

const BillEditor = ({
  actions,
  draft,
  mode,
  billId,
  products,
  clientId,
  bookingId,
  booking,
  visitType,
  bedId,
  ipdId,
  labRequestId,
  productMedicationRequestId,
  purchaseRequisitionFormId,
  currentCounter,
  setCurrentCounter,
  setIsSettingsOpen,
  isExtraReferrerEnabled
}) => {
  const hasBillItems = (billItems) => billItems?.some((billItem) => Boolean(billItem.description));
  const dispatch = useDispatch();
  const unsavedBill = React.useRef(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const rcSettings = useSelector((s: RootState) => s.resources.resourceCentres[0]?.settings) || {};
  const isCounterBillingEnabled = rcSettings?.billingSettings?.enableCounterBilling || false;
  const addBillItemButtonRef = React.useRef<HTMLAnchorElement>(null);
  const [productMedicationRequest, setProductMedicationRequest] =
    React.useState<RequestForm | null>(null);

  const [canPayWithQr, setCanPayWithQr] = React.useState(false);
  const [openQrPaymentPanel, setOpenQrPaymentPanel] = React.useState(false);
  const [qrValue, setQrValue] = React.useState<Record<string, string> | null>(null);
  const [qrS3Url, setQrS3Url] = React.useState<string | null>(null);
  const resourceCentre = useSelector((state: RootState) => state.userContext?.resourceCentre);

  const focusAddItemButton = () => {
    addBillItemButtonRef.current?.focus();
  };

  React.useEffect(() => {
    if (resourceCentre?.id) {
      setQrS3Url(null);
      setQrValue(null);
      const draftPaymentMethod = draft?.paymentInfo?.paymentMethod?.toLocaleLowerCase();

      // Check if payment method selected in draft is in qrPaymentMethods
      const isQPaymentSupported = qrPaymentMethods.some(
        (qr) =>
          qr.value.toLocaleLowerCase() === draftPaymentMethod ||
          // eslint-disable-next-line max-len
          (draftPaymentMethod === paymentOptionsEnum.bankTransfer.toLocaleLowerCase() &&
            qr.value === QrPaymentMethodsEnum.BANK)
      );

      if (!isQPaymentSupported) {
        setCanPayWithQr(false);
        return;
      }

      // check if resourceCentre has qr image for selected payment method
      const qrImage =
        resourceCentre?.qrPaymentInfo?.[
          draftPaymentMethod === paymentOptionsEnum.bankTransfer.toLocaleLowerCase()
            ? QrPaymentMethodsEnum.BANK
            : draftPaymentMethod.toLocaleLowerCase()
        ]?.images[0];

      const isQrImageAvailable = qrImage && qrImage?.url && qrImage?.key;

      if (isQrImageAvailable) {
        // do something
        setCanPayWithQr(true);
        setQrS3Url(qrImage.url);
        return;
      }

      // Check if resourceCentre has qrPaymentInfo for selected payment method
      const qrInfo = resourceCentre?.qrPaymentInfo?.[draftPaymentMethod]?.credentials[0];
      const isQrValueAvailable = qrInfo && qrInfo?.name && qrInfo?.id;

      if (isQrValueAvailable) {
        setCanPayWithQr(true);
        /**
         * Set qrValue based on payment method
         * Esewa and Khalti have different naming convention to store qrValue
         * follow the following format
         * {"eSewa_id":"9823457827","name":"Balaram Thapa"}
         * {"Khalti_ID": "9823457827", "name": "Balaram Thapa"}
         */
        if (draftPaymentMethod === QrPaymentMethodsEnum.ESEWA) {
          setQrValue({ eSewa_id: qrInfo.id, name: qrInfo.name });
        }
        if (draftPaymentMethod === QrPaymentMethodsEnum.KHALTI) {
          setQrValue({ Khalti_ID: qrInfo.id, name: qrInfo.name });
        }
      } else {
        setCanPayWithQr(false);
      }
    }
  }, [draft?.paymentInfo?.paymentMethod, resourceCentre]);

  React.useEffect(() => {
    if (isCounterBillingEnabled) {
      const billCounter = localStorage.getItem("billCounter") || "";
      setCurrentCounter(billCounter);
    }
  }, [isCounterBillingEnabled]);
  const lastItemRef = React.useRef<HTMLInputElement>(null);
  const isMobile = useMobileScreen();
  const beds = useIpdBeds();
  const [labRequest, setLabRequest] = React.useState<RequestForm>(null);
  React.useEffect(() => {
    if (draft && draft.visitType !== visitType) {
      actions.updateDraft({ ...draft, visitType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitType]);

  React.useEffect(() => {
    (async () => {
      if (purchaseRequisitionFormId) {
        await dispatch(
          billActions.createBillFromPurchaseRequisitionForm(purchaseRequisitionFormId)
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseRequisitionFormId]);

  React.useEffect(() => {
    if (draft && draft.type === DocumentTypes.CREDITNOTE && clientId) {
      actions.clearDraft().then(() => actions.initDraft(clientId));
    }
    if (!billId && !draft && !purchaseRequisitionFormId) {
      actions.initDraft(clientId, visitType, bedId, beds, ipdId);
    }
    if (billId && mode === "edit") {
      actions.loadBillForEdit(billId);
    }
    if (draft && clientId && draft.client.id !== clientId) {
      actions.initDraft(clientId, visitType, bedId, beds, ipdId);
    }
    actions.loadServices().then(async () => {
      if (bookingId) {
        actions.fetchBooking(bookingId);
      }
      if (labRequestId) {
        try {
          const reqDetails = await getIpdRequestForm(labRequestId);
          setLabRequest(reqDetails);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Failed to get selected lab request for bill.",
              autoTimeout: false
            })
          );
        }
      }

      if (productMedicationRequestId) {
        try {
          const reqDetails = await getIpdRequestForm(productMedicationRequestId);
          setProductMedicationRequest(reqDetails);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error.message || "Failed to get selected medication request for bill.",
              autoTimeout: false
            })
          );
        }
      }
    });

    const incompleteBill = JSON.parse(localStorage.getItem(LOCALSTORAGE_BILL));

    if (
      incompleteBill &&
      mode === "create" &&
      incompleteBill.draft.type !== DocumentTypes.CREDITNOTE &&
      !hasBillItems(draft?.billItems) &&
      (!clientId || clientId === incompleteBill.draft.client.id)
    ) {
      unsavedBill.current = incompleteBill;
      setShowDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (bookingId && booking && draft) {
      actions.applyProductsFromBooking(draft, booking, products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);
  React.useEffect(() => {
    if (labRequest && draft && labRequestId) {
      actions.applyServicesFromLabRequest(draft, labRequest, products);
    }
    if (productMedicationRequest && draft) {
      actions.applyProductsFromLabRequest(draft, productMedicationRequest);
    }
  }, [labRequest, productMedicationRequest]);

  React.useEffect(() => {
    if (mode === "create" && hasBillItems(draft?.billItems)) {
      localStorage.setItem(
        LOCALSTORAGE_BILL,

        JSON.stringify({
          draft
        })
      );
    }
  }, [draft, mode]);

  const [warning, setWarning] = React.useState<BillEditorWarning>({ message: "", type: null });

  React.useEffect(() => {
    let timerRef;
    if (warning.message) {
      timerRef = setTimeout(() => {
        setWarning({ message: "", type: null });
      }, 10000);
    }
    return () => timerRef && clearTimeout(timerRef);
  }, [warning]);

  React.useEffect(() => {
    if (draft?.issueDate && hasFiscalYear2079Started()) {
      const billNumberType = getBillNumberType(draft);
      actions.getNextBillNumber(billNumberType, draft.issueDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.issueDate, draft?.type, actions]);

  React.useEffect(() => {
    if (draft && bedId && draft?.bedId && draft?.visitType === VisitType.IPD) {
      actions.updateDraft({
        ...draft,
        bedId: +bedId,
        bed: beds.find((bed) => bed.id === +bedId)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bedId, draft?.bedId, beds.length, draft?.client?.id]);

  const classes = useStyles();
  return (
    <Box className="okahti-billEditor">
      {showDialog && (
        <OkhatiDialog
          title={tl("billing.recoverUnsavedBill")}
          description={`Unsaved bill found for ${unsavedBill.current.draft.client.firstName} ${unsavedBill.current.draft.client.lastName}. Do you want to recover it?`}
          next={() => {
            if (unsavedBill.current.draft?.visitType === VisitType.IPD) {
              dispatch(push(`/billing/ipd`));
            } else {
              dispatch(push(`/billing/new`));
            }
            actions.updateDraft({ ...unsavedBill.current.draft, isLocalStorageData: true });
            setShowDialog(false);
          }}
          cancel={() => {
            localStorage.removeItem(LOCALSTORAGE_BILL);

            setShowDialog(false);
          }}
          readMode={false}
        />
      )}
      <Box px={isMobile ? 2 : 4}>
        {draft && (
          <BillHeader
            isExtraReferrerEnabled={isExtraReferrerEnabled}
            isCreditNote={draft.type === DocumentTypes.CREDITNOTE}
            draft={draft}
            updateDraft={actions.updateDraft}
            visitType={visitType}
            bedId={bedId}
          />
        )}
      </Box>
      {draft && (
        <>
          <Box className={classes.itemsSummaryContainer}>
            <Box className={classes.itemsSummaryWrapper}>
              <BillItems
                lastItemRef={lastItemRef}
                focusAddItemButton={focusAddItemButton}
                isCreditNote={draft.type === DocumentTypes.CREDITNOTE}
                draft={draft}
                updateDraft={actions.updateDraft}
                onRowRemove={actions.onRowRemove}
                products={products}
                setWarningMsg={setWarning}
              />
              <Collapse in={Boolean(warning.message)}>
                <Box component="div" padding="16px 32px" borderRadius="4">
                  <Alert
                    severity="error"
                    variant="outlined"
                    action={
                      <Button
                        onClick={() => {
                          setWarning({ message: "", type: null });
                        }}
                        size="medium"
                      >
                        ok
                      </Button>
                    }
                  >
                    <Box>
                      <Typography component="span" mr={1}>
                        {warning.message}
                      </Typography>
                      {warning.type === BILL_EDITOR_WARNING_TYPE.COUNTER && (
                        <Typography
                          component="span"
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          onClick={() => setIsSettingsOpen(true)}
                        >
                          Open Settings
                        </Typography>
                      )}
                    </Box>
                  </Alert>
                </Box>
              </Collapse>
              <BillSummary
                ref={addBillItemButtonRef}
                lastItemRef={lastItemRef}
                isCreditNote={draft.type === DocumentTypes.CREDITNOTE}
                draft={draft}
                onItemAdd={actions.onItemAdd}
                updateDraft={actions.updateDraft}
              />
            </Box>
          </Box>
          <BillActions
            draft={draft}
            isCreditNote={draft.type === DocumentTypes.CREDITNOTE}
            setWarningMsg={setWarning}
            currentBillCounter={currentCounter}
            qr={{
              canPayWithQr,
              setOpenQrPaymentPanel
            }}
          />

          {/* pay with qr panel */}
          {draft && canPayWithQr && openQrPaymentPanel && (
            <QrPaymentPanel
              draft={draft}
              setWarningMsg={setWarning}
              currentBillCounter={currentCounter}
              qrValue={qrValue}
              qrS3Url={qrS3Url}
              handleViewClose={() => setOpenQrPaymentPanel(false)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default connect(
  (
    { bills, services, bookings, stockProducts, userContext }: RootState,
    { bookingId }: { bookingId: number }
  ) => {
    const canAccessServiceList = ((userContext.policies || {}) as CanActions)[
      "services:listService"
    ];
    const canAccessStockList = ((userContext.policies || {}) as CanActions)["stock:listStock"];
    return {
      draft: bills.draft,
      products: mergeServicesProducts(
        canAccessServiceList ? services.collection : [],
        canAccessStockList ? stockProducts.collection : []
      ),
      booking: bookingId ? bookings.collection.find(({ id }) => id === bookingId) : {}
    };
  },
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadBillForEdit: (billId) => dispatch(billActions.loadBillForEdit(billId)),
      initDraft: async (clientId, visitType, bedId, beds, ipdId) => {
        const { userContext } = store.getState();
        dispatch(
          clientActions.getWalkInClient(userContext.resourceCentreId, (draft, client) => {
            if (draft && !clientId) {
              dispatch(billActions.updateDraftAction({ ...draft, client, visitType }));
              dispatch(billActions.getClientBalance(client.id));
            }
          })
        );
        const { clients } = store.getState();
        const hasSelectedClient = clients.collection?.some((client) => client.id === clientId);
        if (!hasSelectedClient && clientId) {
          await dispatch(clientActions.getClientById(clientId));
        }
        await dispatch(
          billActions.initDraft({
            clientId,
            visitType,
            bedId,
            bed: beds.find((bed) => bed.id === +bedId),
            ipdId
          })
        );
        dispatch((dispatchInner, getState) => {
          const { draft } = getState().bills;
          if (draft.isDraft) {
            const billNumberType = getBillNumberType(draft);
            dispatchInner(billActions.getNextBillNumber(billNumberType, draft.issueDate));
          }
        });
      },
      getNextBillNumber: async () => {
        dispatch((dispatchInner, getState) => {
          const { draft } = getState().bills;
          if (draft.isDraft) {
            const billNumberType = getBillNumberType(draft);
            dispatchInner(billActions.getNextBillNumber(billNumberType, draft.issueDate));
          }
        });
      },
      clearDraft: async () => dispatch(billActions.clearDraft()),
      onItemAdd: () => dispatch(billActions.addNewRow()),
      updateDraft: (draft) => {
        dispatch(billActions.updateDraftAction(draft));
      },
      updateSettings: (settings) => {
        dispatch(billActions.updateDraftSettings(settings));
      },
      onRowRemove: (idx) => dispatch(billActions.removeRow(idx)),
      loadServices: async () => {
        await Promise.all([dispatch(serviceActions.getServices())]);
      },
      loadCustomers: () => dispatch(clientActions.getClients()),
      navigateTo: (url) => dispatch(push(url)),
      fetchBooking: (bookingId) => dispatch(bookingActions.getBooking(bookingId)),
      applyProductsFromBooking: (draft, booking, products) => {
        if (!booking || !booking.services) {
          return;
        }
        const newDraft = cloneDeep(draft);
        newDraft.referredBy = booking.referredBy;
        newDraft.referrerId = booking.referrerId;
        newDraft.billItems = [];
        booking.services.forEach((serviceId, idx) => {
          const product = products.find((p) => Number(p.id) === Number(serviceId));
          let serviceProviderId = null;
          if (product.productType === "single") {
            serviceProviderId = product?.document?.rates?.find(
              (item) => item.serviceProviderId === booking.serviceProviderId
            )?.serviceProviderId;
          }

          if (product) {
            const billItem = {
              ...getTemplateBillItem(),
              sNo: 1 + idx,
              description: product.name,
              calculationBasis: "perUnit",
              perUnit: product.grossTotalPrice,
              productType: product?.productType,
              productId: product.id,
              ...(serviceProviderId ? { serviceProviderId } : {}),
              serviceProviderRateUnit: product.serviceProviderRateUnit,
              ...(product?.document?.products && product.productType === "package"
                ? generateSubItems(product?.document?.products, products, booking.serviceProviderId)
                : {})
            };
            newDraft.billItems.push(billItem);
          }
        });
        if (!newDraft.billItems.length) newDraft.billItems = cloneDeep(draft.billItems);
        dispatch(billActions.updateDraftAction(newDraft));
      },
      applyServicesFromLabRequest: (draft, labRequest, services) => {
        if (!labRequest || !labRequest.requestedItems?.length) {
          return;
        }
        const newDraft = cloneDeep(draft);
        newDraft.billItems = [];
        labRequest.requestedItems.forEach((service, idx) => {
          const product = services?.find((p) => Number(p.id) === Number(service.id));
          if (product) {
            const billItem = {
              ...getTemplateBillItem(),
              sNo: 1 + idx,
              description: product.name,
              calculationBasis: "perUnit",
              perUnit: product.grossTotalPrice,
              productId: product.id,
              salesLedgerId: product.salesLedgerId,
              departmentId: product.departmentId,
              salesTaxationId: product.salesTaxationId,
              productData: {
                rates: product.document.rates
              },
              serviceProviderRateUnit: product.serviceProviderRateUnit,
              ...(product.products && product.productType === "package"
                ? generateSubItems(product?.document?.products, services, null)
                : {})
            };
            newDraft.billItems.push(billItem);
          }
        });
        if (!newDraft.billItems.length) newDraft.billItems = cloneDeep(draft.billItems);
        dispatch(billActions.updateDraftAction(newDraft));
      },
      applyProductsFromLabRequest: async (draft, labRequest) => {
        const productIds = labRequest.requestedItems.map((item) => item.id);
        const products = await productsGetByIds(productIds);
        const billItems = [] as BillItemType[];
        products.forEach((product, idx) => {
          const billItem = {
            ...getTemplateBillItem(),
            sNo: 1 + idx,
            description: product.name,
            calculationBasis: "perUnit",
            perUnit: product.grossTotalPrice,
            productId: product.id,
            salesLedgerId: product.salesLedgerId,
            departmentId: product.departmentId,
            salesTaxationId: product.salesTaxationId,
            productData: {
              rates: product.rates
            },
            serviceProviderRateUnit: product.serviceProviderRateUnit
          };
          billItems.push(billItem);
        });

        const newDraft = produce(draft, (draftItem) => {
          draftItem.billItems = billItems;
        });
        dispatch(billActions.updateDraftAction(newDraft));
      }
    }
  })
)(BillEditor);
