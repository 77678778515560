import React from "react";
import { Box, Typography } from "@mui/material";
import { instructionVideos } from "../../constants";

export default function InstructionVideos(): JSX.Element {
  return (
    <div>
      <Box display="flex" gap={3} flexWrap="wrap">
        {instructionVideos.map((video) => (
          <Box key={video.title} flexBasis="40%">
            <Typography mb={1}>{video.title}</Typography>
            <iframe
              src={video.url}
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay; encrypted-media; picture-in-picture;"
            />
          </Box>
        ))}
      </Box>
    </div>
  );
}
