import "./styles.scss";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Typography } from "@mui/material";

import List from "../../components/List";
import { t, tl } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import PageControl from "../../components/PageControl";
import useMobileScreen from "../../hooks/useMobileScreen";
import { DaybookInterface } from "../../interfaces/Daybook";
import { notificationAdd } from "../../actions/notification";
import { CalendarFunctions } from "../../components/Calendar";
import { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";

interface DaybookListProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  selectedRow: Partial<DaybookInterface> | null;
  setSelectedRow: Dispatch<SetStateAction<Partial<DaybookInterface | null>>>;
  daybookData: { results: DaybookInterface[]; total: number };
  isListForReport?: boolean;
}

const docColumns = () => [
  t("daybook.date"),
  t("daybook.particulars"),
  t("daybook.transactionType"),
  t("daybook.amount")
];

const docRowProcessor = ({
  // eslint-disable-next-line camelcase
  created_at,
  particulars,
  transactionType,
  amount,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
Partial<DaybookInterface & { __meta__row_type: string }>) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    // eslint-disable-next-line camelcase
    CalendarFunctions.bsFullDate(created_at as string) || "-",
    particulars || "-",
    transactionType || "-",
    amount || "-"
  ];
};

export default function DaybookList({
  selectedRow,
  setSelectedRow,
  page,
  setPage,
  daybookData,
  isListForReport = false
}: DaybookListProps): JSX.Element {
  const pageSize = 20;
  const dispatch = useDispatch();
  const isMobileScreen = useMobileScreen();

  const customButtonGroup = (
    <Button variant="contained" onClick={() => dispatch(push("/daybook/create"))}>
      {isMobileScreen ? tl("create") : tl("daybook.create")}
    </Button>
  );

  return (
    <Box
      height={isListForReport ? "100%" : "calc(100% - 30px)"}
      className={isListForReport ? "daybookReportList" : "daybookList"}
    >
      <List
        data={daybookData?.results || []}
        rowHeight={50}
        hideCreateButton
        withoutSearch
        isResponsive
        defaultSortOrder={!isListForReport ? -1 : 1}
        activeRow={selectedRow && selectedRow.id}
        onRowClick={(row) => {
          // id -ve is for custom rows which is not clickable
          if (Number(row.id) < 0) return;
          if (isListForReport) {
            if (selectedRow && Number(selectedRow.id) === Number(row.id)) {
              setSelectedRow(null);
            } else {
              setSelectedRow(row as Partial<DaybookInterface>);
            }
            return;
          }
          const unselect = selectedRow && Number(selectedRow.id) === Number(row.id);
          setSelectedRow(unselect ? null : (row as Partial<DaybookInterface>));
          if (unselect) {
            dispatch(push(`/daybook`));
          } else {
            dispatch(push(`/daybook/${row.id}`));
          }
        }}
        customButtonGroup={!isListForReport ? customButtonGroup : undefined}
        columns={[
          {
            key: "created_at",
            label: tl("daybook.date"),
            sortable: true,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>
                  {" "}
                  {row.created_at === ""
                    ? row.created_at
                    : convertADtoBS(new Date(row.created_at)).formatted4}
                </Box>
              </Typography>
            ),
            segmentable: !!isListForReport,
            segmentBy: (row) => convertADtoBS(new Date(row.created_at)).formatted4
          },
          {
            key: "particulars",
            label: tl("daybook.particulars"),
            sortable: false,
            formatter: (row) => (
              <Typography component="div" variant="body2" textTransform="capitalize">
                <Box>{row.particulars}</Box>
              </Typography>
            )
          },
          {
            key: "transactionType",
            label: tl("daybook.transactionType"),
            sortable: false,
            formatter: ({ transactionType }) => (
              <Typography component="div" variant="body2">
                <Box textTransform="capitalize">{transactionType || "-"}</Box>
              </Typography>
            )
          },
          {
            key: "amount",
            label: tl("daybook.amount"),
            sortable: false,
            formatter: (row) => (
              <Typography component="div" variant="body2">
                <Box>{row.amount}</Box>
              </Typography>
            )
          }
        ]}
        segementSummaryRenderer={(acc) => (
          <Box width="100%" bgcolor="#e6e6e6" display="flex" px="20px" alignItems="center">
            <Typography fontWeight={600}>{acc.segment}</Typography>
          </Box>
        )}
      >
        <EmptyView>
          <Box textAlign="center" padding="50px">
            No items to show.
          </Box>
        </EmptyView>
        {isListForReport && (
          <ListActions>
            {({ getProcessedData }) => (
              <Menu>
                <MenuItem
                  onClick={async () => {
                    try {
                      await downloadExcel(
                        t("reports.daybookReport", "en"),
                        formatHeaderForExcel(docColumns()),
                        formatDataForExcel(getProcessedData(), docRowProcessor)
                      );
                    } catch (err) {
                      dispatch(
                        notificationAdd({
                          id: new Date().getUTCMilliseconds(),
                          variant: "error",
                          message: "Failed to download Excel.",
                          autoTimeout: true
                        })
                      );
                    }
                  }}
                >
                  {tl("reports.excel")}
                </MenuItem>
              </Menu>
            )}
          </ListActions>
        )}
      </List>

      {!isListForReport && (
        <Box className="labNavigatePage">
          <PageControl
            page={page}
            onSetPage={(val) => {
              setPage(val);
            }}
            pageSize={pageSize}
            maximumDataCount={daybookData.total || 0}
          />
        </Box>
      )}
    </Box>
  );
}
