import LocationCityIcon from "@mui/icons-material/LocationCity";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Typography } from "@mui/material";
import { startCase } from "lodash";
import * as moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { connect, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { separateByComma } from "../../../helpers/formatters";
import { getAppBaseUrl } from "../../../helpers/urls";
import useGetDepartments from "../../../hooks/department";
import usePrintStyles from "../../../hooks/usePrintStyles";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { clientFullNameSelector } from "../../../reducers/client";
import { RootState } from "../../../store";
import { getCustomerNumber } from "../ClientList";

const getQRUrl = (clientUid: string): string => `${getAppBaseUrl()}/clients/${clientUid}`;

export const getSpFullName = (sp: ServiceProvider): string =>
  startCase(`${sp.title || ""} ${sp?.firstName || ""} ${sp?.lastName || ""}`);

const PrintClientInfoSticker = ({ clientData, resourceCentre, rcLogo }) => {
  const currentDateAndTime = moment().format();
  const BSDate = convertADtoBS(moment.utc(currentDateAndTime.slice(0, 10))).formatted;
  const currentTime = currentDateAndTime.slice(11, 16);
  const fullName = clientFullNameSelector(clientData).toUpperCase();

  const serviceProviders: ServiceProvider[] = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  const serviceProvider = serviceProviders.find((sp) => sp.id === clientData.lastHandledSpId);

  usePrintStyles({ pageStyle: "size: 3.375in 2.125in; margin: 5mm" }).runPrintStyles();

  const printRef = React.useRef();

  function getClientGender(gender) {
    if (gender === "1") return "M";
    if (gender === "2") return "F";
    return "";
  }

  function getClientAge(dob: moment.MomentInput) {
    return moment().diff(dob, "years") > 0
      ? ` ${moment().diff(dob, "years")} years`
      : ` ${moment().diff(dob, "months")} months`;
  }
  const departments = useGetDepartments({ showDeletedDepartments: true });
  const spDepartment =
    departments.find(({ id }) => serviceProvider?.departmentId === id)?.name || null;
  return (
    <div>
      <ReactToPrint
        trigger={() => <Button variant="contained">Print</Button>}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printIframe">
        <Box ref={printRef} p="3mm">
          <Box display="flex" justifyContent="space-between">
            <div>
              <Box display="flex">
                <PermIdentityIcon fontSize="small" />
                <Typography
                  style={{
                    fontWeight: "bolder",
                    marginLeft: "1.3229mm",
                    fontSize: "3mm",
                    fontFamily: "Verdana"
                  }}
                >
                  {fullName}
                  {clientData.dob && `, ${getClientAge(clientData.dob)}`}
                  {clientData.gender && `/${getClientGender(clientData.gender)}`}
                </Typography>
              </Box>
              <Typography
                style={{
                  fontSize: "2.1167mm",
                  marginLeft: "5.8208mm",
                  fontFamily: "Verdana"
                }}
              >
                Customer No. {getCustomerNumber(clientData.customerNumber)}
              </Typography>
              {(clientData.address || clientData.city) && (
                <>
                  <Box display="flex" marginTop="0.793mm">
                    <LocationCityIcon sx={{ fontSize: 13 }} />
                    <Typography
                      style={{
                        marginLeft: "1.3229mm",
                        fontSize: "2.8mm",
                        fontFamily: "Verdana"
                      }}
                    >
                      {separateByComma(clientData.address, clientData.city)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box display="flex" marginTop="1mm">
                <PhoneIcon sx={{ fontSize: 13 }} />
                <Typography
                  style={{
                    marginLeft: "1.3229mm",
                    fontSize: "2.8mm",
                    fontFamily: "Verdana"
                  }}
                >
                  {clientData.phone}
                </Typography>
              </Box>
              {serviceProvider && (
                <Box marginTop="1.552mm">
                  <Box display="flex">
                    <PersonIcon fontSize="small" />
                    <Typography
                      fontWeight="600"
                      fontSize="3mm"
                      style={{
                        marginLeft: "1.3229mm",
                        fontFamily: "Verdana"
                      }}
                    >
                      {getSpFullName(serviceProvider)}
                    </Typography>
                  </Box>

                  <Typography
                    fontSize="2.1167mm"
                    style={{
                      fontFamily: "Verdana",
                      marginLeft: "5.5mm"
                    }}
                  >
                    {serviceProvider?.qualification}{" "}
                    {serviceProvider?.speciality && `, ${serviceProvider.speciality}`}
                  </Typography>

                  <Typography
                    fontSize="2.1167mm"
                    style={{
                      fontFamily: "Verdana",
                      marginLeft: "5.5mm"
                    }}
                  >
                    {serviceProvider?.registrationNumber && serviceProvider.registrationNumber}
                  </Typography>
                  {serviceProvider?.departmentId && spDepartment && (
                    <Typography
                      fontSize="2.1167mm"
                      style={{
                        fontFamily: "Verdana",
                        marginLeft: "5.5mm"
                      }}
                    >
                      {spDepartment}
                    </Typography>
                  )}
                </Box>
              )}
              <Typography
                style={{
                  fontSize: "2.1167mm",
                  marginTop: "7.937mm",
                  fontFamily: "Verdana"
                }}
              >
                {separateByComma(resourceCentre.shortName, resourceCentre.address)}
              </Typography>
              <Typography style={{ fontSize: "2.1167mm", fontFamily: "Verdana" }}>
                {BSDate} {currentTime}
              </Typography>
            </div>
            <div>
              {rcLogo && (
                <img
                  src={rcLogo}
                  style={{
                    marginRight: "0.8cm",
                    height: "1.2cm",
                    width: "1.2cm"
                  }}
                  alt="resourceCentre-logo"
                />
              )}
              <Box mt={4} mr={4}>
                <QRCodeSVG
                  style={{ marginTop: "1.00mm", fontFamily: "Verdana" }}
                  size={43}
                  value={getQRUrl(clientData.uniqueId || "")}
                />
              </Box>
            </div>
          </Box>
        </Box>
      </iframe>
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    resourceCentre: state?.userContext?.resourceCentre,
    rcLogo:
      state.resources.resourceCentres.length &&
      state.resources.resourceCentres[0].resourceCentreLogo?.s3ResourceURL
  }),
  null
)(PrintClientInfoSticker);
