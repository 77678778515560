import { startCase } from "lodash";
import { round } from "mathjs";
import * as moment from "moment";
import { t } from "../components/translate";
import { rupeeDisplay } from "./rupee";

export const ageFormatter = (date: Date): string | null => {
  if (!date) {
    return null;
  }
  const age = moment().diff(moment(date), "months");
  const year = age / 12;
  const month = age % 12;
  const yearString = `${parseInt(year, 10)} ${t("year")}`;
  return yearString + (month ? ` ${month} ${t("month")}` : "");
};

export const dateWithZeroBeforeSingleDigits = (date: string): string => {
  const dateItems = date.split("/");
  const formattedDateItems = dateItems.map((item) => {
    const formattedItem = item.length === 1 ? `0${item}` : item;
    return formattedItem;
  });
  return formattedDateItems.join("/");
};

export const getDisplayAgeFromDOB = (dob: Date | string): string => {
  if (!dob) return "";
  const ageInYears = moment().diff(dob, "years");
  const ageInMonths = moment().diff(dob, "months");
  if (ageInMonths === 0) return `${moment().diff(dob, "days")} DAYS`;
  if (ageInYears === 0) return `${moment().diff(dob, "months")} MONTHS`;

  const remainingMonths = ageInMonths % 12;
  return `${ageInYears} Y ${remainingMonths ? `${remainingMonths} M` : ""}`;
};

export const separateByComma = (x: string, y: string): string => {
  if (x && y) return `${x}, ${y}`;
  return x || y;
};

export const getFullName = (obj: { firstName: string; lastName: string }): string =>
  startCase(`${obj?.firstName || ""} ${obj?.lastName || ""}`);

export const getValue = (val: string | number | null): string | number => val || "";

export const roundOffAndDisplayInRupee = (value: number): string =>
  rupeeDisplay(round(value || 0, 2));

export const toStartCase = (text: string): string => {
  if (!text) return "";
  return text
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};
