import * as React from "react";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import { Box, Typography, Grid, Divider, Tooltip } from "@mui/material";
import * as moment from "moment";
import { chain, round } from "mathjs";
import CheckIcon from "@mui/icons-material/Check";
import startCase from "lodash/startCase";
import { push } from "connected-react-router";
import PrintIcon from "@mui/icons-material/Print";
import produce from "immer";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MessageIcon from "@mui/icons-material/Message";
import { tl, t } from "../../../components/translate";
import { clientFullNameSelector } from "../../../reducers/client";
import BillItems from "../BillItemsShow";
import { rupeeDisplay } from "../../../helpers/rupee";
import { separateByComma } from "../../../helpers/formatters";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import BillActions from "../BillActions/BillActions";
import styles from "./BillShow.module.css";
import PaymentInfoAction from "./PaymentInfoAction";
import { loadBill as loadBillAction } from "../../../actions/bill";
import Panel from "../../../components/Panel";
import { BillStatus, BillType, DocumentTypes } from "../../../interfaces/BillInterfaces";
import HistoryItems from "../../History/HistoryItems";
import { StyledTabs, StyledTab } from "../../../components/StyledTabs";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";

import PaymentHistoryPrint from "../PrintBill/PrintPaymentHistory/PrintPaymentHistory";
import CommissionPanel from "../Comission/ComissionPanel";
import { IThunkDispatch, RootState } from "../../../store";
import BillHistoryCancellation from "./BillHistoryCancellation";
import useLabIdsFromBills from "../../../hooks/useLabIdsFromBills";
import { getCustomerNumber } from "../../Client/ClientList";
import CopyToClipboard from "../../../components/ClipboardCopy/ClipboardCopy";
import { getAppBaseUrl } from "../../../helpers/urls";
import BillCreditNoteList from "../BillCreditNoteList";
import ParentBill from "../ParentBill";

const Grid4 = ({ children }) => (
  <Grid xs={4} lg={4} item>
    <Typography>
      <Box component="span" fontSize="12px" fontWeight={500}>
        {children}
      </Box>
    </Typography>
  </Grid>
);
const Grid8 = ({
  dataTestmation,
  children,
  style = {}
}: {
  dataTestmation?: string;
  children: React.ReactNode;
  style?: { [key: string]: string };
}) => (
  <Grid sx={{ ...style }} xs={8} lg={8} item data-testmation={dataTestmation}>
    <Typography>
      <Box component="span" fontSize="12px" fontWeight={600}>
        {children}
      </Box>
    </Typography>
  </Grid>
);

Grid8.defaultProps = {
  style: {}
};

const switchTabRenderer = (activeTab, bill, setShowMessagePanel, isForKitchenPharmacy) => {
  switch (activeTab) {
    case "billItems":
      return bill.document ? (
        <Box className="billItemsShow">
          <BillItems bill={bill} />
        </Box>
      ) : null;
    case "billHistory":
      return (
        <Box>
          <HistoryItems entity="bill" entityId={bill.id} />
        </Box>
      );
    case "billCreditNotes":
      return (
        <Box className="creditNotesShow">
          <BillCreditNoteList
            setShowMessagePanel={setShowMessagePanel}
            billId={bill.id}
            isForKitchenPharmacy={isForKitchenPharmacy}
          />
        </Box>
      );
    default:
      throw new Error(`Unknown activeTab: ${activeTab}`);
  }
};

function filterCustomProducts(bill) {
  return produce(bill, (draft) => {
    if (draft.document.billItems) {
      draft.document.billItems = draft.document.billItems
        .filter((item) =>
          item.subItems
            ? item.subItems.some((subItem) => Boolean(subItem.productData))
            : Boolean(item.productData)
        )
        .map((item) =>
          item.subItems
            ? { ...item, subItems: item.subItems.filter((subItem) => Boolean(subItem.productData)) }
            : item
        );
    }
  });
}

interface Props {
  bill: BillType;
  billId: number;
  loadBill: (id: number, billData: BillType, isForKitchenPharmacy?: boolean) => void;
  handleViewClose: () => void;
  hideActions: boolean;
  isCommissionSubscribed: boolean;
  hideBillingActions: boolean;
  setShowMessagePanel: (value: boolean) => void;
  wrapperStyle?: { minWidth: string; maxWidth: string };
  billData: BillType;
  isForKitchenPharmacy?: boolean;
}

const BillShow = ({
  bill,
  billId,
  loadBill,
  handleViewClose,
  hideActions,
  isCommissionSubscribed,
  hideBillingActions = false,
  setShowMessagePanel,
  wrapperStyle = { minWidth: "820px", maxWidth: "820px" },
  billData,
  isForKitchenPharmacy = false
}: Props) => {
  const [activeTab, setActiveTab] = React.useState("billItems");
  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [commissionOpen, setCommissionOpen] = React.useState(false);
  const [showParentBill, setShowParentBill] = React.useState(false);

  React.useEffect(() => {
    if (!bill?.document) {
      loadBill(billId, billData || null, isForKitchenPharmacy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId, bill]);

  React.useEffect(() => {
    setShowParentBill(false);
  }, [billId]);

  const labIdsForBill = useLabIdsFromBills(bill);
  if (!bill) {
    return null;
  }

  const summary = bill.document?.summary || bill.summary;
  const client = bill?.client || bill.document?.client;

  // do not show record payment link for bills
  // 1. if no receivable due is left, including credit note
  // 2. if it is a draft
  // 3. if it is in read only mode
  const maxReceivableDue = chain(bill.summary?.totalAmount)
    .add(bill.related?.summary?.totalAmount || 0)
    .subtract(bill.paymentInfo?.paidAmount || 0)
    .done();
  const hideRecordPayment = bill.document
    ? bill.status === BillStatus.cancelled ||
      Boolean(bill.isDraft || hideActions || bill.paymentInfo?.paid || maxReceivableDue <= 0)
    : true;

  return (
    <Panel
      onClose={handleViewClose}
      wrapperStyle={wrapperStyle}
      messageButton={
        bill.uuid && (
          <Tooltip title="Send Email" aria-label="message">
            <MessageIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setShowMessagePanel(true);
              }}
            />
          </Tooltip>
        )
      }
      genericButton={
        bill.uuid && (
          <CopyToClipboard
            TooltipProps={{
              title: "Bill link is copied to clipboard!"
            }}
          >
            {({ copy }) => (
              <Tooltip title="Copy link" aria-label="copylink">
                <FileCopyIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    copy(`${getAppBaseUrl()}/my-bill/${bill.uuid}`);
                  }}
                />
              </Tooltip>
            )}
          </CopyToClipboard>
        )
      }
    >
      <Box className={styles.boxHeight}>
        {!hideActions && (
          <BillActions
            bill={bill}
            propActions={
              isCommissionSubscribed ? { onCommission: () => setCommissionOpen(true) } : {}
            }
            hideBillingActions={isForKitchenPharmacy ? true : hideBillingActions}
            isKitchenPharmacyBill={isForKitchenPharmacy}
          />
        )}
        <Box padding="24px 24px 32px 24px">
          {!bill.isDraft && (
            <Box display="flex" mt="16px">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {bill.type === DocumentTypes.CREDITNOTE
                  ? tl("billing.creditNoteNo")
                  : tl("billing.billNumber")}
              </Typography>
              <Typography>{bill.billNumber}</Typography>
            </Box>
          )}

          <Box display="flex" mt="16px">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Customer Name</Typography>
            <Typography
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setShowClientInfoPanel(true);
              }}
            >
              {clientFullNameSelector(client).toUpperCase()}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Customer No.</Typography>
            <Typography>{getCustomerNumber(bill.customerNumber)}</Typography>
          </Box>
          <Box display="flex">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Phone</Typography>
            <Typography>{client.phone}</Typography>
          </Box>
          {client.email && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>Email</Typography>
              <Typography>{client.email}</Typography>
            </Box>
          )}
          {(client.address || client.city) && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>Address</Typography>
              <Typography>
                {separateByComma(capitalize(client.address), capitalize(client.city))}
              </Typography>
            </Box>
          )}
          {bill.insuranceNumber && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.insuranceNumber")}
              </Typography>
              <Typography>{bill.insuranceNumber}</Typography>
            </Box>
          )}
          {bill.claimNumber && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.claimNumber")}
              </Typography>
              <Typography>{bill.claimNumber}</Typography>
            </Box>
          )}

          <Box marginBottom="16px" marginTop="16px">
            <Grid container>
              <Grid4>{tl("billing.issueDate")}</Grid4>
              <Grid8>{calFns.convertADtoBS(moment(bill.issueDate)).formatted4}</Grid8>
            </Grid>
            {bill.document?.referredBy && (
              <Grid container>
                <Grid4>Referrer</Grid4>
                <Grid8>{bill.document.referredBy.toUpperCase()}</Grid8>
              </Grid>
            )}
            {bill.document?.extraReferrer && (
              <Grid container>
                <Grid4>Extra Referrer</Grid4>
                <Grid8>{bill.document.extraReferrer.name.toUpperCase()}</Grid8>
              </Grid>
            )}

            {labIdsForBill && (
              <Grid container>
                <Grid4>{tl("billing.labId")}</Grid4>
                <Grid8>{labIdsForBill}</Grid8>
              </Grid>
            )}

            {bill.referenceTo && (
              <Grid container>
                <Grid4>
                  {bill.type === DocumentTypes.CREDITNOTE
                    ? tl("billing.creditNoteForBill")
                    : tl("billing.creditNoteBill")}
                </Grid4>
                <Grid8>
                  <Typography
                    style={{ cursor: "pointer", textDecoration: "underline", fontSize: "12px" }}
                    onClick={() => {
                      if (bill.type === DocumentTypes.CREDITNOTE) {
                        setShowParentBill(true);
                      }
                    }}
                  >
                    {bill.related?.billNumber}
                  </Typography>
                </Grid8>
              </Grid>
            )}
            <Grid container>
              {Boolean(summary.taxAmount) && (
                <>
                  <Grid4>{tl("billing.taxAmount")}</Grid4>
                  <Grid8>{rupeeDisplay(summary.taxAmount)}</Grid8>
                </>
              )}
              {Boolean(summary.discount) && (
                <>
                  <Grid4>{tl("billing.discount")}</Grid4>
                  <Grid8>
                    {summary.discountType === "percent"
                      ? `${summary.discountPct} %`
                      : rupeeDisplay(summary.discount)}
                  </Grid8>
                </>
              )}
              <>
                <Grid4>{tl("billing.total")}</Grid4>
                <Grid8>{rupeeDisplay(bill.total)}</Grid8>
              </>
              <>
                <Grid4>{tl("billing.inWords")}</Grid4>
                <Grid8>{summary.inWords}</Grid8>
              </>
              {bill?.paymentInfo?.paymentMethod && (
                <>
                  <Grid4>{tl("billing.paymentMethod")}</Grid4>
                  <Grid8>{startCase(bill.paymentInfo?.paymentMethod)}</Grid8>
                </>
              )}

              {!bill.isDraft && (!bill.paymentInfo || bill.paymentInfo?.paid === true) && (
                <Grid xs={12} item>
                  <Grid4>
                    {tl("billing.label.paidAll")} <CheckIcon color="primary" />
                  </Grid4>
                </Grid>
              )}
              {bill.type !== DocumentTypes.CREDITNOTE && (
                <>
                  {!Number.isNaN(bill.paymentInfo?.paidAmount) &&
                    Boolean(
                      chain(summary.totalAmount)
                        .subtract(bill.paymentInfo?.paidAmount || 0)
                        .done()
                    ) && (
                      <>
                        <Grid4>{tl("billing.dueAmount")}</Grid4>
                        <Grid8 dataTestmation="dueAmount">
                          {rupeeDisplay(
                            round(
                              chain(summary.totalAmount)
                                .subtract(bill.paymentInfo?.paidAmount || 0)
                                .done(),
                              2
                            )
                          )}
                        </Grid8>
                      </>
                    )}
                  {!Number.isNaN(maxReceivableDue) && bill.related && (
                    <>
                      <Grid4>{tl("billing.dueAmountAfterCreditNote")}</Grid4>
                      <Grid8>{rupeeDisplay(maxReceivableDue < 0 ? 0 : maxReceivableDue)}</Grid8>
                    </>
                  )}
                  {!Number.isNaN(bill.paymentInfo?.paidAmount) && (
                    <>
                      <Grid4>{tl("billing.paidAmount")}</Grid4>
                      <Grid8 dataTestmation="paidAmount">
                        {rupeeDisplay(
                          bill.paymentInfo?.paidAmount ||
                            (!bill.paymentInfo?.paidAmount && bill.paymentInfo?.paid
                              ? summary.totalAmount
                              : 0)
                        )}
                        {!hideRecordPayment && (
                          <Grid xs={12} item>
                            <Grid8>
                              <PaymentInfoAction showDisc bill={bill} />
                            </Grid8>
                          </Grid>
                        )}
                      </Grid8>
                    </>
                  )}
                </>
              )}
              {Boolean(bill?.paymentInfo?.tenderAmount) && (
                <>
                  <Grid4>{tl("billing.tenderAmount")}</Grid4>
                  <Grid8>{rupeeDisplay(bill.paymentInfo.tenderAmount.toFixed(2))}</Grid8>
                  <Grid4>{tl("billing.changeAmount")}</Grid4>
                  <Grid8>{rupeeDisplay(bill.paymentInfo.changeAmount.toFixed(2))}</Grid8>
                </>
              )}
              {Boolean(bill.receipts) && Boolean(bill.receipts.length) && (
                <>
                  <Grid4>{tl("billing.receiptHistory")}</Grid4>
                  <Grid8>
                    {[...bill.receipts]
                      .sort(
                        (a, b) =>
                          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                      )
                      .map(
                        (
                          { id, receivedOn, paidAmount, paymentMethod, remarks, cancelled },
                          idx
                        ) => (
                          <Box mb={1} key={id}>
                            {idx ? <Divider /> : ""}
                            <Box
                              marginTop="10px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box flexBasis={200} fontWeight={500}>
                                {rupeeDisplay(round(paidAmount, 2))}
                              </Box>
                              <Box flexBasis={150} fontWeight={500}>
                                {calFns.convertADtoBS(moment(receivedOn)).formatted4}
                              </Box>
                              <Box flexBasis={150} fontWeight={500}>
                                {(paymentMethod || "").toUpperCase()}
                              </Box>
                              <Box flexBasis={100} fontWeight={500}>
                                <PaymentHistoryPrint
                                  billData={bill}
                                  receivedOn={receivedOn}
                                  paidAmount={paidAmount}
                                  paymentMethod={paymentMethod}
                                  remarks={remarks}
                                  buttonText={
                                    <PrintIcon htmlColor="black" style={{ cursor: "pointer" }} />
                                  }
                                  mode="recordPayment"
                                />
                              </Box>
                              <Box width="100px">
                                <BillHistoryCancellation
                                  paymentMethod={paymentMethod}
                                  billStatus={bill.status}
                                  billType={bill.type}
                                  cancelled={cancelled}
                                  receiptInfo={{
                                    receiptId: id,
                                    paidAmount,
                                    billId: bill.id,
                                    paymentMethod,
                                    receivedOn: new Date().toISOString()
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box flexBasis={200} fontWeight={500}>
                              {remarks}
                            </Box>
                          </Box>
                        )
                      )}
                  </Grid8>
                </>
              )}
            </Grid>
          </Box>
          {bill.document?.remarks && (
            <>
              <Divider />
              <Box>
                <Grid4>{tl("billing.remarks")}</Grid4>
                <Grid8>{bill.document.remarks}</Grid8>
              </Box>
            </>
          )}
          <StyledTabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
            <StyledTab label={t("bill.billItems")} value="billItems" />
            <StyledTab label={t("bill.history")} value="billHistory" />
            <StyledTab label={t("bill.creditNotes")} value="billCreditNotes" />
          </StyledTabs>
          {switchTabRenderer(activeTab, bill, setShowMessagePanel, isForKitchenPharmacy)}
        </Box>
      </Box>
      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={client.id}
            mode="edit"
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
          />
        ) : (
          <ClientInfo
            wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
            id={client.id}
            handleViewClose={() => setShowClientInfoPanel(false)}
            setEditMode={setClientEditMode}
            stayOnCurrentPage
          />
        ))}
      {commissionOpen && (
        <CommissionPanel
          selectedBill={filterCustomProducts(bill)}
          handleClose={() => setCommissionOpen(false)}
        />
      )}

      {showParentBill && (
        <ParentBill
          bill={bill}
          setShowParentBill={setShowParentBill}
          isForKitchenPharmacy={isForKitchenPharmacy}
          setShowMessagePanel={setShowMessagePanel}
        />
      )}
    </Panel>
  );
};

export default connect(
  (state: RootState, ownProps: { billId: number }) => ({
    clients: state.clients.collection,
    bill: state.bills.collection.find(({ id }) => id === ownProps.billId),
    isCommissionSubscribed:
      state.userContext?.resourceCentre.subscriptions.features.commission.subscribed
  }),
  (dispatch: IThunkDispatch) => ({
    loadBill: (billId, billData, isForKitchenPharmacy) =>
      dispatch(loadBillAction(billId, billData, isForKitchenPharmacy)),
    navigateTo: (url) => dispatch(push(url))
  })
)(BillShow);
