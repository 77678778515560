import React from "react";
import { Box, Typography } from "@mui/material";
import { scaleFont } from "../LabPrintFunctions";

export interface InfoFieldProps {
  label: string;
  data: JSX.Element | string | number;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
}

const InfoField: React.FC<InfoFieldProps> = ({ label, data, labelStyle = {}, valueStyle = {} }) => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.33cm"),
    color: "black",
    fontWeight: 400,
    width: "2.8cm"
  };
  const baseValueStyle = {
    flex: 1,
    fontSize: scaleFont("0.33cm"),
    fontWeight: 500
  };
  return (
    <Box display="flex" alignItems="flex-start">
      <Typography style={{ ...baseLabelStyle, ...labelStyle }}>{label}</Typography>
      <Typography style={{ ...baseValueStyle, ...valueStyle }}>{data}</Typography>
    </Box>
  );
};

InfoField.defaultProps = {
  labelStyle: {},
  valueStyle: {}
};

export default InfoField;
