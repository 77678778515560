import { Alert, Box, Button, TextField } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import classNames from "../../../helpers/classNames";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { BillItemType } from "../../../interfaces/BillInterfaces";
import { StockProducts } from "../../../interfaces/StockInterfaces";
import BatchInfoPopup from "./BatchInfoPopup";
import styles from "./BillEditor.module.css";
import { addNewRow } from "../../../actions/bill";

interface BillQuantityProps {
  item: BillItemType;
  column: number;
  isCreditNote: boolean;
  batchOptions: Array<StockProducts>;
  showBatchPopup: boolean;
  setShowBatchPopup: (option: boolean) => void;
  updateBillItem: (billItem, batchOptions?) => void;
  selectedBatchAvailableQuantity: number;
  selectedStockProducts: Array<StockProducts>;
  setSelectedStockProducts: (stockProducts) => void;
  notEnoughQuantityMsg: string;
  setNotEnoughQuantityMsg: (message) => void;
  infoText: string;
  setInfoText: (value: string) => void;
  quantityRef: HTMLInputElement;
}

const BillQuantity: React.FC<BillQuantityProps> = ({
  item,
  column,
  isCreditNote,
  batchOptions,
  showBatchPopup,
  setShowBatchPopup,
  updateBillItem,
  selectedBatchAvailableQuantity,
  selectedStockProducts,
  setSelectedStockProducts,
  notEnoughQuantityMsg,
  setNotEnoughQuantityMsg,
  infoText,
  setInfoText,
  quantityRef
}) => {
  const isMobileScreen = useMobileScreen();
  const isQuantityFocused = () => quantityRef?.current === document.activeElement;
  const billedItemRef = React.useRef(item);

  const dispatch = useDispatch();
  useHotkeys(
    "enter",
    () => {
      if (isQuantityFocused()) {
        dispatch(addNewRow());
      }
      setShowBatchPopup(false);
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  return (
    <Box>
      <BatchInfoPopup
        infoText={infoText}
        setInfoText={setInfoText}
        updateBillItem={updateBillItem}
        showBatchPopup={showBatchPopup}
        setShowBatchPopup={setShowBatchPopup}
        batchOptions={batchOptions}
        quantityRef={quantityRef}
        selectedStockProducts={selectedStockProducts}
        item={item}
        handleSwitchBatch={(batch) => {
          updateBillItem(
            {
              ...item,
              quantity: 1,
              batchInfo: { batchId: batch.batchId, quantity: 1, expiryDate: batch.expiryDate },
              perUnit: Number(batch.unitPriceExcVAT)
            },
            batchOptions
          );
          setSelectedStockProducts((prevState) =>
            prevState.map((stock) => {
              if (stock.batchId === item.batchInfo.batchId) {
                return batch;
              }
              return stock;
            })
          );
        }}
      />
      {(notEnoughQuantityMsg || item.showAdjustAvailableQtyMsg) && (
        <Alert
          className={classNames(styles.alertRoot, { [styles.mobileRoot]: isMobileScreen })}
          severity="warning"
          action={
            <Button
              onClick={() => {
                setNotEnoughQuantityMsg("");
                updateBillItem({ ...item, showAdjustAvailableQtyMsg: false });
              }}
              variant="outlined"
              color="inherit"
              size="small"
            >
              Got it!
            </Button>
          }
        >
          {notEnoughQuantityMsg ||
            `This stock quantity has been updated to match the available quantity.`}
        </Alert>
      )}
      <TextField
        data-testmation="billQuantityInput"
        inputRef={quantityRef}
        type="number"
        value={item[column]}
        disabled={isCreditNote ? !item.returned : false}
        InputProps={{ classes: { input: styles.rowInputRoot } }}
        margin="none"
        variant="outlined"
        onFocus={(e) => {
          e.target.select();
          if (isCreditNote) return;
          if (item?.batchInfo?.batchId && batchOptions?.length) {
            setShowBatchPopup(true);
          }
          if (batchOptions?.length === 0) {
            setNotEnoughQuantityMsg("This product is currently out of stock.");
          }
        }}
        onChange={(e) => {
          let quantity = Number(e.target.value);
          if (!isCreditNote) {
            if (batchOptions?.length === 0) {
              quantity = 0;
              setNotEnoughQuantityMsg("This product is currently out of stock.");
            }
            if (selectedBatchAvailableQuantity && selectedBatchAvailableQuantity < quantity) {
              quantity = selectedBatchAvailableQuantity;
              setNotEnoughQuantityMsg("Selected batch does not have enough quantity.");
            }
          } else if (isCreditNote) {
            const billedQuantityInUnit =
              billedItemRef.current.stockUnitType === "unit"
                ? billedItemRef.current.quantity
                : (billedItemRef.current.stockProductUnitsPerPackage || 1) *
                  billedItemRef.current.quantity;

            const selectedQuantityInUnit =
              item.stockUnitType === "unit"
                ? quantity
                : (item.stockProductUnitsPerPackage || 1) * quantity;

            if (Math.abs(selectedQuantityInUnit) > Math.abs(billedQuantityInUnit)) {
              setNotEnoughQuantityMsg("Cannot return more quantity than sold");
              quantity =
                item.stockUnitType === "unit"
                  ? billedQuantityInUnit
                  : billedQuantityInUnit / (billedItemRef.current.stockProductUnitsPerPackage || 1);
            }
          }
          // setShowBatchPopup(true);
          updateBillItem({
            ...item,
            quantity,
            calculationBasis: "perUnit",
            batchInfo: { ...item.batchInfo, quantity }
          });
          if (item.batchInfo.batchId && batchOptions?.length) setShowBatchPopup(true);
          else setShowBatchPopup(false);
        }}
      />
    </Box>
  );
};

export default BillQuantity;
