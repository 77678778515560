import React from "react";
import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import Barcode from "react-barcode";
import moment from "moment-timezone";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import * as NotificationActions from "../../../actions/notification";
import { getBillByUUID } from "../../../api/bill";
import { BillTypeByUUID, DocumentTypes } from "../../../interfaces/BillInterfaces";
import { disclaimerText } from "../Editor/PrintPreview/DraftBill";
import { getSectionStyle, scaleFont } from "../../Lab/LabPrint/LabPrintFunctions";
import { BillClientInfo, checkWalkInCustomer, InfoField } from "../PrintBill/BillPrintHelpers";
import { getCustomerNumber } from "../../Client/ClientList";
import { dateWithZeroBeforeSingleDigits } from "../../../helpers/formatters";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import capitalizedName from "../../../helpers/nameCapitalizer";
import { tl } from "../../../components/translate";
import LabPrintLetterHeadCentered from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterheadCentered";
import LabPrintLetterHead from "../../Lab/LabPrint/LabprintComponents/LabPrintLetterhead";
import CorporateBillClientInfo from "../PrintBill/CorporatePrint/CorporateBillClientInfo";
import CorporateBillDescription from "../PrintBill/CorporatePrint/CorporateBillDescription";
import watermarkLogo from "../../../../assets/images/greenLogo.png";
import { getPrintTitle } from "../PrintBill/PrintBill";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";
import BillDescriptionV2 from "../PrintBill/BillDescriptionV2";
import logo from "../../../../assets/images/poweredByOkhati.png";

interface Props {
  match: {
    params: { uuid: string };
  };
}

const BillByUUID = ({ match }: Props): JSX.Element | null => {
  const printTemplateRef = React.useRef(null);
  const [billData, setBillData] = React.useState<BillTypeByUUID | null>(null);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getBillByUUID(match.params.uuid);
        setBillData(response);
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error.message || errorFetchMessage(MODULE.BILL),
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  if (!billData?.client) return null;

  const { resourceCentre, clientBalance } = billData;

  const showLetterHead = Boolean(
    resourceCentre.settings.printSettings.labPrintSettings.includeLetterhead
  );
  const { clinicNameFontScale, centralizedHeader } = resourceCentre.settings.printSettings;
  const showFooter = resourceCentre.settings.printSettings.billPrintSettings.includeFooter;
  const hideFooterCompletely =
    resourceCentre.settings.printSettings.billPrintSettings.hideFooterLogoCompletely;
  const headerColor =
    (resourceCentre.settings.printSettings.labPrintSettings.enableColor &&
      resourceCentre.settings.printSettings.labPrintSettings.color) ||
    "";
  const { customTitle, customSubtitle } = resourceCentre.settings.printSettings.billPrintSettings;
  const { panNo, vatRegistered: isVatRegistered } = resourceCentre.settings.billingSettings;
  // const {  } = resourceCentre.settings.printSettings.billPrintSettings;
  const hideRcLogo = Boolean(customTitle);

  let letterHeadComponent: JSX.Element | null = null;
  if (showLetterHead) {
    if (centralizedHeader) {
      letterHeadComponent = (
        <LabPrintLetterHeadCentered
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
        />
      );
    } else {
      letterHeadComponent = (
        <LabPrintLetterHead
          titleFontScale={clinicNameFontScale}
          hideRcLogo={hideRcLogo}
          resourceCentre={{
            ...resourceCentre,
            panNo,
            subtitle: customSubtitle
          }}
        />
      );
    }
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "800px", mx: "auto" }}>
      <Box sx={{ alignSelf: "self-end", mb: 2 }}>
        <ReactToPrint
          trigger={() => (
            <Button size="large" startIcon={<Print />} variant="contained">
              Print
            </Button>
          )}
          content={() => printTemplateRef.current}
        />
      </Box>
      <Box
        sx={{
          position: "relative",
          mb: 4,
          border: "1px solid grey"
        }}
      >
        <div ref={printTemplateRef}>
          <Box
            component="table"
            width="100%"
            className={classNames({ "water-marks": billData.isDraft })}
            waterMarkText={disclaimerText}
          >
            <tfoot>
              <tr>
                <td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <Box height="2cm" />
                </td>
              </tr>
            </tfoot>
            <thead>
              {!showLetterHead && (
                <tr>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              )}
              {showLetterHead && (
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).headerStyle }}>
                      {letterHeadComponent}
                    </Box>
                  </td>
                </tr>
              )}
            </thead>
            {billData.client.isCorporateClient ? (
              <tbody>
                <tr>
                  <td>
                    <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
                      <Box
                        m="0.3cm 0cm"
                        textAlign="center"
                        fontWeight={600}
                        textTransform="uppercase"
                      >
                        {getPrintTitle(billData.status, billData.type)}
                      </Box>

                      <Box
                        sx={{
                          pageBreakInside: "avoid",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          my: 3
                        }}
                      >
                        <div>
                          <Typography sx={{ fontSize: "1.25rem", color: "grey" }}>
                            Bill To
                          </Typography>
                          {billData.client && <CorporateBillClientInfo client={billData.client} />}
                        </div>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                            <InfoField
                              label={
                                billData.type === DocumentTypes.CREDITNOTE
                                  ? tl("billing.creditNoteNumber")
                                  : tl("billnumber")
                              }
                              data={<>{billData.isDraft ? "-" : billData.billNumber}</>}
                            />
                          )}
                          {billData.type === DocumentTypes.CREDITNOTE && (
                            <InfoField
                              label={tl("billing.creditNoteForBill")}
                              data={<>: {billData.related?.billNumber}</>}
                            />
                          )}
                          <InfoField
                            label={tl("billing.issueDate")}
                            data={
                              <>
                                {dateWithZeroBeforeSingleDigits(
                                  convertADtoBS(moment(billData.issueDate)).formatted
                                )}
                                {moment(billData.issueDate).format("h:mm a")}
                              </>
                            }
                          />
                          <InfoField
                            label={tl("billing.dateAD")}
                            data={
                              <>
                                {dateWithZeroBeforeSingleDigits(
                                  moment(billData.issueDate).format("YYYY/MM/DD")
                                )}
                              </>
                            }
                          />
                        </Box>
                      </Box>

                      <CorporateBillDescription
                        isVatRegistered={isVatRegistered}
                        clientBalance={clientBalance}
                        billData={billData}
                        relatedSettings={{
                          showReferrer: false,
                          isBordered: false,
                          headerColor
                        }}
                        SPs={[]}
                      />
                    </Box>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>
                    <Box sx={{ ...getSectionStyle(headerColor).bodyStyle }}>
                      <Box display="flex" marginBottom="0.8cm" width="100%" justifyContent="center">
                        <Typography
                          sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontSize: "0.45cm",
                            marginTop: "0.5cm",
                            marginBottom: "0.3cm",
                            fontWeight: 400
                          }}
                        >
                          {billData.type !== DocumentTypes.CREDITNOTE &&
                            getPrintTitle(billData.status, billData.type)}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        marginBottom="1cm"
                        justifyContent="space-between"
                        borderTop="1px solid black"
                        p="0.3cm"
                      >
                        <Box width="50%">
                          {checkWalkInCustomer(billData.client, "isWalkInCustomer") && (
                            <BillClientInfo
                              client={billData.client}
                              customerNumber={getCustomerNumber(billData.customer?.customerNumber)}
                            />
                          )}
                          <Barcode
                            value={billData.id.toString()}
                            width={2}
                            displayValue={false}
                            fontSize={5}
                            height={20}
                            margin={2}
                            font="arial"
                          />
                        </Box>

                        <Box width="40%">
                          <InfoField
                            label={
                              billData.type === DocumentTypes.CREDITNOTE
                                ? tl("billing.creditNoteNumber")
                                : tl("billnumber")
                            }
                            data={<>: {billData.billNumber}</>}
                          />
                          {billData.type === DocumentTypes.CREDITNOTE && (
                            <InfoField
                              label={tl("billing.creditNoteForBill")}
                              data={<>: {billData.related?.billNumber}</>}
                            />
                          )}
                          <InfoField
                            label={tl("billing.issueDate")}
                            data={
                              <>
                                :{" "}
                                {dateWithZeroBeforeSingleDigits(
                                  convertADtoBS(moment(billData.issueDate)).formatted
                                )}{" "}
                                {moment(billData.issueDate).format("h:mm a")}
                              </>
                            }
                          />
                          <InfoField
                            label={tl("billing.dateAD")}
                            data={
                              <>
                                :{" "}
                                {dateWithZeroBeforeSingleDigits(
                                  moment(billData.issueDate).format("YYYY/MM/DD")
                                )}{" "}
                              </>
                            }
                          />
                          {billData.document.referredBy && (
                            <InfoField
                              label={tl("booking.referredBy")}
                              data={<>: {billData.document.referredBy.toUpperCase()}</>}
                            />
                          )}

                          {billData.insuranceNumber && (
                            <InfoField
                              label={tl("billing.insuranceNumber")}
                              data={<>: {billData.insuranceNumber} </>}
                            />
                          )}
                          {billData.claimNumber && (
                            <InfoField
                              label={tl("billing.claimNumber")}
                              data={<>: {billData.claimNumber} </>}
                            />
                          )}
                        </Box>
                      </Box>
                      <BillDescriptionV2
                        hidePaymentMethodOnPrint={false}
                        billData={billData}
                        SPs={[]}
                        relatedSettings={{
                          showReferrer: false,
                          isBordered: false
                        }}
                        isVatRegistered={isVatRegistered}
                        clientBalance={clientBalance}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 4,
                          justifyContent: "space-between",
                          pageBreakInside: "void"
                        }}
                      >
                        <Typography
                          align="left"
                          component="span"
                          sx={{
                            textTransform: "uppercase",
                            fontSize: scaleFont("0.3cm"),
                            fontWeight: 600,
                            width: "7.5rem"
                          }}
                        >
                          Authorized by
                        </Typography>
                        {billData.document.enteredBy.signature && (
                          <img
                            src={billData.document.enteredBy.signature}
                            alt="Service Provider Signature"
                            width="180px"
                            style={{ objectFit: "contain" }}
                          />
                        )}
                        <Typography fontSize={scaleFont("0.33cm")} component="span">
                          {capitalizedName(billData.document.enteredBy.name)}
                        </Typography>
                      </Box>
                    </Box>
                  </td>
                </tr>
              </tbody>
            )}
          </Box>
          {showFooter ? (
            <Box
              sx={{
                ...getSectionStyle(headerColor).footerStyle,
                position: "absolute",
                "@media print": {
                  position: "fixed"
                },
                bottom: 0,
                borderTop: "1px solid #c2c2c2",
                height: "2cm",
                paddingTop: "0.5rem",
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse"
              }}
            >
              <Box>
                <img src={logo} alt="logo" height="35px" />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                "@media print": {
                  position: "fixed"
                },
                borderTop: "1px solid #c2c2c2",
                width: "100%"
              }}
            >
              <Box
                sx={{ paddingRight: "10mm", mt: 2, display: "flex", justifyContent: "flex-end" }}
              >
                {!hideFooterCompletely && <img src={logo} alt="logo" height="35px" />}
              </Box>
              <Box height="2cm" />
            </Box>
          )}
          {/* watermark */}
          {billData.client.isCorporateClient && (
            <img
              src={watermarkLogo}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0.1
              }}
              height="500px"
              width="500px"
              alt=""
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default BillByUUID;
