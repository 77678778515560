import React from "react";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import moment from "moment";
import produce from "immer";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import styles from "./PurchaseEntry.module.css";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { t, tl } from "../../../components/translate";
import SuppliersAutoSelect from "../../ResourceCentre/SupplierAutoSelect";
import {
  Entry,
  paymentOptionsEnum,
  Supplier,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import { getSupplierBalance } from "../../../api/suppliers";
import { RootState, store } from "../../../store";
import { getNextVoucherNumber } from "../../../api/accounts";
import { ChildGeneralLedger, VoucherCodes } from "../../../interfaces/Accounts";
import * as NotificationActions from "../../../actions/notification";
import { hasFiscalYear2079Started } from "../../../actions/helpers/billHelper";
import Modal from "../../../components/Modal/Modal";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../accounts/hooks";
import { supplierActions } from "../../../actions";
import AdBsDateInput from "../../../components/AdBsDateInput/AdBsDateInput";
import useKitchenPharmacy from "../../../hooks/useKitchenPharmacy";

interface HeaderInterface {
  supplierOptions: Supplier[];
  updateEntry: (entry: Entry) => void;
  entry: Entry;
}

const Header = ({ supplierOptions, updateEntry, entry }: HeaderInterface): JSX.Element => {
  const [selectedSupplier, setSelectedSupplier] = React.useState({} as Supplier);
  const [showSelect, setShowSelect] = React.useState(false);
  const { transactionType } = entry;
  const dispatch = useDispatch();
  const { isAccountSubscribed } = useSelector((state: RootState) => ({
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed,
    paymentMethods: state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment
  }));
  const [showLedgerSelect, setShowLedgerSelect] = React.useState<boolean>(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const { equityAndLiabilities } = extractSecondaryData(useCoaAll());
  const allLedgerOfEquityAndLiabilities = getAllChildGl(equityAndLiabilities);

  const supplierInvoiceNumRef: React.RefObject<HTMLElement> = React.useRef(null);
  React.useEffect(() => {
    (async () => {
      if (
        isAccountSubscribed &&
        hasFiscalYear2079Started() &&
        (entry.transactionType === TransactionType.PURCHASE ||
          entry.transactionType === TransactionType.PURCHASE_RETURN)
      ) {
        const type =
          entry.transactionType === TransactionType.PURCHASE ? VoucherCodes.PR : VoucherCodes.DN;
        try {
          await getNextVoucherNumber(type, entry.date);
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error.data?.message || "Sorry, something went wrong.",
              autoTimeout: true
            })
          );
        }
      }
    })();
  }, [entry.date, isAccountSubscribed, entry.transactionType, dispatch]);

  const querySupplierId = queryString.parse(window.location.search).sId;

  React.useEffect(() => {
    if (entry.supplierId || querySupplierId) {
      const sId = querySupplierId || entry.supplierId;
      const relatedSupplier = supplierOptions.find((supplier) => supplier.id === Number(sId));

      if (relatedSupplier) {
        setSelectedSupplier(relatedSupplier);
      }
    } else {
      setSelectedSupplier({} as Supplier);
    }
  }, [entry.supplierId, supplierOptions, querySupplierId]);

  const Label = ({ children }) => (
    <Typography component="span" style={{ display: "flex" }}>
      <Box
        component="span"
        display="flex"
        fontSize="0.83em"
        fontWeight={600}
        alignItems="center"
        width="100px"
        marginRight="16px"
      >
        {children}
      </Box>
    </Typography>
  );

  React.useEffect(() => {
    (async () => {
      try {
        if (entry?.supplierId && entry.transactionType === TransactionType.PURCHASE) {
          const response = await getSupplierBalance(entry.supplierId);
          if (Number(response?.dueAmount) > 0) {
            // store.getState() used
            // because above await call blocks execution
            // and the thread is resumed with reference to previous entry
            // and while calling updateEntry action below,
            // old state(which was persisted before the await call) is again supplied as new state
            const currentEntry: Entry = store.getState().stock?.entry;
            if (currentEntry) {
              const newState = produce(currentEntry, (draft) => {
                draft.paymentInfo.balance = response.dueAmount;
                draft.paymentInfo.cashPayment = 0;
                draft.paymentInfo.paidFromBalance = 0;
                draft.paymentInfo.fromBalance = true;
                draft.paymentType = paymentOptionsEnum.cash;
              });
              updateEntry(newState as Entry);
            }
          } else {
            const currentEntry = store.getState().stock?.entry;
            if (currentEntry) {
              const newState = produce(currentEntry as Entry, (draft) => {
                draft.paymentInfo = {
                  balance: 0,
                  fromBalance: false,
                  paidFromBalance: 0,
                  cashPayment: 0
                };
              });
              updateEntry(newState);
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.supplierId]);

  const isKitchenPharmacyPurchaseEntry = useSelector(
    (state: RootState) =>
      state.stock?.entry?.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
  );
  const kitchenPharmacy = useKitchenPharmacy();

  React.useEffect(() => {
    if (!entry?.kitchenPharmacyId && isKitchenPharmacyPurchaseEntry && kitchenPharmacy) {
      updateEntry({
        ...entry,
        kitchenPharmacyId: kitchenPharmacy.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchenPharmacy, isKitchenPharmacyPurchaseEntry]);

  if (isKitchenPharmacyPurchaseEntry && kitchenPharmacy) {
    return (
      <Box m={2} display="flex" gap={1}>
        <Typography fontWeight="bold">Send Request To: </Typography>
        <div>
          <Typography>{kitchenPharmacy.name}</Typography>
          <Typography fontSize="10px" fontWeight="light" color="gray">
            {kitchenPharmacy.mobileNumber}
          </Typography>
        </div>
      </Box>
    );
  }

  return (
    <Box>
      {![
        TransactionType.INTERNAL_USE,
        TransactionType.INTERNAL_RETURN,
        TransactionType.OPENING_STOCK,
        TransactionType.EDIT_OPENING_STOCK,
        TransactionType.ADJUSTMENT
      ].includes(transactionType as TransactionType) && (
        <>
          <Typography style={{ fontWeight: 600, fontSize: 14, marginTop: "10px" }}>
            {tl("stock.supplier")}
          </Typography>
          <Box>
            {selectedSupplier != null && (!selectedSupplier.name || showSelect) && (
              <SuppliersAutoSelect
                error={!selectedSupplier?.name}
                onChange={(val) => {
                  if (!val) {
                    setShowSelect(true);
                    setSelectedSupplier({} as Supplier);
                    return;
                  }
                  setSelectedSupplier(val);
                  setShowSelect(false);
                  updateEntry({
                    ...entry,
                    supplierId: val.id,
                    transactionType,
                    supplierLedgerId: val.ledgerId
                  });
                  if (supplierInvoiceNumRef?.current) {
                    supplierInvoiceNumRef.current.focus();
                  }
                }}
              />
            )}
            {selectedSupplier?.name && !showSelect && (
              <div>
                <Box alignItems="center" display="flex" fontSize={12} fontWeight={600}>
                  {selectedSupplier.name}
                  {isAccountSubscribed && !entry.supplierLedgerId && (
                    <Alert
                      action={
                        <Button
                          onClick={() => setShowLedgerSelect(true)}
                          variant="outlined"
                          color="inherit"
                          size="small"
                        >
                          Map Ledger
                        </Button>
                      }
                      severity="warning"
                    >
                      This supplier is not mapped to any Account Gl.
                    </Alert>
                  )}
                  <Button size="small" onClick={() => setShowSelect(true)}>
                    Change
                  </Button>
                </Box>
                <Modal
                  open={showLedgerSelect}
                  title="Select Ledger for Supplier"
                  footer={
                    <>
                      <Button
                        onClick={() => {
                          setShowLedgerSelect(false);
                          setSelectedLedger(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!selectedLedger}
                        onClick={async () => {
                          if (selectedLedger) {
                            try {
                              dispatch(
                                supplierActions.putSupplier({
                                  id: entry.supplierId,
                                  ledgerId: selectedLedger.id
                                })
                              );
                              updateEntry({ ...entry, supplierLedgerId: selectedLedger.id });
                              dispatch(
                                NotificationActions.notificationAdd({
                                  id: new Date().getUTCMilliseconds(),
                                  variant: "success",
                                  message: "Successfully mapped with ledger.",
                                  autoTimeout: true
                                })
                              );
                              setShowLedgerSelect(false);
                              setSelectedLedger(null);
                            } catch (error) {
                              dispatch(
                                NotificationActions.notificationAdd({
                                  id: new Date().getUTCMilliseconds(),
                                  variant: "error",
                                  message: "Sorry ! Supplier is not mapped with ledger.",
                                  autoTimeout: true
                                })
                              );
                            }
                          }
                        }}
                      >
                        Save
                      </Button>
                    </>
                  }
                >
                  <LedgerSelect
                    options={allLedgerOfEquityAndLiabilities || []}
                    selected={selectedLedger}
                    onChange={(value) => setSelectedLedger(value)}
                  />
                </Modal>
              </div>
            )}
          </Box>
        </>
      )}

      {transactionType?.includes("purchase") && (
        <Box display="flex" alignItems="center">
          <Label>{tl("stock.supplierInvoiceNumber")}</Label>
          <Box marginTop={2} fontWeight={500}>
            <TextField
              data-testmation="stockInvoiceNumber"
              inputRef={supplierInvoiceNumRef}
              error={!entry.supplierInvoiceId}
              style={{ width: 140 }}
              disabled={false}
              placeholder={t("stock.invoiceNumber")}
              InputProps={{ classes: { input: styles.invoice } }}
              margin="none"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 16 }}
              value={entry.supplierInvoiceId}
              variant="outlined"
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                updateEntry({ ...entry, supplierInvoiceId: e.target.value });
              }}
            />
          </Box>
        </Box>
      )}
      {isAccountSubscribed
        ? entry.date &&
          entry.voucherDate && (
            <>
              <Box display="flex" mt="20px">
                <Label>{tl("stock.supplierInvoiceDate")}</Label>
                <AdBsDateInput
                  value={entry.voucherDate}
                  onChange={(newDate) => {
                    updateEntry({ ...entry, voucherDate: moment(newDate).toISOString() });
                  }}
                />
              </Box>
              <Box display="flex" m="10px 0">
                <Label>{tl("accounts.voucherDate")}</Label>
                <AdBsDateInput
                  value={entry.date}
                  onChange={(newDate) => {
                    updateEntry({ ...entry, date: moment(newDate).toISOString() });
                  }}
                />
              </Box>
            </>
          )
        : entry.date && (
            <Box display="flex">
              <Label>{tl("stock.date")}</Label>
              <CalendarDropdown
                disable={false}
                TextFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  margin: "dense"
                }}
                date={new Date(entry.date)}
                onChange={(newDate) => {
                  updateEntry({ ...entry, date: moment(newDate).toISOString() });
                }}
              />
            </Box>
          )}
    </Box>
  );
};

export default Header;
