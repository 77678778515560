import { Bed, Ward } from "../api/wards";
import { PassportInfo } from "../models/Client";
import { Client } from "./ClientInterface";
import { Medicine } from "./AssessmentInterfaces";

export interface IpdPatientRecord {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phoneNumber: string;
}

export enum IpdTypes {
  emergency = "Emergency",
  maternity = "Maternity",
  regular = "Regular"
}

export interface IpdRecord {
  referredBy: string;
  provisionalDiagnosis: string;
  hopi: string;
  admissionCondition: string;
  case: IpdTypes;
  progressRecord?: Array<ProgressRecord>;
  authorizedPersonnelInfo: AuthorizedPersonnelInfo;
  doctorInCharge: DoctorInCharge;
  dischargeSummary?: DischargeSummary | MaternityDischargeSummary;
  patientRelatedInformation: IpdPatientRecord;
}

export interface IPD {
  id: number;
  ipdNumber: number;
  dateOfAdmission: Date;
  dateOfDischarge: Date;
  referenceNumber: string;
  bedNumber: string;
  wardNumber: string;
  roomType: string;
  status: IpdStatus;
  patientFullName?: string;
  prescribedMedications: Array<MedicationSummary>;
  clientId: number;
  expectedLengthOfStay: string;
  finalDiagnosis: string;
  emergencyContact: EmergencyContact;
  ipdRecord: IpdRecord;
  resourceCentreId: number;
  createdAt: string;
  bedId: number;
  wardId: number;
  active: boolean;
  ward: Partial<Ward>;
  bed: Partial<Bed>;
  client: Client;
  bedName: string;
  authorizedPersonnelId: number;
  doctorInChargeId: number;
}

export enum IpdStatus {
  Admitted = "admitted",
  Discharged = "discharged"
}
export interface ProgressResult {
  cured: string;
  recovered: string;
  unchanged: string;
  worsened: string;
  lettAgainstMedicalAdvice: string;
  expired: string;
  broughtDead: string;
  absconded: string;
}

export interface IMedicine {
  brand: string;
  code: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  form: string;
  genericCode: string;
  genericId: number;
  genericName: string;
  id: number;
  source: string;
  strength: string;
  isFromStock?: boolean;
}

export interface ProgressRecord {
  diagnosis?: Diagnosis;
  examination?: string;
  treatmentProvided?: string;
  operationSummary?: string;
  medicationSummary?: Array<IMedicine>;
  id: number;
  vitals?: Array<IProgressRecordVital>;
  remarks?: string;
  diet?: string;
  allergy?: string;
}

export interface ProgressRecordVital {
  reading: string;
  name: string;
  unit: string;
}

export interface DischargeSummary {
  id: number;
  ipdCase: string;
  conditionDuringDischarge: string;
  instructionsOnDischarge: string;
  progressResult: string;
  remarks: string;
  finalDiagnosis: { diagnosis: string };
  authorizedPersonnelId: number;
  doctorInChargeId: number;
  dischargePrintTitle?: string;
  provisionalDiagnosis: string;
  hopi: string;
  prescribedMedicineOnDischarge?: MedicationSummary[] | Medicine[];
}

export interface MaternityDischargeSummary extends DischargeSummary, MaternityType {}

export interface EmergencyContact {
  name: string;
  phone: string;
  relation: string;
}

export enum PrescriptionContext {
  IPD = "IPD",
  DISCHARGE = "DISCHARGE"
}

export interface MedicationSummary extends IMedicine {
  id: number;
  prescriptionContext: PrescriptionContext;
}

export interface MaternityType {
  procedures: string;
  dateOfDelivery: string;
  weightOfBaby: string;
}

export interface AuthorizedPersonnelInfo {
  id: number;
  fullname: string;
  title: string;
  signature: string;
}

export interface DoctorInCharge {
  id: number;
  title: string;
  fullname: string;
  specialities: Array<number>;
  signature: string;
}

export interface IpdObjectClient {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  active: boolean;
  email: string;
  sendEmail: boolean;
  dob: string;
  gender: string;
  registrationNo: string;
  ipdNo: string;
  passportInfo: PassportInfo;
  address: string;
  displayName: string;
  nationality: string;
}

export interface IProgressRecordVital {
  reading: string;
  name: string;
  unit: string;
  extraInfo?: string;
}

interface Diagnosis {
  diagnosis?: string;
  icdCode: string;
}
