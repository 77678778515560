/* eslint-disable jsx-a11y/anchor-is-valid */
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneIcon from "@mui/icons-material/Done";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import produce from "immer";
import { has } from "lodash";
import moment from "moment";
import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { push } from "connected-react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { getClientAssessments } from "../../actions/assessments";
import { getClientById, uploadClientImage } from "../../actions/client";
import * as labTest from "../../actions/labTest";
import { getLabTestRecord, Type } from "../../actions/labTest";
import * as NotificationActions from "../../actions/notification";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";
import CopyToClipboard from "../../components/ClipboardCopy/ClipboardCopy";
import OkhatiDialog from "../../components/Dialog/Dialog";
import ClientImageUploader from "../../components/ImageUploader/ClientImageUploader";
import MultipleButtonGroup from "../../components/MultipleButtonGroup";
import Panel from "../../components/Panel";
import { t, tl } from "../../components/translate";
import { authorizedSpDeletedErrorMessage } from "../../helpers/messages";
import { getTestReportBaseUrl } from "../../helpers/urls";
import useDeletedSps from "../../hooks/useDeletedSps";
import usePrintStyles from "../../hooks/usePrintStyles";
import { Assessment } from "../../interfaces/AssessmentInterfaces";
import { BillType, PrintTemplate } from "../../interfaces/BillInterfaces";
import { LabRecord, LabStatuses, LabTestRecordResultData } from "../../interfaces/Lab";
import { User } from "../../interfaces/User";
import EVENT from "../../mixpanel-analytics/event";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import { clientFullNameSelector } from "../../reducers/client";
import { allowRecordEdit } from "../../reducers/labTest";
import { IThunkDispatch, RootState } from "../../store";
import styles from "../Billing/BillActions/BillActions.module.css";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import Can from "../Policy/Can";
import LabDetailsCore from "./LabDetailsCore";
import { getAuthorizedSPId } from "./LabEntry";
import { isRadiologyOrCytology } from "./LabPrint/LabPrintFunctions";
import PrintLabBarCode from "./LabPrint/PrintLabBarCode";
import LabPrint from "./LabPrint/index";
import LabStatusChip from "./LabStatusChip";
import LabTestCreate from "./LabTestCreate/LabTestCreate";
import { LabRecordType } from "./index";
import { LabTestStatusTypes } from "./LabList";
import { getSpSignatureGroup } from "./EntryV2/EntryActions";
import { getCustomerNumber } from "../Client/ClientList";
import BillShow from "../Billing/BillShow/BillShow";
import { getBillByNumber } from "../../api/bill";
import { infoIconColor } from "./LabTestCreate/AddLabtests";
import hasOwnProperty from "../../helpers/object";
import { assessLabTestRecord, dispatchLabTestRecord } from "../../api/labTest";
import { barCodePrintPageStyle } from "../Stock/StockCreateEdit/BarCodePrint";
import useHandleShowDobOnLabPrint from "../../hooks/useHandleShowDobOnLabPrint";

export const showLabComponentsBasedOnPermission = (
  permissionGroup: string,
  status = null,
  includeTabCheck = true
): boolean => {
  if (["superAdmin", "supportAdmin", "superAdminAgent"].includes(permissionGroup)) {
    return false;
  }
  if (permissionGroup === "resourceCentreReadOnlyEmployee") {
    if (includeTabCheck && status) {
      if (status === "Result Ready" || status === "Incomplete Result") {
        return true;
      }
      return false;
    }
    return false;
  }
  return true;
};

const clientURL = (uuid: string): string => `${getTestReportBaseUrl()}/${uuid}`;

interface LabDetailsInterface {
  updateLabs?: (data) => void;
  isOpdTab?: boolean;
  labObj: LabRecord;
  resourceCentreId: number;
  labId: number;
  handleViewClose: () => void;
  user: User;
  userType: string;
  customerNumber: string;
  clientAssessments: Assessment[];
  permissionGroup: string;
  showAdditionalLabData: boolean;
  editMode: boolean;
  showMethods: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
  allowUpload: boolean;
  uploadPhoto: (id: number, image: File) => void;
  setEditMode: (val: boolean) => void;
  getLabTestRecordById: (id: number) => void;
  getClientByIdAction: (id: number) => void;
  getClientAssessmentsAction: (id: number) => void;
  onLinkCopied: () => void;
  navigateTo: (url: string) => void;
  updateSampleCollected: (
    labId: number,
    status: string,
    stateRemark?: string,
    id?: number,
    name?: string,
    collectionDate?: string | null
  ) => void;
  authorizedSP1Id: number;
  authorizedSP2Id: number;
  authorizedSP3Id: number;
  authorizedSP4Id: number;
  authorizedSP1Dynamic: string;
  authorizedSP2Dynamic: string;
  authorizedSP3Dynamic: string;
  authorizedSP4Dynamic: string;
  useCustomLabTestNumber: boolean;
  onSave: (
    labData: {
      labId: number;
      labObj: LabRecord;
      final: boolean;
      requestApproval: boolean;
      editAnother: boolean;
      authorizedSP1Id: number;
      authorizedSP2Id: number;
      authorizedSP3Id: number;
      authorizedSP4Id: number;
    },
    labRecordType: LabRecordType
  ) => void;
  hideActionButton?: boolean;
  labRecordType: LabRecordType;
  wrapperStyle?: { maxWidth: string; minWidth: string };
}

const LabDetails = ({
  isOpdTab = false,
  labObj,
  labId,
  handleViewClose,
  navigateTo,
  updateSampleCollected,
  user,
  userType,
  customerNumber,
  getClientAssessmentsAction,
  getClientByIdAction,
  permissionGroup,
  getLabTestRecordById,
  editMode,
  setEditMode,
  showMethods,
  showRangeFlag,
  showSampleCollectedBy,
  uploadPhoto,
  allowUpload,
  resourceCentreId,
  authorizedSP1Id,
  authorizedSP2Id,
  authorizedSP3Id,
  authorizedSP4Id,
  authorizedSP1Dynamic,
  authorizedSP2Dynamic,
  authorizedSP3Dynamic,
  authorizedSP4Dynamic,
  onSave,
  hideActionButton,
  useCustomLabTestNumber,
  labRecordType,
  wrapperStyle = { maxWidth: "900px", minWidth: "900px" },
  updateLabs
}: LabDetailsInterface) => {
  const deletedSps = useDeletedSps(labObj.type);
  const dispatch = useDispatch();
  const {
    client,
    serviceProvider,
    // eslint-disable-next-line camelcase
    created_at,
    status,
    remarks,
    customer
  } = labObj;
  const location = useLocation();
  const ongoing = useSelector((state: RootState) => state.ongoing);
  const statusTab = queryString.parse(location.search)?.status || LabTestStatusTypes.ORDERED;
  const [labTests, setLabTests] = React.useState<Array<LabTestRecordResultData>>([]);
  const [filteredLabObj, setFilteredLabObj] = React.useState(labObj);
  const [billNumber, setBillNumber] = React.useState<string | null>(null);
  const [bill, setBill] = React.useState<BillType | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  React.useEffect(() => {
    const fetchBill = async () => {
      try {
        const billData = await getBillByNumber(billNumber as string);
        setBill(billData);
      } catch (err) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getTime(),
            message: err.data?.message || "Something went wrong when getting bill details.",
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    };
    if (billNumber) {
      fetchBill();
    }
  }, [billNumber, dispatch]);
  const finalizedEditable =
    labObj.status === LabStatuses.RESULT_READY &&
    allowRecordEdit({ resultDate: labObj.resultDate, status: labObj.status });

  const showDetails =
    labObj.status === LabStatuses.RESULT_READY ||
    labObj.status === LabStatuses.INCOMPLETE_RESULT ||
    labObj.status === LabStatuses.PENDING_APPROVAL ||
    LabStatuses.DISPATCHED;
  const isMedicalTest = labObj?.results?.data.length > 0 && labObj.results.data[0]?.id === 705;

  React.useEffect(() => {
    if (!has(labObj, "remarks")) {
      getLabTestRecordById(labObj.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labId, labObj]);

  React.useEffect(() => {
    const allTests = labObj?.results?.data?.map((testResult) => {
      const ret = produce(testResult, (draft) => {
        draft.status = labObj.status;
        draft.testId = labObj.id;
      });
      return ret;
    });
    setLabTests(allTests);
    if (labObj.status === "Result Ready" && isMedicalTest) {
      getClientAssessmentsAction(client.id);
      getClientByIdAction(client.id);
    }

    setFilteredLabObj(labObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labObj]);

  const [moreAnchor, setMoreAnchor] = React.useState(null);
  const [popperAnchor, setPopperAnchor] = React.useState(null);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const popperRef = React.useRef<HTMLDivElement>();
  const [remarksOpen, setRemarksOpen] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = React.useState(false);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = React.useState(false);
  const [isAssessedDialogOpen, setIsAssessedDialogOpen] = React.useState(false);

  const [showEditPanel, setShowEditPanel] = React.useState(false);

  const finalAuthorizedSP1 = userType === "serviceProvider" ? user.id : authorizedSP1Id;
  const finalAuthorizedSP2 = userType === "serviceProvider" ? user.id : authorizedSP2Id;
  const finalAuthorizedSP3 = userType === "serviceProvider" ? user.id : authorizedSP3Id;
  const finalAuthorizedSP4 = userType === "serviceProvider" ? user.id : authorizedSP4Id;

  const handleClick = () => {
    setShowEditPanel(true);
  };
  const [stateRemarks, setStateRemarks] = React.useState("");
  const [sampleCollectionDate, setSampleCollectionDate] = React.useState(null);

  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const clientInfoViewHandle = () => {
    setShowClientInfoPanel(false);
  };

  const headerLabels = ["Test", "Result", "Unit", "Range"];

  if (showMethods) headerLabels.push("Method");

  if (showRangeFlag) {
    headerLabels.splice(2, 0, "Flag");
  }
  if (isRadiologyOrCytology(labObj.type)) {
    headerLabels.splice(1, headerLabels.length);
  }

  // show dob on lab print: custom hook -> useHandleShowDobOnLabPrint();
  const { showDob, toggleShowDob } = useHandleShowDobOnLabPrint();

  const titleContent = (
    <Box
      style={{ width: "100%" }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={1}
    >
      <Box display="flex" sx={{ alignItems: "center" }}>
        <Typography>
          <Box component="span" fontWeight="600" fontSize="16px" pr={2}>
            {tl("lab.labTest")}
          </Box>
        </Typography>
        <Box pr={2}>
          <LabStatusChip status={status.toLowerCase()} />
        </Box>
      </Box>
      <Box display="flex">
        <Box data-testmation="clipboardCopy" display="flex" alignItems="center">
          <CopyToClipboard
            TooltipProps={{
              title: "Client Lab Result is copied to clipboard!"
            }}
          >
            {({ copy }) => (
              <Tooltip title="Copy link" aria-label="copylink">
                <FileCopyIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    copy(clientURL(labObj.uuid));
                  }}
                />
              </Tooltip>
            )}
          </CopyToClipboard>
        </Box>

        <Box display="flex" alignItems="center" data-testmation="activitiesHistory" ml="8px">
          <Tooltip title="View history" aria-label="viewhistory">
            <AssignmentIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowHistory(!showHistory)}
              data-testmation="showHistory"
            />
          </Tooltip>
        </Box>
        {!hideActionButton && (
          <Can policyAccessKey="lab:printBarCode">
            <Box
              onMouseEnter={() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                usePrintStyles({
                  pageStyle: barCodePrintPageStyle
                }).applyPrintStyles();
              }}
              onMouseLeave={() => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                usePrintStyles({
                  pageStyle: "size: A4; margin: 0mm"
                }).applyPrintStyles();
              }}
            >
              <PrintLabBarCode labTests={labTests} client={client} />
            </Box>
          </Can>
        )}
      </Box>
    </Box>
  );
  return (
    <>
      {!showEditPanel && (
        <Panel
          wrapperStyle={wrapperStyle}
          onClose={handleViewClose}
          titleJsx={titleContent}
          footer={
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              position="absolute"
              bottom={0}
              left={0}
              height="50px"
              alignItems="center"
            >
              <div />
              <Box mr="32px">
                <Button onClick={() => handleViewClose()} data-testmation="labPanelClose">
                  {tl("lab.close")}
                </Button>
                {labObj.status === LabStatuses.PENDING_APPROVAL && (
                  <Button
                    data-testmation="labApprove"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (deletedSps.length) {
                        dispatch(
                          NotificationActions.notificationAdd({
                            id: new Date().getTime(),
                            message: `${authorizedSpDeletedErrorMessage(deletedSps)} `,
                            autoTimeout: true,
                            variant: "error"
                          })
                        );
                        return;
                      }
                      setIsApprovalDialogOpen(true);
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    <Typography>Approve</Typography>
                  </Button>
                )}
                {labObj.status === LabStatuses.RESULT_READY && !isOpdTab && (
                  <Button
                    data-testmation="labDispatch"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsDispatchModalOpen(true);
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    <Typography>Dispatch</Typography>
                  </Button>
                )}
                {labObj.status === LabStatuses.RESULT_READY &&
                  labObj.assessmentId &&
                  isOpdTab &&
                  !labObj.isAssessedBySp && (
                    <Button
                      data-testmation="labAssessed"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsAssessedDialogOpen(true);
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      <Typography>Mark Assessed</Typography>
                    </Button>
                  )}
              </Box>
            </Box>
          }
        >
          <Box margin="0px 32px" height="calc(100% - 100px)" style={{ overflowY: "auto" }}>
            {labObj.status !== LabStatuses.CANCELED && (
              <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
                {showLabComponentsBasedOnPermission(permissionGroup, labObj.status, false) && (
                  <>
                    {(labObj.status === LabStatuses.ORDERED ||
                      labObj.status === LabStatuses.SAMPLE_TAKEN) &&
                      !hideActionButton && (
                        <Can policyAccessKey="lab:takeSample">
                          <Link
                            className={styles.actionBtn}
                            onClick={handleClick}
                            sx={{ mr: "16px" }}
                          >
                            <Typography component="span">
                              <Box
                                className={styles.actionBtnLabel}
                                component="span"
                                fontSize="0.83em"
                                data-testmation="sampleTaken"
                              >
                                {tl("lab.status.sampletaken")}
                              </Box>
                            </Typography>
                          </Link>
                        </Can>
                      )}
                    {(labObj.status === LabStatuses.ORDERED ||
                      labObj.status === LabStatuses.SAMPLE_TAKEN) &&
                      !labObj?.results?.billNumber &&
                      !hideActionButton && (
                        <Link
                          onClick={() =>
                            navigateTo(
                              `/lab/labRecords/${labRecordType}/edit/${labObj.id}?status=${statusTab}`
                            )
                          }
                          sx={{ mr: "16px" }}
                          className={styles.actionBtn}
                        >
                          <Typography component="span">
                            <Box
                              className={styles.actionBtnLabel}
                              component="span"
                              fontSize="0.83em"
                            >
                              Edit
                            </Box>
                          </Typography>
                        </Link>
                      )}
                    {(labObj.accessingResourceCentreId
                      ? labObj.accessingResourceCentreId !== resourceCentreId
                      : true) &&
                      !hideActionButton && (
                        <Box component="div">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              navigateTo(
                                `/lab/labRecords/${labRecordType}/entry/${labId}?status=${labObj.status}`
                              )
                            }
                            data-testmation="enterTestResults"
                            style={{ marginRight: "8px", textTransform: "none" }}
                          >
                            <Typography style={{ fontSize: "1em" }}>
                              {labObj.status === "Result Ready"
                                ? "Edit test results"
                                : tl("lab.enterTestResults")}
                            </Typography>
                          </Button>
                        </Box>
                      )}
                  </>
                )}
                {showDetails && (
                  <Can policyAccessKey="lab:print">
                    <Box marginRight="8px">
                      <MultipleButtonGroup
                        printFrom="lab"
                        labData={labObj}
                        setFilteredLabObj={setFilteredLabObj}
                        showDobOnLabPrint={showDob}
                        toggleShowDobOnLabPrint={toggleShowDob}
                        options={[
                          {
                            label: "Print Default",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print Default"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.Default}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_DEFAULT, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          },
                          {
                            label: "Print Bordered",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print Bordered"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.Bordered}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_BORDERED, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          },

                          {
                            label: "Print Repeating Footer",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print Repeating Footer"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.RepeatingFooter}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_REPEATING_FOOTER, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          },
                          {
                            label: "Print Government",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print Government"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.Government}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_GOVERNMENT, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          },

                          {
                            label: "Print A5",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print A5"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.A5}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_A5, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          },

                          {
                            label: "Print Block Category",
                            onClickAction: () => ({}),
                            component: (
                              <LabPrint
                                buttonText="Print Block Category"
                                labData={filteredLabObj}
                                forceTemplate={PrintTemplate.BlockCategory}
                                onPrintClick={() =>
                                  mixpanelAnalytics.track(EVENT.PRINT_LAB_BLOCK_CATEGORY, {
                                    rcId: resourceCentreId
                                  })
                                }
                                showDobOnLabPrint={showDob}
                              />
                            )
                          }
                        ]}
                      />
                    </Box>
                  </Can>
                )}

                <div>
                  <Can policyAccessKey={["bill:listBill", "lab:cancel"]} partialCheck>
                    {(labObj.accessingResourceCentreId
                      ? labObj.accessingResourceCentreId !== resourceCentreId
                      : true) && (
                      <Button
                        onClick={(e) => {
                          setMoreAnchor(e.currentTarget);
                        }}
                        data-testmation="moreOptions"
                        style={{ textTransform: "none" }}
                      >
                        <Typography component="div" style={{ fontSize: "1em" }}>
                          <Box component="span" display="flex" alignItems="center">
                            {tl("lab.more")} <KeyboardArrowDownIcon />
                          </Box>
                        </Typography>
                      </Button>
                    )}
                  </Can>
                  <div
                    role="presentation"
                    ref={popperRef}
                    onClick={(e) => {
                      e.preventDefault();
                      setPopperAnchor(e.target);
                    }}
                  />

                  {showLabComponentsBasedOnPermission(permissionGroup, labObj.status) && (
                    <Menu
                      anchorEl={moreAnchor}
                      keepMounted
                      open={Boolean(moreAnchor)}
                      onClose={() => setMoreAnchor(null)}
                    >
                      {showLabComponentsBasedOnPermission(
                        permissionGroup,
                        labObj.status,
                        false
                      ) && (
                        <div>
                          <Can policyAccessKey="bill:listBill">
                            <MenuItem
                              onClick={() => {
                                setMoreAnchor(null);
                                navigateTo(`/billing/new?clientId=${client?.id}`);
                              }}
                              data-testmation="createBill"
                            >
                              {tl("lab.createBill")}
                            </MenuItem>
                          </Can>
                          {!hideActionButton && (
                            <Can policyAccessKey="lab:cancel">
                              <MenuItem
                                onClick={async () => {
                                  setIsDialogOpen(true);
                                  setMoreAnchor(null);
                                }}
                                data-testmation="cancelLabTest"
                              >
                                {tl("lab.cancelLabTest")}
                              </MenuItem>
                            </Can>
                          )}
                          {finalizedEditable && (
                            <MenuItem
                              onClick={async () => {
                                navigateTo(
                                  `/lab/labRecords/${labRecordType}/entry/${labId}?status=${labObj.status}`
                                );
                              }}
                              data-testmation="editFinalisedLabTest"
                            >
                              {tl("lab.editFinalisedTest")}
                            </MenuItem>
                          )}
                        </div>
                      )}
                    </Menu>
                  )}
                </div>

                {isDialogOpen && (
                  <OkhatiDialog
                    title={tl("lab.labTestCancelTitle")}
                    description={tl("lab.areYouSureCancel")}
                    next={async () => {
                      await updateSampleCollected(labId, "Canceled");
                      setMoreAnchor(null);
                    }}
                    cancel={() => {
                      setIsDialogOpen(false);
                    }}
                    readMode={false}
                  />
                )}
                <Popper
                  style={{ zIndex: 1 }}
                  open={remarksOpen}
                  anchorEl={popperAnchor}
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...TransitionProps}
                      style={{
                        transformOrigin: "bottom"
                      }}
                    >
                      <Paper id="menu-list-grow">
                        <ClickAwayListener onClickAway={() => setRemarksOpen(false)}>
                          <Box
                            style={{
                              padding: "16px",
                              border: "1px solid #908f8f"
                            }}
                          >
                            <TextField
                              id="outlined-dense-multiline"
                              label={tl("lab.remarks")}
                              margin="dense"
                              variant="outlined"
                              multiline
                              maxRows="2"
                              style={{ width: "100%", minHeight: "42px" }}
                              value={stateRemarks}
                              onChange={({ target }) => {
                                if (target.value) {
                                  return setStateRemarks(target.value);
                                }
                                return setStateRemarks("");
                              }}
                              autoFocus
                            />
                            <CalendarDropdown
                              placeholder={t("labTest.sampleTakenDateLabel")}
                              label={t("labTest.sampleTakenDateLabel")}
                              date={labObj.collectionDate ? new Date(labObj.collectionDate) : ""}
                              onChange={(collectionDate) => setSampleCollectionDate(collectionDate)}
                              format="formatted3"
                              TextFieldProps={{
                                variant: "outlined"
                              }}
                              withTimeSelector
                              allowNull
                              fullwidth
                            />
                            <Box component="div" textAlign="right" marginTop="16px">
                              <Button
                                style={{ marginLeft: "32px" }}
                                onClick={() => setRemarksOpen(false)}
                                data-testmation="labRemarksClose"
                              >
                                {tl("cancel")}
                              </Button>
                              <Button
                                style={{ marginLeft: "8px" }}
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                  await updateSampleCollected(
                                    labId,
                                    "Sample Taken",
                                    stateRemarks,
                                    user.id,
                                    `${user.firstName} ${user.lastName}`,
                                    sampleCollectionDate
                                  );
                                  setRemarksOpen(false);
                                }}
                                data-testmation="labUpdateButton"
                              >
                                {tl("lab.update")}
                                <DoneIcon />
                              </Button>
                            </Box>
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            )}

            <Box pt={2}>
              <Box display="flex">
                <Typography style={{ width: "120px" }}>{tl("lab.testId")}</Typography>
                <Typography>
                  <Box component="span" fontWeight="600">
                    {useCustomLabTestNumber ? labObj.labTestNumber || labObj.id : labObj.id}
                  </Box>
                </Typography>
              </Box>
              {labObj?.results?.billNumber && (
                <>
                  <Box display="flex" mt={2}>
                    <Typography style={{ width: "120px" }}>{tl("billnumber")}</Typography>
                    <Typography
                      style={{
                        fontWeight: 600,
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={() => setBillNumber(labObj?.results?.billNumber || null)}
                    >
                      {labObj.results.billNumber}
                    </Typography>
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "0.675rem" }}>
                          Note: This lab test cannot be edited because bill has already been
                          created.
                        </Typography>
                      }
                      sx={{
                        marginLeft: "10px",
                        color: infoIconColor
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                </>
              )}
              {labObj?.results?.relatedServices?.length > 0 && (
                <>
                  <Box display="flex">
                    <Typography style={{ width: "120px" }}>Bill Items:</Typography>
                    <Box display="flex" flexDirection="column">
                      {labObj.results.relatedServices.map((service, i) => {
                        if (service.subItems?.length) {
                          const tooltipTitle = (
                            <Box>
                              <Typography>Package Items:</Typography>
                              {service.subItems.map((item) => (
                                <Typography key={item.name}>{item.name}</Typography>
                              ))}
                            </Box>
                          );
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Tooltip title={tooltipTitle} aria-label="help" key={i}>
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  cursor: "pointer"
                                }}
                              >
                                {service.name}
                              </Typography>
                            </Tooltip>
                          );
                        }
                        return (
                          <Typography
                            style={{
                              fontWeight: 600
                            }}
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                          >
                            {service.name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              )}
              <Box display="flex" mt="16px">
                <Box flexDirection="column" justifyContent="space-between" mr="20px">
                  <Box display="flex">
                    <Typography style={{ width: "120px" }}>{tl("lab.client")}</Typography>
                    {showLabComponentsBasedOnPermission(permissionGroup, labObj.status, false) ? (
                      <Typography
                        style={{
                          fontWeight: 600,
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setShowClientInfoPanel(true);
                        }}
                      >
                        {clientFullNameSelector(client).toUpperCase()}
                      </Typography>
                    ) : (
                      <Typography style={{ fontWeight: 600 }}>
                        {clientFullNameSelector(client).toUpperCase()}
                      </Typography>
                    )}
                  </Box>
                  {customerNumber && (
                    <Box display="flex">
                      <Typography style={{ width: "120px" }}>{tl("lab.customerNumber")}</Typography>
                      <Typography>
                        <Box component="span" fontWeight="600">
                          {getCustomerNumber(customerNumber)}
                        </Box>
                      </Typography>
                    </Box>
                  )}
                  {labObj?.customer?.externalIdentifier && (
                    <Box display="flex">
                      <Typography style={{ width: "120px" }}>
                        {tl("labPrint.customerIdentifier")}
                      </Typography>
                      <Typography>
                        <Box component="span" fontWeight="600">
                          {labObj.customer.externalIdentifier}
                        </Box>
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex">
                    <Typography style={{ width: "120px" }}>{tl("lab.phoneNo")}</Typography>
                    <Typography>
                      <Box component="span" fontWeight="600">
                        {client.phone}
                      </Box>
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography style={{ width: "120px" }}>{tl("lab.address")}</Typography>
                    <Typography>
                      <Box component="span" fontWeight="600">
                        {client.address}
                      </Box>
                    </Typography>
                  </Box>
                </Box>

                <div>
                  {allowUpload && (
                    <ClientImageUploader
                      readOnly={labObj.status === "Result Ready"}
                      action={(image) => {
                        uploadPhoto(client.id, image);
                      }}
                      initialImage={customer?.customerDetails?.profileImageS3Url || ""}
                    />
                  )}
                </div>
              </Box>
              <Box justifyContent="space-between" pt={1}>
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.orderedBy")}</Typography>
                  <Typography>
                    {serviceProvider
                      ? clientFullNameSelector(serviceProvider)
                      : tl("labTest.orderBy.self")}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.orderedOn")}</Typography>
                  <Typography>{calendarFns.bsFullDate(created_at)}</Typography>
                </Box>
              </Box>
              {labObj.status === LabStatuses.DISPATCHED && (
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.dispatchedDate")}</Typography>
                  <Typography>{calendarFns.bsFullDate(labObj.updated_at)}</Typography>
                </Box>
              )}
              {labObj.referrers && (
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("booking.referredBy")}</Typography>
                  <Typography>{labObj.referrers}</Typography>
                </Box>
              )}
              {labObj.extraReferrer?.name && (
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>Extra Referrer</Typography>
                  <Typography>{labObj.extraReferrer.name}</Typography>
                </Box>
              )}
              {labObj.accessingResourceCentreId && (
                <Box mt="16px">
                  {labObj.accessingResourceCentreId !== resourceCentreId ? (
                    <Typography>
                      Referred By Lab:{" "}
                      <Box component="span" ml="18px" fontWeight={600}>
                        {labObj.accessingResourceCentre?.name}
                      </Box>
                    </Typography>
                  ) : (
                    <Typography>
                      Referred Lab:{" "}
                      <Box component="span" ml="18px" fontWeight={600}>
                        {labObj.resourceCentre.name}
                      </Box>
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            <Box pt={3}>
              <Typography>
                <Box component="span" fontWeight="600">
                  {tl("lab.testParticulars")}
                </Box>
              </Typography>
              {!showDetails && (
                <Box pt={1}>
                  {labTests?.map((tr, ind) => (
                    <Box
                      pl={1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={ind}
                      pr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography component="span">
                          {`${ind + 1}. ${tr.name} ${
                            tr.labTests?.length > 1
                              ? `(${tr.labTests.map((trl) => `${trl.name}`)})`
                              : ""
                          }`}
                          {tr.sampleTaken && (
                            <Tooltip title="Sample for the lab has been taken.">
                              <Checkbox checked={tr.sampleTaken} />
                            </Tooltip>
                          )}
                        </Typography>
                      </Box>
                      {tr.status === "Result Ready" && <CheckCircleIcon color="primary" />}
                    </Box>
                  ))}
                </Box>
              )}
              {showDetails && (
                <LabDetailsCore
                  data={labTests}
                  headers={headerLabels}
                  labClient={labObj.client}
                  comment={labObj.results?.comment}
                  showComment={Boolean(labObj.results?.showComment)}
                  showSampleCollectedBy={showSampleCollectedBy}
                />
              )}
            </Box>
            {isApprovalDialogOpen && (
              <OkhatiDialog
                title={tl("lab.labTestApprovalTitle")}
                description={tl("lab.areYouSureApprove")}
                isLoading={hasOwnProperty(ongoing, Type.UPDATE_LAB_TEST_RECORD)}
                next={async () => {
                  await onSave(
                    {
                      labId,
                      labObj,
                      final: true,
                      requestApproval: false,
                      editAnother: false,
                      authorizedSP1Id: getAuthorizedSPId({
                        currentId: labObj?.results?.approvedBy?.authorizedSP1?.id,
                        staticId: authorizedSP1Id,
                        dynamicState: authorizedSP1Dynamic,
                        enteredById: null,
                        assessedById: null,
                        approvedById: finalAuthorizedSP1
                      }),
                      authorizedSP2Id: getAuthorizedSPId({
                        currentId: labObj?.results?.approvedBy?.authorizedSP2?.id,
                        staticId: authorizedSP2Id,
                        dynamicState: authorizedSP2Dynamic,
                        enteredById: null,
                        assessedById: null,
                        approvedById: finalAuthorizedSP2
                      }),
                      authorizedSP3Id: getAuthorizedSPId({
                        currentId: labObj?.results?.approvedBy?.authorizedSP3?.id,
                        staticId: authorizedSP3Id,
                        dynamicState: authorizedSP3Dynamic,
                        enteredById: null,
                        assessedById: null,
                        approvedById: finalAuthorizedSP3
                      }),
                      authorizedSP4Id: getAuthorizedSPId({
                        currentId: labObj?.results?.approvedBy?.authorizedSP4?.id,
                        staticId: authorizedSP4Id,
                        dynamicState: authorizedSP4Dynamic,
                        enteredById: null,
                        assessedById: null,
                        approvedById: finalAuthorizedSP4
                      })
                    },
                    labRecordType
                  );
                  setIsApprovalDialogOpen(false);
                }}
                cancel={() => {
                  setIsApprovalDialogOpen(false);
                }}
                readMode={false}
              />
            )}
            {isDispatchModalOpen && (
              <OkhatiDialog
                title={tl("lab.labTestDispatchTitle")}
                description={tl("lab.areYouSureDispatch")}
                isLoading={isSaving}
                next={async () => {
                  setIsSaving(true);
                  try {
                    await dispatchLabTestRecord(labId);
                    setIsDispatchModalOpen(false);
                    dispatch(push(`/lab/labRecords/all/${labId}?status=${LabStatuses.DISPATCHED}`));
                  } catch (err) {
                    dispatch(
                      NotificationActions.notificationAdd({
                        id: new Date().getTime(),
                        message:
                          err.data?.message ||
                          "Something went wrong when dispatching lab test record",
                        autoTimeout: true,
                        variant: "error"
                      })
                    );
                  }
                  setIsSaving(false);
                }}
                cancel={() => {
                  setIsDispatchModalOpen(false);
                }}
                readMode={false}
              />
            )}
            {showHistory && (
              <Box pt={3}>
                <Typography>
                  <Box component="span" fontWeight="600">
                    {tl("lab.remarksHistory")}
                  </Box>
                </Typography>
                <Box pt={1}>
                  {remarks &&
                    Array.isArray(remarks) &&
                    remarks.map((remark) => (
                      <Typography key={remark.id}>
                        {moment(remark.date).format("YYYY-MM-DD")} {remark.remark}{" "}
                        {`${remark.name ? `by ${remark.name}` : ""}`}
                      </Typography>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
          {showClientInfoPanel &&
            client &&
            (editMode ? (
              <ClientCreateEdit
                setEditMode={setEditMode}
                clientId={client.id}
                mode="edit"
                onCancel={() => {
                  setEditMode(false);
                }}
                stayOnCurrentPage
              />
            ) : (
              <ClientInfo
                setEditMode={setEditMode}
                id={client.id}
                handleViewClose={clientInfoViewHandle}
                wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
                stayOnCurrentPage
              />
            ))}
        </Panel>
      )}

      {showEditPanel && (
        <LabTestCreate
          labRecordType={labRecordType}
          id={labId}
          onClose={() => setShowEditPanel(false)}
          isForSampleTaken
          runAfterSave={() => setShowEditPanel(false)}
        />
      )}
      {billNumber && bill && (
        <BillShow
          billId={bill.id}
          handleViewClose={() => setBillNumber(null)}
          hideActions
          wrapperStyle={{ minWidth: "660px", maxWidth: "660px" }}
          billData={bill}
        />
      )}
      {isAssessedDialogOpen && (
        <OkhatiDialog
          title={tl("lab.labTestAssessedTitle")}
          description={tl("lab.areYouSureAssess")}
          isLoading={isSaving}
          next={async () => {
            setIsSaving(true);
            try {
              await assessLabTestRecord(labId);
              if (updateLabs) {
                updateLabs(labId);
              }
              setIsAssessedDialogOpen(false);
              handleViewClose();
            } catch (err) {
              dispatch(
                NotificationActions.notificationAdd({
                  id: new Date().getTime(),
                  message:
                    err.data?.message || "Something went wrong when assessing lab test record",
                  autoTimeout: true,
                  variant: "error"
                })
              );
            }
            setIsSaving(false);
          }}
          cancel={() => {
            setIsAssessedDialogOpen(false);
          }}
          readMode={false}
        />
      )}
    </>
  );
};

LabDetails.defaultProps = {
  hideActionButton: false
};

export default connect(
  (state: RootState, ownProps: Record<string, unknown>) => {
    const { labObj } = ownProps;
    const resourceCentre =
      state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre;
    const { user } = state.userContext;
    const customer = state.clients.collection?.find((client) => client.id === labObj.clientId);
    const spSignatureGroup = getSpSignatureGroup(labObj as LabRecord, resourceCentre);
    const [authorizedSP1Id, authorizedSP2Id, authorizedSP3Id, authorizedSP4Id] = [
      spSignatureGroup.authorizedSP1,
      spSignatureGroup.authorizedSP2,
      spSignatureGroup.authorizedSP3,
      spSignatureGroup.authorizedSP4
    ];

    // eslint-disable-next-line max-len
    const [authorizedSP1Dynamic, authorizedSP2Dynamic, authorizedSP3Dynamic, authorizedSP4Dynamic] =
      [
        spSignatureGroup.authorizedSP1Dynamic,
        spSignatureGroup.authorizedSP2Dynamic,
        spSignatureGroup.authorizedSP3Dynamic,
        spSignatureGroup.authorizedSP4Dynamic
      ];

    const customerNumber = customer?.customerNumber;
    const customerIpd = customer?.ipdNo;
    const customerIdentifier = customer?.externalIdentifier;
    const clientAssessments = state.assessments.clientSpecific;
    const showAdditionalLabData = resourceCentre?.labSettings?.additionallabdata;
    const showMethods = resourceCentre?.labSettings?.showMethods;
    const showRangeFlag = resourceCentre?.labSettings?.showRangeFlag;
    const showSampleCollectedBy = resourceCentre?.labSettings?.showSampleCollectedBy;
    const allowUpload = resourceCentre?.settings.clientSettings.enablePictureUpload;
    const useCustomLabTestNumber = resourceCentre?.labSettings?.useCustomLabTestNumber;
    return {
      user,
      userType: state?.userContext?.userCreds?.authenticable,
      customerNumber,
      customerIpd,
      customerIdentifier,
      clientAssessments,
      permissionGroup: state.userContext?.userCreds?.userGroups[0],
      showAdditionalLabData,
      showMethods,
      showRangeFlag,
      showSampleCollectedBy,
      allowUpload,
      resourceCentreId: state.userContext.resourceCentreId,
      authorizedSP1Id,
      authorizedSP2Id,
      authorizedSP3Id,
      authorizedSP4Id,
      authorizedSP1Dynamic,
      authorizedSP2Dynamic,
      authorizedSP3Dynamic,
      authorizedSP4Dynamic,
      useCustomLabTestNumber
    };
  },
  (dispatch: IThunkDispatch) => ({
    getLabTestRecordById: (labTestRecordId) => {
      dispatch(getLabTestRecord(labTestRecordId));
    },
    updateSampleCollected: (labTestId, status, remark, id, name, collectionDate) => {
      dispatch(
        labTest.updateLabTestsRecordSample(labTestId, status, remark, id, name, collectionDate)
      );
    },
    getClientByIdAction: (id) => dispatch(getClientById(id)),
    getClientAssessmentsAction: (id) => dispatch(getClientAssessments(id)),
    onLinkCopied: () => {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: tl("booking.linkCopied"),
          autoTimeout: true
        })
      );
    },
    onSave: async (
      {
        labId,
        labObj,
        final,
        requestApproval,
        editAnother,
        authorizedSP1Id,
        authorizedSP2Id,
        authorizedSP3Id,
        authorizedSP4Id
      },
      labRecordType
    ) => {
      const updatedTestData = {
        attachments: labObj.attachments,
        updatedTestResults: labObj.results,
        final,
        requestApproval,
        authorizedSP1Id,
        authorizedSP2Id,
        authorizedSP3Id,
        authorizedSP4Id
      };
      await dispatch(
        labTest.updateLabTestsRecord(labId, updatedTestData, editAnother, labRecordType)
      );
    },
    uploadPhoto: (id, image) => dispatch(uploadClientImage(id, image))
  })
)(LabDetails);
