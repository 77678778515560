import React from "react";
import { Box, Typography } from "@mui/material";
import { round } from "mathjs";
import startCase from "lodash/startCase";
import moment from "moment";
import "../PrintBill.scss";
import { useSelector } from "react-redux";
import { BillType } from "../../../../interfaces/BillInterfaces";
import { RootState } from "../../../../store";
import "./PrintStyle.scss";
import InfoField from "./InfoField";
import { t } from "../../../../components/translate";
import capitalizedName from "../../../../helpers/nameCapitalizer";
import { roundOffAndDisplayInRupee } from "../../../../helpers/formatters";
import { scaleFont } from "../../../../components/Print/Print";
import hasOwnProperty from "../../../../helpers/object";
import { spFromIdSelector, spFullNameSelector } from "../../../../reducers/serviceProvider";

const columns = ["Description", "Qty", "Price"];

const ItemHeadings = ({ headers }: { headers: string[] }) => (
  <Box display="flex">
    {headers.map((col) => (
      <Box
        key={col}
        sx={{
          display: "flex",
          flexBasis: "1.2cm",
          "&:first-child": {
            flexGrow: 1,
            flexBasis: "3cm"
          },
          "&:nth-child(2)": {
            flexBasis: "0.6cm"
          },
          "&:not(:first-child)": {
            justifyContent: "flex-end"
          }
        }}
      >
        <InfoField valueStyle={{ fontWeight: "medium" }} value={col} />
      </Box>
    ))}
  </Box>
);

const BillItems = ({ billItems }) => {
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);
  return (
    <Box mb={1} mt={0.3} borderBottom="0.5px solid #777777" borderTop="0.5px solid #777777">
      {billItems.map((item) => (
        <Box
          m="0.05cm 0cm"
          display="flex"
          key={item.id}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Box fontSize="0.2cm" lineHeight={1} flexBasis="3cm" flexGrow={1}>
            {item.description}{" "}
            <Typography
              fontSize={scaleFont("0.2cm", 0.75)}
              component="i"
              sx={{ wordBreak: "break-all" }}
            >
              {item.batchInfo.expiryDate &&
                `Expiring ${moment(item.batchInfo.expiryDate).format("MMM YYYY")}`}
            </Typography>
            {item?.subItems?.map((subitem) => (
              <Box
                key={subitem.productId}
                paddingTop="0.05cm"
                ml="5px"
                fontSize={scaleFont("0.2cm", 0.9)}
              >
                {`◦ ${subitem.description}`}
                {hasOwnProperty(subitem, "serviceProviderId") && subitem.serviceProviderId && (
                  <span>
                    {`(${spFullNameSelector(spFromIdSelector(SPs, subitem.serviceProviderId))})`}
                  </span>
                )}
              </Box>
            ))}
          </Box>
          <Box sx={{ flexBasis: "0.6cm", display: "flex", justifyContent: "flex-end" }}>
            <InfoField value={item.quantity} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", flexBasis: "1.2cm" }}>
            <InfoField value={item.grossTotal} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Summary = ({
  bill,
  user,
  hidePaymentMethodOnPrint
}: {
  bill: BillType;
  user: { firstName: string; lastName: string };
  hidePaymentMethodOnPrint: boolean;
}): JSX.Element => {
  const dueAmount = round(bill.summary.totalAmount - bill.paymentInfo.paidAmount || 0, 2);
  return (
    <>
      {Boolean(bill.summary.totalPerUnitTimesQty) && (
        <InfoField
          valueStyle={{ fontWeight: "bold" }}
          label="Gross Total"
          value={roundOffAndDisplayInRupee(bill.summary.totalPerUnitTimesQty)}
        />
      )}
      {bill.summary.taxAmount > 0 && (
        <InfoField label="Vat" value={roundOffAndDisplayInRupee(bill.summary.taxAmount)} />
      )}
      {bill.summary.discount > 0 && (
        <InfoField label="Discount" value={roundOffAndDisplayInRupee(bill.summary.discount)} />
      )}
      <InfoField
        valueStyle={{ fontWeight: "bold" }}
        label="Grand Total"
        value={roundOffAndDisplayInRupee(bill.summary.totalAmount)}
      />
      <InfoField
        valueStyle={{ fontWeight: "bold" }}
        label="Paid Amount"
        value={roundOffAndDisplayInRupee(bill.paymentInfo.paidAmount)}
      />
      {dueAmount > 0 && (
        <InfoField
          valueStyle={{ fontWeight: "bold" }}
          label="Due Amount"
          value={roundOffAndDisplayInRupee(dueAmount)}
        />
      )}
      {!!bill.paymentInfo?.tenderAmount && (
        <>
          <InfoField
            valueStyle={{ fontWeight: "bold" }}
            label={t("billing.tenderAmount")}
            value={round(bill.paymentInfo.tenderAmount, 2).toFixed(2)}
          />
          <InfoField
            valueStyle={{ fontWeight: "bold" }}
            label={t("billing.changeAmount")}
            value={round(bill.paymentInfo.changeAmount, 2).toFixed(2)}
          />
        </>
      )}
      <Box sx={{ margin: "0.1cm" }} />
      <InfoField value={`${bill.summary.inWords} only`} />
      <Box sx={{ margin: "0.1cm" }} />
      {!hidePaymentMethodOnPrint && (
        <InfoField label="Payment Method" value={startCase(bill.paymentInfo.paymentMethod)} />
      )}
      <InfoField
        label={t("billing.printedDateTime")}
        value={`${moment().format("YYYY/MM/DD")} ${moment().format("h:mm a")}`}
      />
      {bill?.printCount > 0 && (
        <InfoField
          label={t("billing.rePrintUser")}
          value={capitalizedName(`${user?.firstName} ${user?.lastName}`)}
        />
      )}
    </>
  );
};
const BillDescription = ({
  billData,
  hidePaymentMethodOnPrint = false
}: {
  billData: BillType;
  hidePaymentMethodOnPrint?: boolean;
}): JSX.Element | null => {
  const user = useSelector((state: RootState) => state.userContext.user);
  if (!billData?.document?.billItems) return null;

  return (
    <div>
      <ItemHeadings headers={columns} />
      <BillItems billItems={billData.document.billItems} />
      <Summary bill={billData} user={user} hidePaymentMethodOnPrint={hidePaymentMethodOnPrint} />
    </div>
  );
};

export default BillDescription;
