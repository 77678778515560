/* eslint-disable react-hooks/rules-of-hooks */
import { chain, round } from "mathjs";
import {
  ServiceProviderCommissionBill,
  VendorCommission,
  VendorCommissionBill
} from "../../../../interfaces/Commission";
import { ServiceProvider } from "../../../../interfaces/ServiceProvidersInterface";

export const findRateAmt = (item: VendorCommission): VendorCommission => {
  const amountAfterMaterialCharge = chain(1 - item.materialChargePercent / 100)
    .multiply(item.allocatedAmt)
    .done();
  const amountAfterLabCharge = Number(amountAfterMaterialCharge) - item.labCharge;
  const rateAmt = chain(item.rate * amountAfterLabCharge)
    .divide(100)
    .done();
  return { ...item, rateAmt };
};

export const findSpPayableAmt = (item: VendorCommission, changer?: string): VendorCommission => {
  const amountAfterMaterialCharge = chain(1 - item.materialChargePercent / 100)
    .multiply(item.allocatedAmt)
    .done();
  const materialChargeAmount = item.materialChargePercent * item.allocatedAmt || 0;
  const amountAfterLabCharge = Number(amountAfterMaterialCharge) - item.labCharge;
  let { rate } = item;
  let { rateAmt } = item;
  if (changer === "rateAmt") {
    rate = chain(item.rateAmt / (amountAfterLabCharge || 1))
      .multiply(100)
      .done();
  } else {
    rateAmt = chain(item.rate * amountAfterLabCharge)
      .divide(100)
      .done();
  }

  const amountAfterTds = chain(1 - item.tds / 100)
    .multiply(rateAmt)
    .done();
  return {
    ...item,
    materialChargeAmount,
    rateAmt,
    rate,
    paidAmount: item.allocatedAmt,
    standardPayable: amountAfterTds,
    netPayableToVendor: amountAfterTds
  };
};

/*
Formatting data for Service Provider Commission.

Each Bill may contain multiple Receipts. Commission can be given in each Receipt for the same bill.
Each Bill may contain BillItems. The row in each bill is either billItem (if it is service) or 
    subItem (if it is package).
Receipt Amount is Divided in a ratio by looking at:

1. If it is Service:
    a. Sum of grossTotal of each BillItem.
    b. Receipt Amount
    c. Gross total of Bill Item

2. If it is Package:
    a. Allocated Amount for that Package.
    b. Sum of grossTotal of each subItem.
    c. Gross total of subItem.
*/

export const formatBillDataForCommission = (
  bills: ServiceProviderCommissionBill[],
  serviceProviders: ServiceProvider[]
): VendorCommissionBill[] => {
  const items = [] as VendorCommissionBill[];
  let index = 0;
  [...bills]
    .sort((a, b) => b.id - a.id)
    .forEach((bill) => {
      // For commission that has been applied.
      const recordPaymentIds = [
        ...new Set(bill.serviceProviderCommissions.map((item) => item.recordPaymentId))
      ];

      // For Each Individual Payment
      bill.receipts.forEach((receipt) => {
        index += 1;
        const rows = [] as VendorCommission[];
        let internalSNo = 0;

        // Formatting row in which commission has been applied.
        if (recordPaymentIds.includes(receipt.id)) {
          bill.serviceProviderCommissions
            .filter((spCommission) => spCommission.recordPaymentId === receipt.id)
            .forEach((item) => {
              internalSNo += 1;
              rows.push(
                findRateAmt({
                  ...item,
                  internalSNo,
                  qty: item.qty || 0,
                  allocatedAmt: item.paidAmount,
                  serviceProviderFullName: item.relatedInformation?.serviceProviderFullName || "",
                  description: item.relatedInformation?.serviceName,
                  netPayableToVendor: item.netPayableToVendor || 0,
                  rate: item.rate || 0
                })
              );
            });
        } else {
          bill.document.billItems?.forEach((billItem) => {
            const allocatedAmt = round(
              (receipt.paidAmount * (billItem?.grossTotal || 0)) / (bill.total || 1),
              2
            );

            // Formatting row for services (not package)
            if (!billItem.subItems?.length && billItem.serviceProviderId) {
              const serviceProvider = serviceProviders.find(
                (sp: ServiceProvider) => sp.id === billItem.serviceProviderId
              );

              internalSNo += 1;
              const row = findSpPayableAmt({
                internalSNo,
                billId: bill.id,
                clientId: bill.clientId,
                billNo: bill.billNumber,
                source: billItem?.source || "services",
                description: billItem.description || "",
                qty: billItem?.quantity,
                allocatedAmt,
                labCharge:
                  billItem.source === "services" ? Number(billItem.productData?.labCharge) || 0 : 0,
                materialChargeAmount: 0,
                materialChargePercent: Number(billItem.productData?.materialCharge) || 0,
                rate:
                  Number(
                    billItem.productData?.document?.rates.find(
                      (rt) => rt.serviceProviderId === billItem.serviceProviderId
                    ).rate
                  ) || 0,
                tds: Number(serviceProvider?.tds) || 0,
                vendorId: billItem.serviceProviderId,
                serviceProviderFullName: `${serviceProvider?.firstName || ""} ${
                  serviceProvider?.lastName || ""
                }`,
                discountAmount: billItem.discountAmt || 0,
                discountPercent: billItem.discountPercent || 0,
                standardPayable: 0,
                netPayableToVendor: 0,
                netAmount: billItem?.grossTotal || 0,
                paidAmount: 0,
                rateAmt: 0,
                recordPaymentId: receipt.id,
                serviceId: billItem.productData?.id || billItem.productId,
                productPriceExcVat:
                  billItem.source === "services"
                    ? +(billItem?.productData?.servicePriceExcVAT || 0)
                    : +(billItem?.productData?.unitPriceExcVAT || 0),
                relatedInformation: {
                  customerFirstName: bill.client?.firstName || "",
                  customerLastName: bill.client?.lastName || "",
                  packageDetail: billItem?.subItems || [],
                  serviceName: billItem.description || "",
                  serviceProviderFullName: `${serviceProvider?.firstName || ""} ${
                    serviceProvider?.lastName || ""
                  }`,
                  source: billItem?.source || "services"
                }
              });
              rows.push(row);
              return;
            }

            // formatting row for package (containing subitems)
            if (billItem.subItems?.length) {
              billItem.subItems?.forEach((subItem, i) => {
                if (subItem.serviceProviderId) {
                  internalSNo += 1;
                  const serviceProvider = serviceProviders.find(
                    (sp) => sp.id === subItem.serviceProviderId
                  );
                  const totalGrossTotal = billItem.subItems?.reduce(
                    (prev, acc) => prev + Number(acc.productData.grossTotalPrice || 0),
                    0
                  );
                  const allocatedAmtForSubItem =
                    ((Number(subItem?.productData.grossTotalPrice) || 0) / (totalGrossTotal || 1)) *
                    allocatedAmt;

                  const netAmt =
                    (Number(subItem.productData.grossTotalPrice) / (totalGrossTotal || 1)) *
                    Number(billItem.grossTotal || 0);
                  const row = findSpPayableAmt({
                    internalSNo,
                    isSubItem: true,
                    billId: bill.id,
                    clientId: bill.clientId,
                    billNo: bill.billNumber,
                    source: billItem?.source || "services",
                    description: subItem.description || "",
                    qty: 0,
                    allocatedAmt: allocatedAmtForSubItem,
                    labCharge: Number(subItem.productData?.labCharge) || 0,
                    materialChargeAmount: 0,
                    materialChargePercent: Number(subItem.productData?.materialCharge) || 0,
                    rate:
                      Number(
                        subItem.productData?.document?.rates.find(
                          (rt) => rt.serviceProviderId === subItem.serviceProviderId
                        ).rate
                      ) || 0,
                    tds: Number(serviceProvider?.tds) || 0,
                    vendorId: subItem.serviceProviderId,
                    serviceProviderFullName: `${serviceProvider?.firstName || ""} ${
                      serviceProvider?.lastName || ""
                    }`,
                    discountAmount: 0,
                    discountPercent: 0,
                    standardPayable: 0,
                    netPayableToVendor: 0,
                    netAmount: netAmt,
                    paidAmount: 0,
                    rateAmt: 0,
                    recordPaymentId: receipt.id,
                    serviceId: subItem.productData?.id || subItem.productId,
                    productPriceExcVat: +(billItem?.productData?.servicePriceExcVAT || 0),
                    relatedInformation: {
                      customerFirstName: bill.client?.firstName || "",
                      customerLastName: bill.client?.lastName || "",
                      packageDetail: billItem?.subItems || [],
                      serviceName: subItem.description || "",
                      serviceProviderFullName: `${serviceProvider?.firstName || ""} ${
                        serviceProvider?.lastName || ""
                      }`,
                      source: billItem?.source || "services"
                    },
                    ...(i === 0 && {
                      packageName: billItem.description,
                      netAllocatedAmt: allocatedAmt
                    })
                  });
                  rows.push(row);
                }
              });
            }
          });
        }

        const item = {
          isServiceProviderPaid: recordPaymentIds.includes(receipt.id),
          sNo: index,
          billItems: rows,
          paidAmount: receipt.paidAmount,
          billId: bill.id,
          billNumber: bill?.billNumber || "",
          recordPaymentId: receipt.id,
          isSelected: false,
          totalNetPayableToVendor: 0,
          isSaved: false
        };
        if (rows.length) {
          items.push(item);
        }
      });
    });
  return items;
};

export const formatData = (data: VendorCommissionBill[]): VendorCommission[] => {
  const serviceProviderCommissionData = [] as VendorCommission[];
  data.forEach((item) => {
    if (item.isSelected) {
      item.billItems.forEach((billItem: VendorCommission) => {
        serviceProviderCommissionData.push(billItem);
      });
    }
  });
  return serviceProviderCommissionData;
};
