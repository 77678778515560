import { Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import { tl } from "../../../../components/translate";
import { getDisplayAgeFromDOB, separateByComma } from "../../../../helpers/formatters";
import { Client } from "../../../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../../../reducers/client";
import { getLocalDateInAD } from "../BillPrintHelpers";
import InfoField from "./InfoField";
import { getCustomerNumber } from "../../../Client/ClientList";

interface ClientInfoProps {
  client: Client;
  customerNumber: string;
  showDobOnPrint: boolean;
}

export const getGenderCode = (gender: string): string => {
  switch (gender) {
    case "1":
      return "M";
    case "2":
      return "F";
    default:
      return "";
  }
};

export const showDOBAndGender = (
  gender: string,
  dob: string,
  showIndividual: boolean = false
): string => {
  const age = dob ? getDisplayAgeFromDOB(dob) : "";
  const sex = getGenderCode(gender);
  if (age && sex) return ` ${age}/${sex}`;
  if (age) return showIndividual ? age : ` /${age}`;
  if (sex) return showIndividual ? sex : ` /${sex}`;
  return "";
};

export const ClientInfo: React.FC<ClientInfoProps> = ({
  client,
  customerNumber = null,
  showDobOnPrint = false
}): JSX.Element => (
  <>
    <Typography
      style={{
        textAlign: "left",
        fontSize: "0.3cm",
        fontWeight: 600,
        textTransform: "uppercase"
      }}
    >
      {clientFullNameSelector(client)}
      {showDOBAndGender(client.gender, client.dob)}
    </Typography>
    {customerNumber && (
      <InfoField label="Customer Number" value={getCustomerNumber(customerNumber)} />
    )}
    {client.registrationNo && (
      <InfoField label={tl("bills.registrationNo")} value={client.registrationNo} />
    )}
    {showDobOnPrint && client?.dob && (
      <InfoField label={tl("billPrint.customerDob")} value={getLocalDateInAD(client.dob)} />
    )}
    {(client.address || client.city) && (
      <InfoField
        label="Address"
        value={separateByComma(capitalize(client.address), capitalize(client.city))}
      />
    )}
    {client.phone && (
      <InfoField
        label="Phone No:"
        value={client.phone?.startsWith("+977") ? client.phone.substring(4) : client.phone}
      />
    )}
  </>
);

export default ClientInfo;
