import axios from "axios";
import { omit } from "lodash";
import { FiscalPeriodProps } from "../interfaces/Accounts";
import { ResourceCentre, BannerUploadResponse } from "../interfaces/ResourceCentreInterface";
import { Post, Patch, Get, Delete } from "./apiHelper";
import { PublicProfileState } from "../slices/publicProfileSlice";
import { SmsHistory } from "../interfaces/Subscription";
import { QrPaymentInfo } from "../containers/ResourceCentre/ModuleSettings/QrPayment/QrPaymentInfoForm";

interface UserBranchPayload {
  userId: number;
  employeeId: number;
  resourceCentreId: number;
}
const resourceCentreRoot = "/api/resourceCentres";

export const resourceCentreLogoUploadURL = (resourceCentreId: number): string =>
  `${resourceCentreRoot}/${resourceCentreId}/logo`;

export const resourceCentreBannerUploadURL = (resourceCentreId: number): string =>
  `${resourceCentreRoot}/${resourceCentreId}/banner`;

export async function uploadProfileResourceCentre(rcObj: {
  id: number;
  formData: FormData;
}): Promise<ResourceCentre> {
  const response = await Post(
    resourceCentreLogoUploadURL(rcObj.id),
    rcObj.formData,
    true,
    {},
    {
      "Content-Type": "multipart/form-data"
    }
  );
  return response.data as ResourceCentre;
}

export async function uploadBannerResourceCentre(rcObj: {
  id: number;
  formData: FormData;
}): Promise<BannerUploadResponse> {
  const response = await Post(
    resourceCentreBannerUploadURL(rcObj.id),
    rcObj.formData,
    true,
    {},
    {
      "Content-Type": "multipart/form-data"
    }
  );
  return response.data as BannerUploadResponse;
}

export async function setResourceCentreSubscriptions(
  data: ResourceCentre["subscriptions"],
  id: number
): Promise<ResourceCentre> {
  const response = await Patch(
    `${resourceCentreRoot}/${id}/subscriptions`,
    { subscriptions: data },
    true
  );
  return response.data as ResourceCentre;
}

export const getBillsMaterializedView = async (
  id: number,
  from: Date,
  until: Date
): Promise<Record<string, unknown>> => {
  const response = await Get(
    `/api/resourceCentres/${id}/billsMaterializedView/?&from=${from.toISOString()}&until=${until.toISOString()}`,
    true
  );
  return response.data as Record<string, unknown>;
};

export const getAddressOptions = async (): Promise<Array<Record<string, unknown>>> => {
  const response = await Get(`/api/getAddressOptions/`, true);
  return response?.data as Array<Record<string, unknown>>;
};

export const getSmsHistory = async (rcId: number): Promise<Array<SmsHistory>> => {
  const response = await Get(`/api/resourceCentresSMSHistory?rcId=${rcId}`, true);
  return response?.data as Promise<Array<SmsHistory>>;
};

export const getFiscalPeriod = async ({
  queryKey
}: {
  queryKey: unknown;
}): Promise<FiscalPeriodProps[]> => {
  const { data } = await Get(`/api/resourceCentres/${queryKey[1]}/fiscalPeriods`, true);
  return data as FiscalPeriodProps[];
};

export const postFiscalPeriod = async (
  fiscalPeriod: Partial<FiscalPeriodProps>
): Promise<FiscalPeriodProps> => {
  const { data } = await Post(
    `/api/resourceCentres/${fiscalPeriod.resourceCentreId}/fiscalPeriod`,
    omit(fiscalPeriod, ["resourceCentreId"]),
    true
  );
  return data as FiscalPeriodProps;
};

export const patchFiscalPeriod = async (
  period: Partial<FiscalPeriodProps>
): Promise<FiscalPeriodProps> => {
  const { data } = await Patch(
    `/api/resourceCentres/${period.resourceCentreId}/fiscalPeriod/${period.id}`,
    period,
    true
  );
  return data as FiscalPeriodProps;
};

export const getMasterFiscalPeriods = async (): Promise<FiscalPeriodProps[]> => {
  const { data } = await Get(`/api/fiscalPeriods`, true);
  return data as FiscalPeriodProps[];
};

export const postMasterFiscalPeriod = async (
  fiscalPeriod: Partial<FiscalPeriodProps>
): Promise<FiscalPeriodProps> => {
  const { data } = await Post(`/api/fiscalPeriods`, fiscalPeriod, true);
  return data as FiscalPeriodProps;
};

export const getResourceCentrePublicProfileApi = async (
  resourceCentreId: number
): Promise<PublicProfileState["profileDetails"]> => {
  const { data } = await Get(
    `/api/resourceCentre/publicProfile?resourceCentreId=${resourceCentreId}`,
    true
  );
  return data as PublicProfileState["profileDetails"];
};

export const setResourceCentrePublicProfileApi = async (
  data: PublicProfileState["profileDetails"] & { resourceCentreId: number }
): Promise<PublicProfileState["profileDetails"]> => {
  const { data: res } = await Patch(
    `/api/resourceCentre/publicProfile?resourceCentreId=${data.resourceCentreId}`,
    data,
    true
  );
  return res as PublicProfileState["profileDetails"];
};

export const getUserBranches = async (userId?: number): Promise<Partial<Array<ResourceCentre>>> => {
  const { data } = await Get(`/api/userBranches?userId=${userId || ""}`, true);
  return data as Array<ResourceCentre>;
};

export const postUserBranches = async (
  payload: UserBranchPayload
): Promise<Partial<Array<ResourceCentre>>> => {
  const { data } = await Post("/api/userBranches", payload, true);
  return data as Array<ResourceCentre>;
};

export const getResourceCentreBranches = async (
  id: number
): Promise<Partial<Array<ResourceCentre>>> => {
  const { data } = await Get(`/api/subBranches?resourceCentreId=${id}`, true);
  return data as Array<ResourceCentre>;
};

export const deleteUserBranch = async (id: number): Promise<string> => {
  const { data } = await Delete(`/api/userBranches/${id}`, true);
  return data as string;
};

export const assignRcBranch = async (payload: {
  mainResourceCentreId: number;
  branchResourceCentreId: number;
  isKitchenPharmacyBranch: boolean;
}): Promise<ResourceCentre> => {
  const { data } = await Post("/api/branch/assign", payload, true);
  return data as ResourceCentre;
};

export const unassignRcBranch = async ({
  resourceCentreId,
  mainResourceCentreId
}: {
  resourceCentreId: number;
  mainResourceCentreId: number;
}): Promise<ResourceCentre> => {
  const { data } = await Patch(
    `/api/branch/unassign/${resourceCentreId}`,
    { mainResourceCentreId },
    true
  );
  return data as ResourceCentre;
};

export const deleteEverything = async ({ id }: { id: number }): Promise<string> => {
  const { data } = await Delete(`${resourceCentreRoot}/${id}/deleteEverything`, true);
  return data as string;
};

export const getKitchenPharmacyBranch = async (): Promise<
  { resourceCentreName: string; rcId: number }[]
> => {
  const response = await Get(`/api/kitchenPharmacyBranch`, true);
  return response.data as { resourceCentreName: string; rcId: number }[];
};

export const getS3UploadPostData = async (fileName: string): Promise<unknown> => {
  const response = await Post(`/api/resourceCentre/customHeader`, { fileName });
  return response.data;
};

export const customHeaderImagePresignedS3 = async (pictureData: Blob): Promise<string> => {
  try {
    const fileName = `${Date.now()}.${pictureData.type.split("/")[1]}`;
    const postData = (await getS3UploadPostData(fileName)) as {
      id: number;
      data: Record<string, unknown>;
    };
    const s3FileName = `${postData.id}/${fileName}`;
    const formData = new FormData();

    [
      "key",
      "bucket",
      "acl",
      "X-Amz-Algorithm",
      "X-Amz-Credential",
      "X-Amz-Date",
      "Policy",
      "X-Amz-Signature"
    ].forEach((key) => {
      formData.append(key, postData.data.fields[key]);
    });
    formData.append("file", pictureData, s3FileName);
    const response = await axios.post(postData.data.url, formData, {
      headers: {
        "Content-Type": "application/octet-stream"
      }
    });
    return response.headers.location || `${postData.data.url}/${postData.data.fields.key}` || "";
  } catch (err) {
    return "";
  }
};

// get QrPaymentInfo
export const getQrPaymentInfo = async (resourceCentreId: number): Promise<QrPaymentInfo> => {
  const { data } = await Get(`${resourceCentreRoot}/${resourceCentreId}/qrPaymentInfo`, true);
  return data as QrPaymentInfo;
};

// patch QrPaymentInfo
export const patchQrPaymentInfo = async (
  resourceCentreId: number,
  updatedQrPaymentInfo: QrPaymentInfo
): Promise<ResourceCentre> => {
  const { data } = await Patch(
    `${resourceCentreRoot}/${resourceCentreId}/qrPaymentInfo`,
    updatedQrPaymentInfo,
    true
  );
  return data as ResourceCentre;
};

// upload qr picture
export const getS3UploadPostDataForQrImage = async (fileName: string): Promise<unknown> => {
  const response = await Post(`/api/resourceCentres/qrImage`, { fileName });
  return response.data;
};

/**
 * Uploads a QR image to an S3 bucket and returns the image key and URL.
 * The function handles generating the necessary file name, fetching S3 upload
 * post data, preparing the form data, and uploading the image to S3.
 *
 * @param {Blob} pictureData - The image data to be uploaded.
 * @returns {Promise<{qrImageKey: string, qrImageUrl: string}>} - The image key and URL.
 */
export const uploadQrPaymentImage = async (pictureData: Blob): Promise<unknown> => {
  const qrImageInfo = {
    qrImageKey: "",
    qrImageUrl: ""
  };

  // Generate a unique file name using the current timestamp and the file extension
  const fileName = `${Date.now()}.${pictureData.type.split("/")[1]}`;

  // Fetch the necessary data for uploading the image to S3
  const postData = (await getS3UploadPostDataForQrImage(fileName)) as {
    id: number;
    data: Record<string, unknown>;
  };

  // Construct the S3 file name using the unique id and file name
  const s3FileName = `${postData.id}/${fileName}`;

  // Prepare the form data with the S3 fields and the image file
  const formData = new FormData();
  [
    "key",
    "bucket",
    "acl",
    "X-Amz-Algorithm",
    "X-Amz-Credential",
    "X-Amz-Date",
    "Policy",
    "X-Amz-Signature"
  ].forEach((k) => {
    formData.append(k, postData.data.fields[k]);
  });
  formData.append("file", pictureData, s3FileName);

  // Upload the image to S3
  const response = await axios.post(postData.data.url, formData, {
    headers: {
      "Content-Type": "application/octet-stream"
    }
  });

  // Extract the image URL and key from the response or construct them
  qrImageInfo.qrImageUrl =
    response.headers.location || `${postData.data.url}/${postData.data.fields.key}` || "";
  qrImageInfo.qrImageKey = postData.data.fields.key;

  return qrImageInfo;
};

// delete qr image
export const deleteQrPaymentImage = async (
  rcId: number,
  paymentMethod: string,
  qrImageKey: string
): Promise<ResourceCentre> => {
  const response = await Delete("/api/resourceCentres/qrImage", true, {
    qrImageKey,
    rcId,
    paymentMethod
  });
  return response.data as ResourceCentre;
};
