import React from "react";
import moment from "moment-timezone";
import { Typography } from "@mui/material";
import { TableCell, TableRow } from "../../../../components/ListWithNestedHeader/Index";
import classNames from "../../../../helpers/classNames";
import { Imnci242 } from "../../interfaces";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import useAddressOptions from "../../../../hooks/useAddressOptions";
import { OUTCOME, REFERRED_BY, RESULT_OPTIONS } from "../../constants";
import { ethnicityMappedValue } from "../../../../models/Client";

interface Props {
  row: Imnci242;
  serialNumber: number;
}

const ListRow = ({ row, serialNumber }: Props): JSX.Element => {
  const addressOptions = useAddressOptions();

  const entryDate = convertADtoBS(moment(row.entryDate));
  const {
    imncNumber,
    classificationAndCode,
    patientSignAndSymptoms,
    vitalsAndReferral,
    treatmentOutcome,
    remarks,
    medicines,
    counsellingToMother,
    followUp,
    mothersName
  } = row.document || {};

  const followupDate = followUp?.date
    ? convertADtoBS(moment(followUp.date))
    : { bsDate: "", bsMonth: "", bsYear: "" };

  return (
    <>
      <TableRow>
        <TableCell rowSpan={2}>{serialNumber}</TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Date
        </TableCell>
        <TableCell rowSpan={2}>{entryDate.bsDate}</TableCell>
        <TableCell rowSpan={2}>{entryDate.bsMonth}</TableCell>
        <TableCell rowSpan={2}>{entryDate.bsYear}</TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Female
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography className={classNames(row.client.gender === "2" && "selected")}>1</Typography>
        </TableCell>
        <TableCell isHeaderCell>Convulsion</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.convulsion && "selected")}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Yes
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography className={classNames(patientSignAndSymptoms?.diarrhoea.value && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Breastfed</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.breastfed && "selected"
            )}
          >
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Includes all 4 points of attachment
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          Yes
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.assessBreastFeeding.includesAll4PointsOfAttachment &&
                "selected"
            )}
          >
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={14}>
          <Typography className="formattedValue">
            {classificationAndCode.majorClassification}
          </Typography>
        </TableCell>
        <TableCell rowSpan={9}>{classificationAndCode?.numberOfClassification}</TableCell>
        <TableCell rowSpan={9}>
          {classificationAndCode?.icd?.icdCode || classificationAndCode?.icd?.diagnosis}
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Ampicillin
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography className={classNames(medicines?.ampicillin && "selected")}>1</Typography>
        </TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          Breast Feeding
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(counsellingToMother?.breastFeeding && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          Improved
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(treatmentOutcome === OUTCOME.IMPROVED && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={18}>{row.referredTo?.referrer || ""}</TableCell>
        <TableCell rowSpan={3}>{followupDate?.bsDate || ""}</TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Improved
        </TableCell>
        <TableCell rowSpan={6}>
          <Typography
            className={classNames(followUp.result === RESULT_OPTIONS.IMPROVED && "selected")}
          >
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={18}>{remarks || ""}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell isHeaderCell>Respiratory rate (RR):</TableCell>
        <TableCell>{patientSignAndSymptoms?.pbsi.options.respiratoryRate || ""}</TableCell>
        <TableCell isHeaderCell>Difficult feeding</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.difficultFeeding && "selected"
            )}
          >
            2
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={8}>{row.mulDarta?.mulDartaNumber || ""}</TableCell>
        <TableCell colSpan={4} isHeaderCell>
          Name of Mother
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Male
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography className={classNames(row.client.gender === "1" && "selected")}>2</Typography>
        </TableCell>
        <TableCell isHeaderCell>Severe chest indrawing</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.severeChestIndrawing && "selected"
            )}
          >
            2
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          No
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(!patientSignAndSymptoms?.diarrhoea.value && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          How many times in 24 hours
        </TableCell>
        <TableCell rowSpan={2}>
          {patientSignAndSymptoms?.breastFeedingProblem.options.breastFeedTimesIn24Hours || ""}
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Amoxycillin
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography className={classNames(medicines.amoxycillin && "selected")}>2</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2} colSpan={4}>
          {mothersName}
        </TableCell>
        <TableCell isHeaderCell>Nasal flaring</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.nasalFlaring && "selected")}
          >
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          No
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(!patientSignAndSymptoms?.diarrhoea.value && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          Day:
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2} isHeaderCell>
          Age in weeks
        </TableCell>
        <TableCell rowSpan={2}>{moment().diff(moment(row.client.dob), "weeks")}</TableCell>
        <TableCell isHeaderCell>Grunting</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.grunting && "selected")}
          >
            4
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          How many days?
        </TableCell>
        <TableCell rowSpan={2}>{patientSignAndSymptoms?.diarrhoea.options.days || ""}</TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Receive other food/ drinks
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.receiveOtherFoodDrinks &&
                "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={8} colSpan={2} className="formattedValue">
          {medicines.others}
        </TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          Keep warm
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(counsellingToMother?.keepWarm && "selected")}>
            2
          </Typography>
        </TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          Referred
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(treatmentOutcome === OUTCOME.REFERRED && "selected")}>
            2
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Name of child
        </TableCell>
        <TableCell isHeaderCell>Unable to feed</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.unableToFeed && "selected")}
          >
            5
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} rowSpan={2}>
          {row.client?.firstName || ""}
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Weight (kg)
        </TableCell>
        <TableCell rowSpan={2}>{vitalsAndReferral.weight || ""}</TableCell>
        <TableCell isHeaderCell>Buldging fontanelle</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.buldgingFontanelle && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Blood
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(patientSignAndSymptoms?.diarrhoea.options.blood && "selected")}
          >
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          How often? Times:
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography>
            {patientSignAndSymptoms?.breastFeedingProblem.options.otherFoodDrinksFeedTimes || ""}
          </Typography>
        </TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Includes all 4 points of Position
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          Yes
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.assessBreastFeeding.includesAll4PointsOfPosition && "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={3}>{followupDate.bsMonth}</TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Same
        </TableCell>
        <TableCell rowSpan={6}>
          <Typography className={classNames(followUp.result === RESULT_OPTIONS.SAME && "selected")}>
            2
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell isHeaderCell>Umbilicus infection to skin</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms.pbsi.options.umbilicusInfectionToSkin && "selected"
            )}
          >
            7
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell isHeaderCell colSpan={4}>
          Caste
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Temp (.C)
        </TableCell>
        <TableCell rowSpan={2}>{vitalsAndReferral?.temperature || ""}</TableCell>
        <TableCell isHeaderCell>Umbilicus red or with Pus</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms.pbsi.options.umbilicusRedOrWithPus && "selected"
            )}
          >
            8
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Lethargic/ Unconscious
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms.diarrhoea.options.lethargicUnconscious && "selected"
            )}
          >
            4
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Feed by bottle</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms.breastFeedingProblem.options.feedByBottle && "selected"
            )}
          >
            4
          </Typography>
        </TableCell>

        <TableCell rowSpan={5} isHeaderCell>
          Immediate visit
        </TableCell>
        <TableCell rowSpan={5}>
          <Typography className={classNames(counsellingToMother.intermediateVisit && "selected")}>
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={5} isHeaderCell>
          LAMA/Absconded
        </TableCell>
        <TableCell rowSpan={5}>
          <Typography
            className={classNames(treatmentOutcome === OUTCOME.LAMA_ABSCONDED && "selected")}
          >
            3
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2} colSpan={4}>
          {row.client?.lastName}
        </TableCell>
        <TableCell isHeaderCell>{`Temp > 37.5 C`}</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.tempAbove37 && "selected")}
          >
            9
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Feed by spoon
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.feedBySpoon && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          No
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(
              !patientSignAndSymptoms?.assessBreastFeeding.includesAll4PointsOfPosition &&
                "selected"
            )}
          >
            4
          </Typography>
        </TableCell>
        <TableCell rowSpan={9} isHeaderCell>
          Number of classification:
        </TableCell>
        <TableCell rowSpan={9} isHeaderCell>
          ICD Code for one major diagnosis:
        </TableCell>
        <TableCell rowSpan={3} isHeaderCell>
          Month:
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={8}>{imncNumber}</TableCell>
        <TableCell isHeaderCell rowSpan={2} colSpan={2}>
          Referred by
        </TableCell>
        <TableCell isHeaderCell>{`Temp < 35.5 C`}</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.tempBelow37 && "selected")}
          >
            10
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Restless/ Irritable
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.restlessIrritable && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Ethnicity code
        </TableCell>
        <TableCell colSpan={2}>
          {`${
            row.client?.ethnicity
              ? `${ethnicityMappedValue[row.client.ethnicity]} (${row.client?.ethnicity || ""})`
              : ""
          }`}
        </TableCell>
        <TableCell isHeaderCell>{`Skin pustules: Severe or >10`}</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.skinPustulesAbove10 && "selected"
            )}
          >
            11
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Very low weight
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.veryLowWeight && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Address:
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          FCHV
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(vitalsAndReferral.referredBy === REFERRED_BY.FCHV && "selected")}
          >
            1
          </Typography>
        </TableCell>

        <TableCell isHeaderCell>{`Skin pustules: < 10`}</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.skinPustulesBelow10 && "selected"
            )}
          >
            12
          </Typography>
        </TableCell>

        <TableCell rowSpan={2} isHeaderCell>
          Sunken eyes
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.sunkenEyes && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={6}>
          Suckling: Effectively
        </TableCell>
        <TableCell isHeaderCell rowSpan={3}>
          Yes
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.assessBreastFeeding.sucklingEffectively && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} colSpan={2} isHeaderCell>
          IF Gentamycin
        </TableCell>
        <TableCell rowSpan={3}>{followupDate.bsYear}</TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Worse
        </TableCell>
        <TableCell rowSpan={6}>
          <Typography
            className={classNames(followUp.result === RESULT_OPTIONS.WORSE && "selected")}
          >
            3
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} rowSpan={2} isHeaderCell>
          District
        </TableCell>
        <TableCell colSpan={2} rowSpan={2}>
          {row.client.palikaId
            ? addressOptions.find((item) => item.palikaId === row.client.palikaId)?.districtName
            : ""}
        </TableCell>
        <TableCell isHeaderCell>Lethargic/ Unconscious</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.lethargicUnconscious && "selected"
            )}
          >
            13
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Low weight
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.lowWeight && "selected"
            )}
          >
            7
          </Typography>
        </TableCell>
        <TableCell rowSpan={5} isHeaderCell>
          Regular follow up
        </TableCell>
        <TableCell rowSpan={5}>
          <Typography className={classNames(counsellingToMother?.regularFollowUp && "selected")}>
            4
          </Typography>
        </TableCell>
        <TableCell rowSpan={5} isHeaderCell>
          Death
        </TableCell>
        <TableCell rowSpan={5}>
          <Typography className={classNames(treatmentOutcome === OUTCOME.DEATH && "selected")}>
            4
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell isHeaderCell rowSpan={2}>
          PHC/ORC
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              vitalsAndReferral.referredBy === REFERRED_BY.PHC_ORC && "selected"
            )}
          >
            2
          </Typography>
        </TableCell>

        <TableCell isHeaderCell>Less than normal movement</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.lessThanNormalMovement && "selected"
            )}
          >
            14
          </Typography>
        </TableCell>

        <TableCell rowSpan={2} isHeaderCell>
          Skin pinch very slowly
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.skinPinchVerySlowly && "selected"
            )}
          >
            7
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Others</TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.one && "selected")}>1</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.five && "selected")}>
            5
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Rural/ Municipality, Ward no.
        </TableCell>
        <TableCell isHeaderCell>Jaundice upto hands feet</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.pbsi.options.jaundiceUpToHandsFeet && "selected"
            )}
          >
            15
          </Typography>
        </TableCell>
        <TableCell isHeaderCell rowSpan={2}>
          Normal weight
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.normalWeight && "selected"
            )}
          >
            8
          </Typography>
        </TableCell>

        <TableCell rowSpan={3} isHeaderCell>
          No
        </TableCell>
        <TableCell rowSpan={3}>
          <Typography
            className={classNames(
              !patientSignAndSymptoms.assessBreastFeeding.sucklingEffectively && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell rowSpan={3}>-</TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.two && "selected")}>2</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.six && "selected")}>6</Typography>
        </TableCell>

        <TableCell rowSpan={3} isHeaderCell>
          Year
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={2} colSpan={4}>
          {row.client.palikaId
            ? addressOptions.find((item) => item.palikaId === row.client.palikaId)?.palikaName
            : ""}{" "}
          {row.client.wardNo}
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          HF
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(vitalsAndReferral?.referredBy === REFERRED_BY.HF && "selected")}
          >
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Jaundice</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.jaundice && "selected")}
          >
            16
          </Typography>
        </TableCell>

        <TableCell rowSpan={2} isHeaderCell>
          Skin pinch slowly
        </TableCell>
        <TableCell rowSpan={2}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.skinPinchSlowly && "selected"
            )}
          >
            8
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.three && "selected")}>
            3
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classNames(medicines?.gentamycin.seven && "selected")}>
            7
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell isHeaderCell>Pus from Eye</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.pbsi.options.pusFromEye && "selected")}
          >
            17
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Oral Ulcer/Thrush</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.breastFeedingProblem.options.oralUlcerThrush && "selected"
            )}
          >
            9
          </Typography>
        </TableCell>
        <TableCell colSpan={2}>
          <Typography className={classNames(medicines?.gentamycin.four && "selected")}>
            4
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListRow;
