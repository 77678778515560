import { startCase } from "lodash";
import { ServiceProvider } from "../interfaces/ServiceProvidersInterface";
import { spFullNameSelector } from "../reducers/serviceProvider";

export enum MODULE {
  ASSESSMENT = "assessment",
  BILL = "bill",
  LAB_TEST = "labTest",
  STOCK = "stock",
  INTERNAL_STOCK = "internalStock",
  CLIENT = "client",
  EMPLOYEE_LEDGER = "employeeLedger",
  SERVICE_PROVIDER_LEDGERS = "serviceProviderLedger",
  HISTORY = "history",
  ESTIMATES = "estimates",
  ASSOCIATE_COMPANY = "associateCompany",
  CONSENT_FORM = "consentForm",
  SURVERY_FORM = "surveyForm",
  SURVERY_FORM_TEMPLATE = "surveyFormTemplate",
  STOCK_EXPIRY_REPORT = "stockExpiryReport",
  STOCK_LEDGER_REPORT = "stockLedgerReport",
  STOCK_SUMMARY_REPORT = "stockSummaryReport",
  MUL_DARTA = "mulDarta",
  OPD_REGISTER = "opdRegister",
  IMNCI_REGISTER = "imnciRegister",
  IPD_NUMBER = "ipdNumber",
  RISK_INFO = "riskInformation",
  REPORT = "report",
  USER = " user",
  SUPPLIER = "supplier",
  OPD_REQUEST_FORM = "opdRequestForm",
  SMS_PROVIDER = "smsProvider"
}

type Modules = (typeof MODULE)[keyof typeof MODULE];

export const commonSuccessMessage = "Successfully created.";
export const commonErrorMessage = `Sorry!, Something went wrong.`;
export const commonDeleteMessage = "Successfully Deleted.";
export const authorizedSpDeletedErrorMessage = (deletedSps: ServiceProvider[]): string =>
  `${deletedSps.map((sp) => spFullNameSelector(sp)).join(", ")} ${
    deletedSps.length > 1 ? "are" : "is"
  } deleted. Please remove from settings or assign another authorized person.`;

export const createSuccessMessage = (module: Modules): string =>
  `Successfully, created ${startCase(module)}.`;
export const updateSuccessMessage = (module: Modules): string =>
  `Successfully, updated ${startCase(module)}.`;
export const createErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while creating ${startCase(module)}.`;
export const updateErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while updating ${startCase(module)}.`;
export const errorFetchMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while getting ${startCase(module)}.`;
export const deleteSuccessMessage = (module: Modules): string => `Successfully, deleted ${module}.`;
export const deleteErrorMessage = (module: Modules): string =>
  `Sorry!, Something went wrong while deleting ${module}.`;
