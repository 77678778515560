import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Filters from "../Filters";
import { RootState } from "../../../store";
import { t } from "../../../components/translate";
import DaybookList from "../../Daybook/DaybookList";
import { getDaybookData } from "../../../api/daybook";
import { rupeeDisplay } from "../../../helpers/rupee";
import useMobileScreen from "../../../hooks/useMobileScreen";
import DaybookEntryInfo from "../../Daybook/DaybookEntryInfo";
import { DaybookInterface } from "../../../interfaces/Daybook";
import { notificationAdd } from "../../../actions/notification";
import { CalendarFunctions } from "../../../components/Calendar";
import useIsReactNativeWebView from "../../../hooks/useIsReactNativeWebView";
import { DaybookParticulars, DaybookTransactionEnum } from "../../../enum/Daybook";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";

const DaybookReport = (): JSX.Element => {
  const pageSize = 20;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const [closingBalance, setClosingBalance] = useState(0);
  const [selectedRow, setSelectedRow] = useState<Partial<DaybookInterface> | null>(null);
  const [filters, setFilters] = useState({
    from: CalendarFunctions.startOfDay(new Date()).toDate(),
    until: new Date()
  });
  const [daybookData, setDaybookData] = useState<{ results: DaybookInterface[]; total: number }>({
    results: [],
    total: 0
  });

  const isMobileScreen = useMobileScreen();
  const { isRnWebView } = useIsReactNativeWebView();

  useEffect(() => {
    (async () => {
      try {
        const res = await getDaybookData({ page, pageSize, ...filters });
        setClosingBalance(res.closingBalance);

        const data = {
          results: [
            ...res.daybookRecords.results,

            // add receipt today here
            {
              id: -1,
              created_at: CalendarFunctions.endOfDay(new Date(filters.until)).toISOString(),
              transactionType: DaybookTransactionEnum.INCOME,
              particulars: `Receipt amount from ${
                convertADtoBS(new Date(filters.from)).formatted4
              } to ${convertADtoBS(new Date(filters.until)).formatted4}`,
              amount: res.totalReceipt,
              // eslint-disable-next-line max-len
              runningBalance: res.closingBalance,
              remarks: "Amount received in the selected period"
            },
            // add closing balance here
            {
              id: -2,
              created_at: CalendarFunctions.endOfDay(new Date(filters.until)).toISOString(),
              transactionType: DaybookParticulars.CLOSING_BALANCE,
              particulars: `Closing Balance on ${
                convertADtoBS(new Date(filters.until)).formatted4
              }`,
              amount: res.closingBalance,
              remarks: "Closing balance in the selected period"
            }
          ].sort((a, b) => Number(new Date(a.created_at)) - Number(new Date(b.created_at))),
          total: res.daybookRecords.total
        };
        setDaybookData(data);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get request forms",
            autoTimeout: true
          })
        );
      }
    })();
  }, [page, dispatch, filters, rcId]);

  return (
    <Box overflow="auto hidden">
      <Box minWidth={isRnWebView ? "1000px" : "auto"}>
        <Box mx="32px">
          <Filters
            filters={filters}
            onSetFilters={(newfilters) => {
              setFilters(newfilters);
              setPage(0);
            }}
          />
        </Box>
        <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
          <Box
            marginTop="15px"
            width={isMobileScreen ? "960px" : "100%"}
            height="calc(100vh - 190px)"
          >
            <DaybookList
              page={page}
              setPage={setPage}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              daybookData={daybookData}
              isListForReport
            />

            {selectedRow && (
              <DaybookEntryInfo
                onClose={() => setSelectedRow(null)}
                selectedRow={selectedRow}
                daybookData={daybookData}
                setDaybookData={setDaybookData}
                isInfoRenderedInReport
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className="salesReportTotalBarStyle">
        <Box sx={{ paddingLeft: "20px", width: "180px", fontSize: "medium" }}>
          {convertADtoBS(CalendarFunctions.endOfDay(new Date(filters.until))).formatted4}
        </Box>
        <Box sx={{ display: "flex", flex: 1, fontSize: "nedium" }}>
          {t("daybook.closingBalance")}
        </Box>
        <Typography sx={{ width: "200px" }}>
          {rupeeDisplay(closingBalance.toFixed(2) || 0)}
        </Typography>
      </Box>
    </Box>
  );
};

export default DaybookReport;
