import React from "react";
import moment from "moment-timezone";
import { Typography } from "@mui/material";
import { TableCell, TableRow } from "../../../../components/ListWithNestedHeader/Index";
import classNames from "../../../../helpers/classNames";
import { ethnicityMappedValue } from "../../../../models/Client";
import { Imnci241 } from "../../interfaces";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import useAddressOptions from "../../../../hooks/useAddressOptions";

interface Props {
  row: Imnci241;
  serialNumber: number;
}

const ListRow = ({ row, serialNumber }: Props): JSX.Element => {
  const addressOptions = useAddressOptions();

  const entryDate = convertADtoBS(moment(row.entryDate));
  const {
    imncNumber,
    classificationAndCode,
    patientSignAndSymptoms,
    vitalsAndReferral,
    treatmentOutcome,
    remarks,
    medicines,
    counsellingToMother,
    followUp
  } = row.document || {};

  const followupDate = followUp?.date
    ? convertADtoBS(moment(followUp.date))
    : { bsDate: "", bsMonth: "", bsYear: "" };

  return (
    <>
      <TableRow>
        <TableCell>{serialNumber}</TableCell>
        <TableCell isHeaderCell>Date</TableCell>
        <TableCell>{entryDate.bsDate}</TableCell>
        <TableCell>{entryDate.bsMonth}</TableCell>
        <TableCell>{entryDate.bsYear}</TableCell>
        <TableCell isHeaderCell>Female</TableCell>
        <TableCell>
          <Typography className={classNames(row.client.gender === "2" && "selected")}>1</Typography>
        </TableCell>
        <TableCell isHeaderCell>Yes</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.generalDangerSign.value && "selected")}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Yes</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.acuteRespiratoryInfection.value && "selected"
            )}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Yes</TableCell>
        <TableCell>
          <Typography className={classNames(patientSignAndSymptoms?.diarrhoea.value && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Yes
        </TableCell>
        <TableCell>
          <Typography className={classNames(patientSignAndSymptoms?.fever.value && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Yes</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.earInfection.value && "selected")}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Severe wasting</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.severeWasting && "selected"
            )}
          >
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={10}>
          <Typography className="formattedValue">
            {classificationAndCode?.majorClassification}
          </Typography>
        </TableCell>
        <TableCell rowSpan={16}>{classificationAndCode?.numberOfClassification}</TableCell>
        <TableCell rowSpan={16}>
          {classificationAndCode?.icd?.icdCode || classificationAndCode?.icd?.diagnosis}
        </TableCell>
        <TableCell rowSpan={16}>
          <Typography className="formatedValue">{medicines || ""}</Typography>
        </TableCell>
        <TableCell isHeaderCell>Food</TableCell>
        <TableCell>
          <Typography className={classNames(counsellingToMother?.food && "selected")}>1</Typography>
        </TableCell>
        <TableCell isHeaderCell>Improved</TableCell>
        <TableCell>
          <Typography className={classNames(treatmentOutcome === "improved" && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={16}>{row.referredTo?.referrer || ""}</TableCell>
        <TableCell rowSpan={2}>{followupDate?.bsDate}</TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Improved</Typography>
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(followUp?.result === "improved" && "selected")}>
            1
          </Typography>
        </TableCell>
        <TableCell rowSpan={16}>{remarks || ""}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>{row.mulDarta?.mulDartaNumber}</TableCell>
        <TableCell colSpan={4} isHeaderCell>
          Name
        </TableCell>
        <TableCell isHeaderCell>Male</TableCell>
        <TableCell>
          <Typography className={classNames(row.client.gender === "1" && "selected")}>2</Typography>
        </TableCell>
        <TableCell isHeaderCell>No</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.generalDangerSign.value && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>No</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              !patientSignAndSymptoms?.acuteRespiratoryInfection.value && "selected"
            )}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>No</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.diarrhoea.value && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          No
        </TableCell>
        <TableCell>
          <Typography className={classNames(!patientSignAndSymptoms?.fever.value && "selected")}>
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>No</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.earInfection.value && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Oedema feet</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.nutritionStatus.oedemaFeet && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Fluid</TableCell>
        <TableCell>
          <Typography className={classNames(counsellingToMother?.fluid && "selected")}>
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Referred</TableCell>
        <TableCell>
          <Typography className={classNames(treatmentOutcome === "referred" && "selected")}>
            2
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={14}>{imncNumber || ""}</TableCell>
        <TableCell colSpan={4}>{row.client?.firstName || ""}</TableCell>
        <TableCell isHeaderCell>Age in months</TableCell>
        <TableCell>{moment().diff(moment(row.client.dob), "months")}</TableCell>
        <TableCell isHeaderCell>Unable to drink</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.generalDangerSign.options.unableToDrink && "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Days:
        </TableCell>
        <TableCell isHeaderCell>Days:</TableCell>
        <TableCell>{patientSignAndSymptoms?.diarrhoea.options.days || ""}</TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Days:
        </TableCell>
        <TableCell>{patientSignAndSymptoms?.diarrhoea.options.days || ""}</TableCell>
        <TableCell isHeaderCell>Ear pain</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.earInfection.options.earPain && "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Very low weight</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.veryLowWeight && "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Immediate visit</TableCell>
        <TableCell>
          <Typography className={classNames(counsellingToMother?.intermediateVisit && "selected")}>
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>LAMA / Absconded</TableCell>
        <TableCell>
          <Typography className={classNames(treatmentOutcome === "lamaAbsconded" && "selected")}>
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Day</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Caste
        </TableCell>
        <TableCell isHeaderCell>Weight (kg)</TableCell>
        <TableCell>{vitalsAndReferral?.weight || ""}</TableCell>
        <TableCell isHeaderCell>Vomiting all</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.generalDangerSign.options.vomiting && "selected"
            )}
          >
            4
          </Typography>
        </TableCell>
        <TableCell colSpan={2}>
          {patientSignAndSymptoms?.acuteRespiratoryInfection.options.days || ""}
        </TableCell>
        <TableCell isHeaderCell>Blood</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.diarrhoea.options.blood && "selected")}
          >
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Malaria Risk
        </TableCell>
        <TableCell isHeaderCell>Yes</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.malariaRisk && "selected")}
          >
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Ear discharge</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.earInfection.options.earDischarge && "selected"
            )}
          >
            4
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Low weight</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.nutritionStatus.lowWeight && "selected")}
          >
            4
          </Typography>
        </TableCell>
        <TableCell rowSpan={13} isHeaderCell>
          Regular Follow Up
        </TableCell>
        <TableCell rowSpan={13}>
          <Typography className={classNames(counsellingToMother?.regularFollowUp && "selected")}>
            4
          </Typography>
        </TableCell>
        <TableCell rowSpan={13} isHeaderCell>
          Death
        </TableCell>
        <TableCell rowSpan={13}>
          <Typography className={classNames(treatmentOutcome === "death" && "selected")}>
            4
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>{row.client?.lastName}</TableCell>
        <TableCell isHeaderCell>Temp (° C)</TableCell>
        <TableCell>{vitalsAndReferral?.temperature || ""}</TableCell>
        <TableCell isHeaderCell>Convulsion</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.generalDangerSign.options.convulsion && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Respiratory Rate
        </TableCell>
        <TableCell isHeaderCell>Lethargic/Unconscious</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.lethargicUnconscious && "selected"
            )}
          >
            4
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>No</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.fever.options.malariaRisk && "selected")}
          >
            4
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Days:</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.earInfection.options.days && "selected")}
          >
            5
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Normal weight</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.normalWeight && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
        <TableCell rowSpan={2}>{followupDate?.bsMonth}</TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Same</Typography>
        </TableCell>
        <TableCell rowSpan={4}>
          <Typography className={classNames(followUp?.result === "same" && "selected")}>
            2
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Ethnicity code
        </TableCell>
        <TableCell colSpan={2}>
          {`${
            row.client?.ethnicity
              ? `${ethnicityMappedValue[row.client.ethnicity]} (${row.client?.ethnicity || ""})`
              : ""
          }`}
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Referred by
        </TableCell>
        <TableCell rowSpan={11} isHeaderCell>
          Lethargic/ Unconscious
        </TableCell>
        <TableCell rowSpan={11}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.generalDangerSign.options.lethargicUnconscious && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell colSpan={2}>
          {patientSignAndSymptoms?.acuteRespiratoryInfection.options.respiratoryRate || ""}
        </TableCell>
        <TableCell isHeaderCell>Irritable</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.irritable && "selected"
            )}
          >
            5
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Stiff neck
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.stiffNeck && "selected")}
          >
            5
          </Typography>
        </TableCell>
        <TableCell rowSpan={11} isHeaderCell>
          Tender swelling behind the ear
        </TableCell>
        <TableCell rowSpan={11}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.earInfection.options.tenderSwellingBehindTheEar && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          MUAC
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Address
        </TableCell>
        <TableCell isHeaderCell>FCHV</TableCell>
        <TableCell>
          <Typography
            className={classNames(vitalsAndReferral?.referredBy === "FCHV" && "selected")}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Chest indrawing</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.acuteRespiratoryInfection.options.chestIndrawing && "selected"
            )}
          >
            1
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Sunken eyes</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.sunkenEyes && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell colSpan={2} isHeaderCell>
          Nasal discharge
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.fever.options.nasalDischarge && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Red</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.muac === "red" && "selected"
            )}
          >
            6
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Month</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          District
        </TableCell>
        <TableCell colSpan={2}>
          {row.client.palikaId
            ? addressOptions.find((item) => item.palikaId === row.client.palikaId)?.districtName
            : ""}
        </TableCell>
        <TableCell isHeaderCell>PHC/ORC</TableCell>
        <TableCell>
          <Typography
            className={classNames(vitalsAndReferral?.referredBy === "PHC/ORC" && "selected")}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Stridor</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.acuteRespiratoryInfection.options.striodor && "selected"
            )}
          >
            2
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Unable to drink</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.unableToDrink && "selected"
            )}
          >
            7
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          Microscopic
        </TableCell>
        <TableCell isHeaderCell>+ve</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.microscopic && "selected")}
          >
            7
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Yellow</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.muac === "yellow" && "selected"
            )}
          >
            7
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} isHeaderCell>
          Rural/Municipality, Ward no.
        </TableCell>
        <TableCell rowSpan={8} isHeaderCell>
          HF
        </TableCell>
        <TableCell rowSpan={8}>
          <Typography className={classNames(vitalsAndReferral?.referredBy === "HF" && "selected")}>
            3
          </Typography>
        </TableCell>
        <TableCell rowSpan={8} isHeaderCell>
          Wheezing
        </TableCell>
        <TableCell rowSpan={8}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.acuteRespiratoryInfection.options.wheezing && "selected"
            )}
          >
            3
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Drinks eagerly</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.drinksEagerly && "selected"
            )}
          >
            8
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>-ve</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.fever.options.microscopic && "selected")}
          >
            8
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Green</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.muac === "green" && "selected"
            )}
          >
            8
          </Typography>
        </TableCell>
        <TableCell rowSpan={4}>{followupDate?.bsYear || ""}</TableCell>
        <TableCell rowSpan={8} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Worse</Typography>
        </TableCell>
        <TableCell rowSpan={8}>
          <Typography className={classNames(followUp?.result === "worse" && "selected")}>
            3
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} rowSpan={7}>
          {row.client.palikaId
            ? addressOptions.find((item) => item.palikaId === row.client.palikaId)?.palikaName
            : ""}
          {row.client.wardNo}
        </TableCell>
        <TableCell isHeaderCell>Skin pinch very slowly</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.skinPinchVerySlowly && "selected"
            )}
          >
            9
          </Typography>
        </TableCell>
        <TableCell rowSpan={2} isHeaderCell>
          RDT
        </TableCell>
        <TableCell isHeaderCell>+ve</TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.rdt && "selected")}
          >
            9
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Severe pallor</TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.severePallor && "selected"
            )}
          >
            9
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell rowSpan={6} isHeaderCell>
          Skin pinch slowly
        </TableCell>
        <TableCell rowSpan={6}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.diarrhoea.options.skinPinchSlowly && "selected"
            )}
          >
            10
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>-ve</TableCell>
        <TableCell>
          <Typography
            className={classNames(!patientSignAndSymptoms?.fever.options.rdt && "selected")}
          >
            10
          </Typography>
        </TableCell>
        <TableCell rowSpan={6} isHeaderCell>
          Some pallor
        </TableCell>
        <TableCell rowSpan={6}>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.nutritionStatus.severePallor && "selected"
            )}
          >
            10
          </Typography>
        </TableCell>
        <TableCell isHeaderCell>Others</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Falciparum
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.falciparum && "selected")}
          >
            11
          </Typography>
        </TableCell>
        <TableCell rowSpan={5}>-</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Non Falciparum
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.fever.options.nonFalciparum && "selected"
            )}
          >
            12
          </Typography>
        </TableCell>
        <TableCell rowSpan={4} isHeaderCell>
          <Typography sx={{ transform: "rotate(-90deg)" }}>Year</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          General rash
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.generalRash && "selected")}
          >
            13
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Hazy Cornea / Oral Ulcer (deep & spread)
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(patientSignAndSymptoms?.fever.options.hazyCornea && "selected")}
          >
            14
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} isHeaderCell>
          Pus From Eye / Oral Ulcer
        </TableCell>
        <TableCell>
          <Typography
            className={classNames(
              patientSignAndSymptoms?.fever.options.pusFromEyeOralUlcer && "selected"
            )}
          >
            15
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListRow;
