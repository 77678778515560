/* eslint-disable no-param-reassign */
import { Box } from "@mui/material";
import produce from "immer";
import sortBy from "lodash/sortBy";
import * as React from "react";
import { getDiscountField } from "../../../actions/helpers/billHelper";
import { getStockAndServiceByIds } from "../../../api/bill";
import * as api from "../../../api/stock";
import { tl } from "../../../components/translate";
import classNames from "../../../helpers/classNames";
import useMobileScreen from "../../../hooks/useMobileScreen";
import {
  BillDocumentType,
  BillItemFieldTypes,
  BillItemSource,
  BillItemType
} from "../../../interfaces/BillInterfaces";
import { Stock, StockProducts } from "../../../interfaces/StockInterfaces";
import styles from "./BillEditor.module.css";
import BillItem from "./BillItem";
import { BillEditorWarning } from "./BillEditor";

// return false if the product was deleted.
const isActiveProduct = (item, services, stocks) => {
  if (item.source === BillItemSource.services) {
    const relatedService = services.find((s) => s.id === (item.productId || item.productData.id));
    if (relatedService) {
      return relatedService.active;
    }
  }
  if (item.source === BillItemSource.stocks) {
    const relatedStock = stocks.find((stock) => stock.product.id === item.productData.id);
    if (relatedStock) {
      return relatedStock.product.active;
    }
  }
  return true;
};

const adjustAvailableQuantity = (bill: BillDocumentType, availableStocks: Stock[]) => ({
  ...bill,
  billItems: bill.billItems.map((billItem) => {
    const stock = availableStocks.find(
      (item) =>
        item.batchId === billItem.batchInfo.batchId && item.productId === billItem.productData.id
    );
    if (Number(stock?.quantity) < Number(billItem.quantity)) {
      return {
        ...billItem,
        quantity: Number(stock?.quantity) || 0,
        showAdjustAvailableQtyMsg: true
      };
    }
    return billItem;
  })
});
interface BillItemsProps {
  draft: BillDocumentType;
  updateDraft: (dispatch) => Promise<void>;
  onRowRemove: (dispatch) => Promise<void>;
  products: StockProducts[];
  isCreditNote: boolean;
  setWarningMsg: ({ message, type }: BillEditorWarning) => void;
  lastItemRef: React.RefObject<HTMLInputElement> | null;
  focusAddItemButton: () => void;
}
const BillItems = ({
  draft,
  updateDraft,
  onRowRemove,
  products = [],
  isCreditNote,
  setWarningMsg,
  lastItemRef,
  focusAddItemButton
}: BillItemsProps): React.ReactElement => {
  const discountField = getDiscountField(draft.settings.discountSettings);
  const [batchOptions, setBatchOptions] = React.useState({});
  const [selectedStockProducts, setSelectedStockProducts] = React.useState<Stock[]>([]);
  const isMobileScreen = useMobileScreen();

  const getBatchOptions = async (id) => {
    if (batchOptions[id]) {
      return batchOptions[id];
    }
    try {
      const batchOptionsResponse: Stock[] = await api.getStockByProductId(id);
      const sortedFilteredBatchOptionsResponse = sortBy(
        batchOptionsResponse,
        (date) => new Date((date as unknown as Stock).expiryDate)
      ).filter((batch) => Number((batch as unknown as Stock).quantity) > 0);
      setBatchOptions((prevState) => ({ ...prevState, [id]: sortedFilteredBatchOptionsResponse }));
      return sortedFilteredBatchOptionsResponse;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Failed to fetch batch options", error);
      return "";
    }
  };

  const initBatchStates = async () => {
    const uniqueProductIds = draft?.billItems.reduce(
      (productIds: number[], product: Partial<BillItemType>) => {
        if (
          product.batchInfo?.batchId &&
          !productIds.includes(product.productId || (product.productData?.id as number))
        ) {
          return [...productIds, product.productId || product.productData?.id];
        }
        return productIds;
      },
      []
    );

    const uniqueServiceIds = draft?.billItems
      .reduce((serviceIds: number[], item: Partial<BillItemType>) => {
        if (item.source === BillItemSource.services && !serviceIds.includes(item.productId)) {
          serviceIds.push(item.productId);
        }
        return serviceIds;
      }, [])
      .filter(Number);

    if (uniqueProductIds.length || uniqueServiceIds.length) {
      try {
        const productsAndServices = await getStockAndServiceByIds(
          uniqueProductIds as number[],
          uniqueServiceIds as number[]
        );
        const eliminateZeroQty = productsAndServices.filter(
          (item) => Number(item.quantity) && item.source === BillItemSource.stocks
        );
        const services = productsAndServices.filter(
          (item) => item.source === BillItemSource.services
        );
        setBatchOptions(
          sortBy(
            eliminateZeroQty,
            (date) => new Date((date as unknown as Stock).expiryDate)
          ).reduce((prev, curr) => {
            if (!prev[curr.productId]) {
              prev[curr.productId] = [];
            }
            prev[curr.productId].push(curr);
            return prev;
          }, {})
        );
        const selectedBatchProducts = eliminateZeroQty.filter((batchResponse) =>
          Boolean(
            draft.billItems.find(
              (item) =>
                (item.productData?.id || item.productId) === batchResponse.productId &&
                item.batchInfo?.batchId === batchResponse.batchId
            )
          )
        );
        if (selectedBatchProducts.length) {
          setSelectedStockProducts(selectedBatchProducts);
        }
        if (isCreditNote) return;
        const soldOutBatchProducts = draft.billItems.filter((billItem) =>
          billItem.batchInfo?.batchId
            ? !eliminateZeroQty.some(
                (item) =>
                  item.batchId === billItem.batchInfo?.batchId &&
                  item.productId === (billItem.productId || billItem.productData?.id)
              )
            : false
        );
        const deletedProductsNames = [] as string[];
        const filteredSoldOutProducts = produce(draft, (draftItem) => {
          draftItem.billItems = draftItem.billItems.filter((billItem) => {
            if (
              !soldOutBatchProducts.some(
                (product) =>
                  product.batchInfo?.batchId === billItem.batchInfo?.batchId &&
                  product.productData?.id === (billItem.productData?.id || billItem.productId)
              )
            ) {
              return true;
            }
            deletedProductsNames.push(billItem.description as string);
            return false;
          });
        });
        const filterDeletedProducts = produce(filteredSoldOutProducts, (billDraft) => {
          billDraft.billItems = billDraft.billItems.filter((billItem) => {
            if (isActiveProduct(billItem, services, selectedBatchProducts)) {
              return true;
            }
            deletedProductsNames.push(billItem.description as string);
            return false;
          });
        });
        if (filterDeletedProducts.billItems.length < draft?.billItems.length) {
          const warningMsg = `${
            deletedProductsNames.length > 1
              ? `${deletedProductsNames.join(", ")} are`
              : `${deletedProductsNames[0]} is`
          }`;
          setWarningMsg({
            message: `${warningMsg} out of stock or deleted from system and removed from bill.`,
            type: null
          });
        }
        updateDraft(adjustAvailableQuantity(filterDeletedProducts, selectedBatchProducts));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Failed to fetch product batch options", error);
      }
    }
  };

  React.useEffect(() => {
    if (draft || draft.isLocalStorageData) {
      initBatchStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.id, draft?.kitchenBranchPharmacyId, draft.isLocalStorageData]);

  const headerCell = (column) => tl(`billing.billItem.${column}`);

  return (
    <Box paddingTop="10px">
      <Box display="flex" padding="6px 0" borderBottom="1px solid lightgray" sx={{ gap: "5px" }}>
        {!isMobileScreen &&
          draft.settings.showFields
            .filter((column) => {
              if (
                [BillItemFieldTypes.discountAmt, BillItemFieldTypes.discountPercent].includes(
                  column
                )
              ) {
                return discountField === column;
              }
              return column;
            })
            .map((column) => {
              if (isCreditNote && column === BillItemFieldTypes.delivered) {
                return null;
              }
              return (
                <Box
                  key={column}
                  display={isMobileScreen ? "none" : "block"}
                  flexBasis="116px"
                  fontWeight="500"
                  fontSize="12px"
                  paddingLeft="8px"
                  style={{ textTransform: "uppercase" }}
                  className={classNames(styles.headerCell, styles[`headerCell-${column}`])}
                >
                  {headerCell(column)}
                </Box>
              );
            })}
        <Box flexBasis="60px" className={styles.headerCell} />
      </Box>
      <Box>
        {[...draft.billItems]
          .sort((a, b) => (a.sNo > b.sNo ? 1 : -1))
          .map((row, i) => (
            <BillItem
              // Ref for last item only
              itemRef={i === draft.billItems.length - 1 ? lastItemRef : null}
              focusAddItemButton={focusAddItemButton}
              key={row?.sNo}
              item={row}
              draft={draft}
              products={products}
              isCreditNote={isCreditNote}
              updateDraft={updateDraft}
              onRowRemove={onRowRemove}
              batchOptions={batchOptions}
              getBatchOptions={getBatchOptions}
              selectedStockProducts={selectedStockProducts}
              setSelectedStockProducts={setSelectedStockProducts}
            />
          ))}
      </Box>
    </Box>
  );
};

export default BillItems;
