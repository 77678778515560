import { createSelector } from 'reselect';
import startCase from 'lodash/startCase';

//selectors
export const spFullNameSelector = (
  serviceProvider = { title: '', firstName: '', lastName: '' },
) => {
  const { title, firstName, lastName } = serviceProvider;
  return `${startCase(title)} ${startCase(firstName)} ${startCase(lastName)}`;
};

export const spFromIdSelector = (SPs, id) => {
  if (!SPs || !id) return null;
  return SPs.find((item) => Number(id) === Number(item.id));
};

export const serviceProvidersSelector = (state) => state.resources.resourceCentreServiceProviders;

export const activeServiceProviders = createSelector(serviceProvidersSelector, (SPs) =>
  SPs.filter(({ active }) => active),
);

export const serviceProvidersSortedSelector = createSelector(activeServiceProviders, (SPs) => {
  return SPs.sort((a, b) =>
    `${a.firstName || ''}${a.lastName || ''}`.toLowerCase() >
    `${b.firstName || ''}${b.lastName || ''}`.toLowerCase()
      ? 1
      : -1,
  );
});
