export const supportPhoneNumber = "9851200730";

export const salesPhoneNumber = "9851154810, 015320826";

export const supportEmail = "info@okhati.com.np";

export const address = "Tripura Marg, Teku, Kathmandu";

export const instructionVideos = [
  { title: "Okhati Introduction", url: "https://www.youtube.com/embed/jQzqz1gRa2Y" },
  { title: "Okhati Demo", url: "https://www.youtube.com/embed/Sx1RDnhIA4k" },
  { title: "How To Purchase Sms", url: "https://www.youtube.com/embed/_Bc9AkFxc4w " },
  {
    title: "How To Create Sales Return/Credit Note",
    url: "https://www.youtube.com/embed/ByAN87B2gKE "
  },
  {
    title: "How To Record Payment/Due Clearance",
    url: "https://www.youtube.com/embed/LugeEftudsk "
  },
  { title: "How To Edit Bill Print Margin", url: "https://www.youtube.com/embed/LugeEftudsk " },
  {
    title: "How To Upload Service Provider Signature",
    url: "https://www.youtube.com/embed/LugeEftudsk "
  },
  {
    title: "How To Enable Sms For Booking And Other Services",
    url: "https://www.youtube.com/embed/PC9QnkZC2Yk "
  }
];
