/* eslint-disable no-param-reassign */
import React from "react";
import { Box, Typography } from "@mui/material";
import { abs, round } from "mathjs";
import {
  FiltersInterface,
  ProfitLossInterface,
  TrialBalanceInterface
} from "../../../interfaces/AccountReportsInterface";
import CsvDownload from "./CsvDownload";
import { formatDataForProfitAndLoss, formatDataToDownload } from "./helper";
import classNames from "../../../helpers/classNames";

const docColumns = ["GL Code", "GL Desc", "Closing Debit", "Closing Credit"];
const extraColumns = ["Opening Dr", "Opening Cr", "During The Period Dr", "During The Period Cr"];
const profitAndLossColumns = ["Code", "Accounting Group", "Current Year"];
const getValue = (value, isString = false) => (isString ? value || "" : round(value || 0, 2));

export enum Reports {
  TRIAL_BALANCE = "trialBalance",
  PROFIT_LOSS = "profitLoss",
  BALANCE_SHEET = "balanceSheet"
}

const getData = (data, report) => {
  if ([Reports.PROFIT_LOSS, Reports.BALANCE_SHEET].includes(report)) {
    return formatDataForProfitAndLoss(data);
  }
  return formatDataToDownload(data);
};

const getColumns = (columns, filter, report) => {
  if (
    [Reports.BALANCE_SHEET, Reports.PROFIT_LOSS].includes(report) &&
    filter?.showPreviousYearPeriod
  ) {
    return [...columns, "Previous Year"];
  }
  return columns;
};

const getDocsColumnsForTrialBalance = (columns, showExtraFields) => {
  if (!showExtraFields) return columns;
  /*
    Initially extraColumns are now shown in UI
    if showExtraFields btn is clicked then added extraColumns before index 2
  */
  columns.splice(2, 0, ...extraColumns);
  return columns;
};

export default function Headers({
  headers,
  data,
  report,
  filters
}: {
  headers: { key: string; label: string; className?: string }[];
  data: TrialBalanceInterface[] | ProfitLossInterface[];
  report?: string;
  filters?: FiltersInterface;
}): JSX.Element {
  const profitAndLossDocProcessor = ({
    code,
    accountingGroupName,
    balance,
    previousYearBalance
  }) => {
    const extractedData = [
      getValue(code, "string"),
      getValue(accountingGroupName, "string"),
      getValue(balance)
    ];
    return [
      ...extractedData,
      ...(filters?.showPreviousYearPeriod ? [getValue(previousYearBalance)] : [])
    ];
  };
  const docRowProcessor = ({
    code,
    ledgerName,
    debit,
    credit,
    debitOpeningBalance,
    creditOpeningBalance,
    duringThePeriodDr,
    duringThePeriodCr
  }) => [
    getValue(code, "string"),
    getValue(ledgerName, "string"),
    ...(filters?.showExtraColumns
      ? [
          getValue(debitOpeningBalance),
          getValue(creditOpeningBalance),
          getValue(duringThePeriodDr),
          getValue(duringThePeriodCr)
        ]
      : []),
    getValue(abs(debit)),
    getValue(abs(credit))
  ];

  return (
    <Box className="accounts-list-headers">
      {headers.map((header) => (
        <Box
          fontWeight={500}
          className={classNames(header?.className, "headercell", {
            additionalColumns: filters?.showExtraColumns
          })}
          textAlign="unset"
          key={header.key}
          fontSize="0.8rem"
          whiteSpace="pre"
        >
          <Typography>{header.label}</Typography>
        </Box>
      ))}
      {Object.values(Reports).includes(report as Reports) && (
        <CsvDownload
          data={getData(data, report)}
          fileName={report}
          columns={
            report === Reports.TRIAL_BALANCE
              ? getDocsColumnsForTrialBalance(docColumns, filters?.showExtraColumns)
              : getColumns(profitAndLossColumns, filters, report)
          }
          docRowProcessor={
            report === Reports.TRIAL_BALANCE ? docRowProcessor : profitAndLossDocProcessor
          }
        />
      )}
    </Box>
  );
}

Headers.defaultProps = {
  report: "",
  filters: {}
};
