import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import { ReducerBuilder } from "./ReducerBuilder";
import * as billActions from "../actions/bill";
import {
  getTemplateBillItem,
  calculateCreditNoteBillItems,
  calculateCreditNoteSummary,
  calculateBillItems,
  calculateSummary,
  distributePayment,
  mergeBatchInfo,
  updateShowFields
} from "../actions/helpers/billHelper";
import { BillDocumentType, BillType, DocumentTypes } from "../interfaces/BillInterfaces";
import { removeBill } from "./billsFunction";

export interface StateBillsInterface {
  collection: BillType[];
  draft: BillDocumentType;
  isScanning: boolean;
}

const INITIAL_STATE: StateBillsInterface = {
  collection: [],
  draft: null,
  isScanning: false
};

function getBill(state, payload) {
  const payloadWithBatchInfo = produce(payload, (draft) => {
    draft.document.billItems = mergeBatchInfo(draft.document.billItems);
  });
  const newState = cloneDeep(state);
  const idx = newState.collection.findIndex((el) => el.id === payloadWithBatchInfo.id);
  if (idx !== -1) newState.collection[idx] = payloadWithBatchInfo;
  else newState.collection.push(payloadWithBatchInfo);
  return newState;
}

function updateBillsInBatch(state, payload) {
  if (!payload) return state;
  const newState = cloneDeep(state);
  const ids = payload.map(({ id }) => id);
  newState.collection = newState.collection.map((el) =>
    ids.includes(el.id) ? payload.find(({ id }) => id === el.id) : el
  );

  return newState;
}

// ordering the bill so that the credited bill is after its related bill
export const getOrderedBill = (billList: BillDocumentType[]): BillDocumentType[] => {
  const sortedBills = [...billList].sort((a, b) => b.billNumber - a.billNumber);
  return sortedBills.reduce((acc, bill) => {
    if (bill.type === DocumentTypes.INVOICE) {
      acc.push(bill);
      if (bill.referenceTo) {
        acc.push(bill.related);
      }
    } else {
      // if the bill type is creditnote then find its related bill if it exists,
      // don't do anything coz above logic already added it.
      const relatedBill = sortedBills.find((b) => b.id === bill.referenceTo);
      if (!relatedBill) {
        acc.push(bill);
      }
    }
    return acc;
  }, []);
};

function addBills(state, payload) {
  // use this for pagination
  return { ...state, collection: getOrderedBill(payload.results), total: payload.total };
  // return { ...state, collection: payload };
}

function initDraft(state, payload) {
  return { ...state, draft: payload };
}

function clearDraft(state) {
  return { ...state, draft: null };
}

const updatesNo = (draft) => ({
  ...draft,
  billItems: draft.billItems.map((billItem, i) => ({ ...billItem, sNo: i + 1 }))
});

function addNewRow(state) {
  let newDraft = {
    ...state.draft,
    billItems: [...state.draft.billItems, getTemplateBillItem()]
  };
  newDraft = updatesNo(newDraft);
  return {
    ...state,
    draft: newDraft
  };
}

function removeRow(state, sNo) {
  let newDraft = {
    ...state.draft,
    billItems: state.draft.billItems.filter((item) => item.sNo !== sNo)
  };
  newDraft = updatesNo(newDraft);
  newDraft = calculateSummary(newDraft);
  return {
    ...state,
    draft: newDraft
  };
}

function calculateDraftValuesForCreditNote(draft, originalBill) {
  let draftUpdated = calculateCreditNoteBillItems(draft, originalBill);
  draftUpdated = calculateCreditNoteSummary(draft, originalBill);
  return draftUpdated;
}

function calculateDraftValues(draft) {
  let draftUpdated = updateShowFields(draft);
  draftUpdated = calculateBillItems(draftUpdated);
  draftUpdated = calculateSummary(draftUpdated);
  draftUpdated = distributePayment(draftUpdated);
  return draftUpdated;
}

function updateDraft(state, payload) {
  let calculatedDraft;
  const clonedPayload = cloneDeep(payload);
  if (payload.type === DocumentTypes.CREDITNOTE) {
    const sourceBill = state.collection.find(({ id }) => id === payload.referenceTo);
    calculatedDraft = calculateDraftValuesForCreditNote(payload, sourceBill);
  } else {
    calculatedDraft = calculateDraftValues(clonedPayload);
  }
  return { ...state, draft: calculatedDraft };
}

function updateDraftSettings(state, payload) {
  const newDraft = cloneDeep(state.draft);
  newDraft.settings = payload;
  const calculatedDraft = calculateDraftValues(newDraft);
  return { ...state, draft: calculatedDraft };
}

function updateBillOnClientEdit(state, payload) {
  if (!payload?.id) return state;
  const newCollection = state.collection.map((bill) => {
    if (payload.id === bill.client.id) {
      return produce(bill, (draft) => {
        draft.client = payload;
      });
    }
    return bill;
  });
  return { ...state, collection: newCollection };
}

function updateBillPrintCount(state, payload) {
  if (!payload?.id) return state;
  const newState = cloneDeep(state);
  const idx = newState.collection.findIndex((el) => el.id === payload.id);
  if (idx !== -1) newState.collection[idx].printCount = payload.printCount;
  return newState;
}

const updateOnReceiptCancel = (state, payload) => {
  if (!payload?.id) return state;
  return produce(state, (draft) => {
    const idx = draft.collection.findIndex((el) => el.id === payload.id);
    if (idx !== -1) {
      draft.collection[idx].paymentInfo = payload.paymentInfo;
      draft.collection[idx].receipts = payload.receipts;
    }
  });
};

const billCancel = (state, payload) => {
  if (!payload?.id) return state;
  return produce(state, (draft) => {
    const idx = draft.collection.findIndex((el) => el.id === payload.id);
    if (idx !== -1) {
      draft.collection[idx].paymentInfo = payload.paymentInfo;
      draft.collection[idx].receipts = payload.receipts;
      draft.collection[idx].status = payload.status;
    }
  });
};

const scannerDetected = (state, payload) => ({ ...state, isScanning: payload });

const reducer = ReducerBuilder.create(INITIAL_STATE)
  .mapAction(billActions.Type.INIT_DRAFT, initDraft)
  .mapAction(billActions.Type.CLEAR_DRAFT, clearDraft)
  .mapAction(billActions.Type.ADD_NEW_ROW, addNewRow)
  .mapAction(billActions.Type.REMOVE_ROW, removeRow)
  .mapAction(billActions.Type.UPDATE_DRAFT, updateDraft)
  .mapAction(billActions.Type.UPDATE_DRAFT_SETTINGS, updateDraftSettings)
  .mapAction(billActions.Type.FETCH_BILLS, addBills)
  .mapAction(billActions.Type.GET_BILL, getBill)
  .mapAction(billActions.Type.UPDATE_BILL, getBill)
  .mapAction(billActions.Type.UPDATE_BILLS, updateBillsInBatch)
  .mapAction(billActions.Type.REMOVE_BILL, removeBill)
  .mapAction(billActions.Type.UPDATE_BILL_ON_CLIENT_EDIT, updateBillOnClientEdit)
  .mapAction(billActions.Type.UPDATE_PRINT_COUNT, updateBillPrintCount)
  .mapAction(billActions.Type.RECEIPT_CANCEL, updateOnReceiptCancel)
  .mapAction(billActions.Type.BILL_CANCELLATION, billCancel)
  .mapAction(billActions.Type.BARCODE_SCANNER_DETECTED, scannerDetected)
  .build();
export default reducer;
