import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import Form from "../../components/Form";
import { fields } from "../../models/Login";
import * as AuthenicationActions from "../../actions/authentication";
import { tl } from "../../components/translate";
import style from "./style.module.css";
import logo from "../../../assets/images/okhati_full_green.png";
import { RootState } from "../../store";
import { sessionOrLocalStorageUserData } from "../../auth/authentication";
import { isRequired, isValidEmail } from "../../helpers/validators";
import TwoFactorAuthQrScanModal from "./TwoFactorAuthQrScanModal";
import { resetUserPassword } from "../../api/user";
import { notificationAdd } from "../../actions/notification";
import ForgotPasswordModal from "./ForgotPasswordModal";

interface loginProps {
  login: (creds) => void;
}

const Login: React.FC<loginProps> = ({ login }) => {
  const { required2FAToken, imageUrl } = useSelector((s: RootState) => s.twoFactorAuthInfo);
  const [state, setState] = React.useState<{
    credentials: { username: string; password: string; authToken: string } | null;
    errorsCount: number;
    showErrors: boolean;
  }>({
    // eslint-disable-next-line no-bitwise
    credentials: { username: "", password: "", authToken: "" } || null,
    errorsCount: 0,
    showErrors: false
  });
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = React.useState(false);
  const [emailForPwReset, setEmailForPwReset] = React.useState("");
  const onChange = (credentials, errorsCount) => {
    setState({ ...state, credentials, errorsCount });
  };
  const dispatch = useDispatch();
  const onResetPassword = async (data = state.credentials) => {
    if (!data?.email || !emailForPwReset) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Email or Username is missing. Please check and try again!",
          variant: "error",
          autoTimeout: true
        })
      );
      return;
    }

    if (!isValidEmail(emailForPwReset)) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Email is invalid!",
          variant: "error",
          autoTimeout: true
        })
      );
      return;
    }
    try {
      await resetUserPassword({ username: data.email, email: emailForPwReset });
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Password reset successful. Please check your email.",
          variant: "success",
          autoTimeout: true
        })
      );
      setEmailForPwReset("");
      setOpenForgotPasswordModal(false);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: err.data?.message || "Something went wrong!",
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };
  const isFromClinicsApp = window.isRnWebView || false;
  const { expoPushToken } = window;
  const isUserAuthenticated = useSelector((s: RootState) => s.authenticated);
  const isTokenRequired = required2FAToken && !imageUrl;
  const user = sessionOrLocalStorageUserData();
  const logIn = (data = state.credentials) => {
    if (state.errorsCount) {
      setState({ ...state, showErrors: true });
    } else {
      login({
        ...data,
        ...(isFromClinicsApp ? { isFromClinicsApp, expoPushToken } : {})
      });
      if (!isTokenRequired) {
        setState({ ...state, credentials: null });
      }
    }
  };
  if (isUserAuthenticated && user) {
    return <Redirect to="/dashboard" />;
  }

  const updatedFields = [
    ...fields,
    ...(isTokenRequired
      ? [
          {
            key: "token",
            label: "token",
            value: "",
            inputType: "text",
            editable: true,
            creatable: true,
            required: true,
            validators: [isRequired({ msg: "Token is Required" })]
          }
        ]
      : [])
  ];

  return (
    <div className={style.root}>
      <div className={style.loginPanelBody}>
        <Form
          title={
            <div className={style.loginHeader}>
              <div className="login-logo-img">
                <img className={style.logo} src={logo} alt="Okhati Logo" />
              </div>
              <Typography variant="caption" gutterBottom className={style.subText}>
                आफ्नो Okhati खाता प्रयोग गरी भित्र पस्नुहोस
              </Typography>
            </div>
          }
          classNames={{
            root: style.loginFormRoot,
            paper: style.loginPanel
          }}
          onChange={(data, errors, errorsCount) => onChange(data, errorsCount)}
          data={{}}
          fields={updatedFields}
          hideSaveButton
          showErrors={state.showErrors}
          onSave={() => logIn()}
          footer={
            <Box>
              <Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!state.credentials?.email) {
                      dispatch(
                        notificationAdd({
                          id: new Date().getTime(),
                          message: "Please enter username to continue!",
                          variant: "error",
                          autoTimeout: true
                        })
                      );
                    } else {
                      setOpenForgotPasswordModal(true);
                    }
                  }}
                >
                  Forgot Password?
                </Typography>
              </Box>
              <Button
                variant="contained"
                aria-label="Login"
                type="submit"
                color="primary"
                className={style.loginButton}
                onClick={() => logIn()}
                disabled={isTokenRequired && !state.credentials?.token}
              >
                {isTokenRequired ? tl("main.verify") : tl("main.login")}
              </Button>
            </Box>
          }
        />
        <ForgotPasswordModal
          isOpen={openForgotPasswordModal}
          onClose={() => {
            setOpenForgotPasswordModal(false);
            setEmailForPwReset("");
          }}
          email={emailForPwReset}
          setEmail={setEmailForPwReset}
          onResetPassword={onResetPassword}
        />
        <TwoFactorAuthQrScanModal onSave={(token) => login({ ...state.credentials, token })} />
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  login: (credentials) => dispatch(AuthenicationActions.login(credentials))
}))(Login);
