import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageIcon from "@mui/icons-material/Message";
import HistoryIcon from "@mui/icons-material/History";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Tooltip
} from "@mui/material";
import Grow from "@mui/material/Grow";
import PrintIcon from "@mui/icons-material/Print";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { t, tl } from "../../../components/translate";
import ClientShow from "../ClientShow";
import * as clientActions from "../../../actions/client";
import { deleteClient, saveClient } from "../../../actions/client";
import * as VitalActions from "../../../actions/vitals";
import { StyledTab, StyledTabs } from "../../../components/StyledTabs";
import ClientVisits from "./ClientVisits";
import ClientBills from "./ClientBills";
import ClientLabs from "./ClientLabs";
import Panel from "../../../components/Panel";
import Authorize from "../../../auth/authorization";
import { navigateRemoveModal, showDialog } from "../../../actions/navigation";
import { clientFullNameSelector } from "../../../reducers/client";
import ClientAssessments from "./ClientAssessments";
import useMobileScreen from "../../../hooks/useMobileScreen";
import styles from "../style.module.css";
import VitalsPopUp from "../../Assessment/Vitals/VitalsPopUp";
import ClientVitals from "./ClientVitals";
import localStorage from "../../../helpers/localStorage";
import PrintClientInfoSticker, { getSpFullName } from "./PrintClientInfoSticker";
import usePrintStyles from "../../../hooks/usePrintStyles";
import RecordPayment from "../../Billing/RecordPayment/RecordRefundPayment";
import MessageCreate, {
  MessageType,
  MESSAGE_TYPE
} from "../../Campaign/InstantMessage/MessageCreate";
import { IUser } from "../../../interfaces/User";
import ClientLedger from "./ClientLedger";
import { AppDispatch, IThunkDispatch, RootState } from "../../../store";
import { Client, ClientVisit } from "../../../interfaces/ClientInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import Can from "../../Policy/Can";
import EstimatesCreateEdit from "../Estimates/EstimatesCreateEdit";
import EstimateInfo from "../Estimates/EstimateInfo";
import EstimateList from "../Estimates/EstimateList";
import { fetchEstimatesByClient, fetchEstimateTemplate } from "../../../slices/estiamtesSlice";
import { notificationAdd } from "../../../actions/notification";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import ClientActivity from "./ClientActivity";
import ConsentCreateEditForm, { ConsentData } from "../Consent/ConsentCreateEditForm";
import ConsentFormShow from "../Consent/ConsentFormShow";
import ConsentFormList from "../Consent/ConsentFormList";
import { Estimate } from "../Estimates/interfaces";
import SurveyCreateEditForm, { SurveyData } from "../Survey/SurveyCreateEditForm";
import SurveyFormShow from "../Survey/SurveyFormShow";
import SurveyFormList from "../Survey/SurveyFormList";
import { getSurveyFormTemplate } from "../../../api/surveyForm";
import { defaultTemplateDocument } from "../../ResourceCentre/OtherSettings/SurveyFormSettings";

interface SwitchTabRendererParams {
  activeTab: number;
  id: number;
  shouldFetchBills: boolean;
  onConsentFormRowClick: (data: ConsentData) => void;
  onEstimateRowClick: (data: Estimate) => void;
  onSurveyRowClick: (data: SurveyData) => void;
}

const switchTabRenderer = ({
  activeTab,
  id,
  shouldFetchBills,
  onConsentFormRowClick,
  onEstimateRowClick,
  onSurveyRowClick
}: SwitchTabRendererParams) => {
  switch (activeTab) {
    case 0:
      return <ClientVisits clientId={id} />;
    case 1:
      return <ClientBills clientId={id} shouldFetchBills={shouldFetchBills} />;
    case 2:
      return <ClientAssessments clientId={id} />;
    case 3:
      return <ClientVitals clientId={id} />;
    case 4:
      return <ClientLabs clientId={id} />;
    case 5:
      return <ClientLedger clientId={id} />;
    case 6:
      return <EstimateList clientId={id} onRowClick={onEstimateRowClick} />;
    case 7:
      return <ConsentFormList clientId={id} onRowClick={onConsentFormRowClick} />;
    case 8:
      return <SurveyFormList clientId={id} onRowClick={onSurveyRowClick} />;
    default:
      throw new Error(`Unknown activeTab: ${activeTab}`);
  }
};

type Props = MapDispatchToProps & MapStateToProps & OwnProps;

const EditPatient: React.FC<Props> = ({
  navigateTo,
  getClientVisits,
  clientData,
  user,
  onDeleteClient,
  handleViewClose,
  postVitals,
  getVitals,
  loadFullClient,
  restrictTestCreation,
  wrapperStyle,
  setEditMode,
  stayOnCurrentPage,
  id,
  permissionGroup
}) => {
  React.useEffect(() => {
    if (!clientData) {
      loadFullClient(id);
    }
    getClientVisits(id);
  }, [id]);

  const dispatch = useDispatch<AppDispatch>();
  const [messageType, setMessageType] = React.useState<MessageType>(MESSAGE_TYPE.SMS);
  const [activeTab, setActiveTab] = React.useState(0);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [showMessagePanel, setShowMessagePanel] = React.useState(false);
  const isMobileScreen = useMobileScreen();
  const [isVitalPopupOpen, setIsVitalPopupOpen] = React.useState(false);
  const [recordPayment, setRecordPayment] = React.useState({
    open: false,
    refundMode: false
  });
  const [estimatePanel, setEstimatePanel] = useState<{ show: boolean; data: Estimate | null }>({
    show: false,
    data: null
  });
  const [surveyTemplate, setSurveyTemplate] = React.useState<Partial<SurveyData>>({
    document: defaultTemplateDocument
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [showActivityPanel, setShowActivityPanel] = useState(false);
  const [consentForm, setConsentForm] = useState<{
    data: ConsentData | null;
    isOpen: boolean;
    isEditMode: boolean;
  }>({
    isOpen: false,
    isEditMode: false,
    data: null
  });
  const [surveyForm, setSurveyForm] = useState<{
    data: SurveyData | null;
    isOpen: boolean;
    isEditMode: boolean;
  }>({
    isOpen: false,
    isEditMode: false,
    data: null
  });

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEstimateTemplate()).unwrap();
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: errorFetchMessage(MODULE.ESTIMATES),
            variant: "error",
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getSurveyFormTemplate();
        if (response) {
          setSurveyTemplate(response);
        }
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: errorFetchMessage(MODULE.SURVERY_FORM_TEMPLATE),
            variant: "error",
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const navigateToAssessment = (cid: number) => {
    navigateTo(`/assessment/new?clientId=${cid}`);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const serviceProviders: ServiceProvider[] = useSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  let editButton = null;
  let deleteButton = null;
  let printButton = null;
  let messageButton = null;
  let genericButton = null;

  if (!clientData) return null;

  if (Authorize(user).can("admin", "Patient", { id: clientData.id })) {
    if (!clientData.isWalkInCustomer) {
      genericButton = (
        <Tooltip title="Client Activity" aria-label="edit">
          <HistoryIcon
            data-testmation="clientActivityButton"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowActivityPanel(true);
            }}
          />
        </Tooltip>
      );

      editButton = (
        <Tooltip title="Edit" aria-label="edit">
          <EditIcon
            data-testmation="editButton"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (!stayOnCurrentPage) {
                navigateTo(`/clients/${clientData.id}/edit`);
              }
              if (clientData.active && setEditMode) {
                setEditMode(true);
              }
            }}
          />
        </Tooltip>
      );

      deleteButton = (
        <Tooltip title="Delete" aria-label="delete">
          <DeleteIcon
            data-testmation="deleteButton"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (clientData.active) {
                onDeleteClient(clientData.id, clientFullNameSelector(clientData));
              }
            }}
          />
        </Tooltip>
      );

      messageButton = (
        <Tooltip title="Message" aria-label="message">
          <MessageIcon
            data-testmation="editButton"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowMessagePanel(true);
            }}
          />
        </Tooltip>
      );
    }
    printButton = (
      <Box
        onMouseEnter={() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: "size: 4in 2in"
          }).applyPrintStyles();
        }}
        onMouseLeave={() => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          usePrintStyles({
            pageStyle: "size: A4; margin: 10mm"
          }).applyPrintStyles();
        }}
      >
        <PrintClientInfoSticker clientData={clientData} />
      </Box>
    );
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  return (
    <Panel
      wrapperStyle={wrapperStyle}
      onClose={handleViewClose}
      editButton={editButton}
      deleteButton={deleteButton}
      genericButton={genericButton}
      printButton={
        <>
          <IconButton
            disableRipple
            size="small"
            sx={{
              mt: "-5px",
              color: "black",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
          >
            <Tooltip title="Print" aria-label="print">
              <PrintIcon />
            </Tooltip>
          </IconButton>
          <Menu
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
          >
            <Box width="300px" p="10px">
              <Autocomplete
                value={serviceProviders.find((sp) => sp.id === clientData.lastHandledSpId) || null}
                onChange={(e, sp: ServiceProvider) => {
                  dispatch(
                    saveClient(
                      {
                        id: clientData.id,
                        lastHandledSpId: sp?.id || null,
                        firstName: clientData.firstName,
                        lastName: clientData.lastName
                      } as Client,
                      true
                    )
                  );
                }}
                selectOnFocus
                getOptionLabel={(option) => getSpFullName(option)}
                options={serviceProviders.filter((sp) => sp.active)}
                renderInput={(params) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField variant="outlined" {...params} label="Select Service Providers" />
                )}
              />
              <Box mt={2} display="flex" justifyContent="flex-end">
                {printButton}
              </Box>
            </Box>
          </Menu>
        </>
      }
      messageButton={messageButton}
    >
      <Box px={`${isMobileScreen ? "16px" : "32px"}`} width="100%" className={styles.clientForm}>
        <ClientShow
          client={clientData}
          showActionbar={false}
          onMessageBtnClick={(mType: MessageType) => {
            setMessageType(mType);
            setShowMessagePanel(true);
          }}
        />
        <Box textAlign="left" marginTop="64px">
          {permissionGroup !== "resourceCentreCampaignOnlyEmployee" && (
            <Can policyAccessKey="allow" superAdminAgentPassReverse>
              <ButtonGroup
                size="small"
                variant="outlined"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  data-testmation="createAssessment"
                  variant="outlined"
                  onClick={() => {
                    localStorage.setItem("patientId", clientData.id?.toString());
                    navigateToAssessment(clientData.id);
                  }}
                  color="primary"
                >
                  {tl("assessment.createAssessment")}
                </Button>
                <Button
                  data-testmation="clientinfoDropdown"
                  size="small"
                  color="primary"
                  aria-owns={openMenu ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
            </Can>
          )}
          <Popper
            style={{ zIndex: 1 }}
            open={openMenu}
            anchorEl={anchorRef.current}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "right bottom" : "right bottom"
                }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem
                        data-testmation="clientBillCreate"
                        onClick={() => {
                          navigateTo(`/calendar/?clientId=${clientData.id}`);
                        }}
                        color="primary"
                      >
                        {tl("booking.createBooking")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="vitals"
                        onClick={() => {
                          setIsVitalPopupOpen(true);
                        }}
                        color="primary"
                        style={{ minWidth: "200px" }}
                      >
                        {tl("assessment.addVitals")}
                      </MenuItem>
                      {!restrictTestCreation && (
                        <>
                          <MenuItem
                            data-testmation="clientLabCreate"
                            onClick={() => {
                              navigateTo(`/lab/labRecords/all/create?cId=${clientData.id}`);
                            }}
                            color="primary"
                            style={{ minWidth: "200px" }}
                          >
                            {tl("client.createLabTest")}
                          </MenuItem>

                          <MenuItem
                            data-testmation="clientMedicalReportCreate"
                            onClick={() => {
                              navigateTo(`/medical/create?cId=${clientData.id}`);
                            }}
                            color="primary"
                            style={{ minWidth: "200px" }}
                          >
                            {tl("client.createMedicalReport")}
                          </MenuItem>
                        </>
                      )}
                      <MenuItem
                        data-testmation="clientBillCreate"
                        onClick={() => {
                          navigateTo(`/billing/new?clientId=${clientData.id}`);
                        }}
                        color="primary"
                        style={{ minWidth: "200px" }}
                      >
                        {tl("client.createBill")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="clientBillCreate"
                        onClick={(e) => {
                          handleClose(e as unknown as MouseEvent);
                          setRecordPayment({ ...recordPayment, open: true });
                        }}
                        color="primary"
                        style={{ minWidth: "200px" }}
                      >
                        {tl("client.recordReceipt")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="clientBillCreate"
                        onClick={(e) => {
                          handleClose(e as unknown as MouseEvent);
                          setRecordPayment({ refundMode: true, open: true });
                        }}
                        color="primary"
                        style={{ minWidth: "200px" }}
                      >
                        {tl("client.createRefund")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="estimateCreate"
                        onClick={(e) => {
                          handleClose(e as unknown as MouseEvent);
                          setEstimatePanel((prevState) => ({ ...prevState, show: true }));
                        }}
                        color="primary"
                      >
                        {tl("estimates.create")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="consentCreate"
                        onClick={(e) => {
                          handleClose(e as unknown as MouseEvent);
                          setConsentForm({ data: null, isOpen: true, isEditMode: false });
                        }}
                      >
                        {tl("consent.create")}
                      </MenuItem>
                      <MenuItem
                        data-testmation="surveyCreate"
                        onClick={(e) => {
                          handleClose(e as unknown as MouseEvent);
                          setSurveyForm({ data: null, isOpen: true, isEditMode: false });
                        }}
                      >
                        {tl("survey.create")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
        <Can policyAccessKey="allow" superAdminAgentPassReverse>
          <Box marginTop="32px">
            <StyledTabs
              value={activeTab}
              onChange={(e, tab) => setActiveTab(tab)}
              aria-label="client info tabs"
            >
              <StyledTab label={t("clients.Visits")} />
              <StyledTab label={t("clients.bills")} />
              <StyledTab label={t("clients.assessments")} />
              <StyledTab data-testmation="showVitals" label={t("clients.vitals")} />
              <StyledTab label="Labs" />
              <StyledTab label="Ledger" />
              <StyledTab label="Estimates" data-testmation="estimatesTab" />
              <StyledTab label={t("consent.form")} data-testmation="consentFormTab" />
              <StyledTab label={t("survey.form")} data-testmation="surveyFormTab" />
            </StyledTabs>
            {switchTabRenderer({
              activeTab,
              id: clientData.id,
              shouldFetchBills: recordPayment.open,
              onEstimateRowClick: (data: Estimate) =>
                setEstimatePanel((prevState) => ({ ...prevState, data })),
              onConsentFormRowClick: (data: ConsentData) =>
                setConsentForm((prev) => ({ ...prev, data })),
              onSurveyRowClick: (data: SurveyData) => setSurveyForm((prev) => ({ ...prev, data }))
            })}
          </Box>
        </Can>
      </Box>
      <VitalsPopUp
        isOpen={isVitalPopupOpen}
        onClose={() => {
          setIsVitalPopupOpen(false);
          if (setEditMode) {
            setEditMode(false);
          }
        }}
        onSave={async (vitals) => {
          if (setEditMode) {
            setEditMode(false);
          }
          await postVitals(vitals, clientData.id);
          await getVitals(clientData.id);
        }}
      />
      {recordPayment.open ? (
        <Box>
          <RecordPayment
            clientId={clientData.id}
            onClose={() => {
              setRecordPayment({ open: false, refundMode: false });
            }}
            refundMode={recordPayment.refundMode}
          />
        </Box>
      ) : null}

      {showMessagePanel && (
        <MessageCreate
          onCancel={() => {
            setShowMessagePanel(false);
          }}
          client={clientData}
          messageType={messageType}
        />
      )}
      {estimatePanel.show && (
        <EstimatesCreateEdit
          editMode={isEditMode}
          selectedEstimate={estimatePanel.data}
          onClose={() => {
            setEstimatePanel({ data: null, show: false });
            setIsEditMode(false);
          }}
          selectedClient={clientData}
          afterSave={(value) => {
            setEstimatePanel({ data: value, show: false });
            setIsEditMode(false);
            dispatch(fetchEstimatesByClient(clientData.id));
          }}
        />
      )}
      {estimatePanel.data && (
        <EstimateInfo
          data={estimatePanel.data}
          onClose={() => setEstimatePanel({ data: null, show: false })}
          setEditMode={() => {
            setEstimatePanel((prevState) => ({ ...prevState, show: true }));
            setIsEditMode(true);
          }}
        />
      )}
      {showActivityPanel && (
        <ClientActivity onClose={() => setShowActivityPanel(false)} client={clientData} />
      )}
      {consentForm.isOpen && (
        <ConsentCreateEditForm
          isEditMode={consentForm.isEditMode}
          data={consentForm.data}
          onClose={() => setConsentForm({ isOpen: false, isEditMode: false, data: null })}
          client={clientData}
          afterSave={(data) => {
            setConsentForm({ isOpen: false, isEditMode: false, data });
          }}
        />
      )}
      {consentForm.data && (
        <ConsentFormShow
          data={consentForm.data}
          onClose={() => setConsentForm({ isOpen: false, isEditMode: false, data: null })}
          onEdit={() => {
            setConsentForm((prev) => ({ ...prev, isOpen: true, isEditMode: true }));
          }}
        />
      )}
      {surveyForm.isOpen && (
        <SurveyCreateEditForm
          template={surveyTemplate}
          isEditMode={surveyForm.isEditMode}
          data={surveyForm.data}
          onClose={() => setSurveyForm({ isOpen: false, isEditMode: false, data: null })}
          client={clientData}
          afterSave={(data) => {
            setSurveyForm({ isOpen: false, isEditMode: false, data });
          }}
        />
      )}
      {surveyForm.data && (
        <SurveyFormShow
          data={surveyForm.data}
          onClose={() => setSurveyForm({ isOpen: false, isEditMode: false, data: null })}
          onEdit={() => {
            setSurveyForm((prev) => ({ ...prev, isOpen: true, isEditMode: true }));
          }}
        />
      )}
    </Panel>
  );
};

EditPatient.defaultProps = {
  clientData: undefined
};
interface MapStateToProps {
  permissionGroup: string;
  clientVisits: Array<ClientVisit>;
  clientData: Partial<Client>;
  user: IUser & { role: string };
  restrictTestCreation: boolean;
}

interface MapDispatchToProps {
  getClientVisits: (id: number) => void;
  navigateTo: (string) => void;
  loadFullClient: (number) => void;
  onDeleteClient: (id: number, clientName: string) => void;
  postVitals: (data: Record<string, unknown>, clientId: number) => void;
  getVitals: (number) => void;
}

interface OwnProps {
  clientData?: Client;
  handleViewClose: () => void;
  wrapperStyle?: Record<string, unknown>;
  setEditMode?: (v: boolean) => void;
  stayOnCurrentPage?: boolean;
  id: number;
}

EditPatient.defaultProps = {
  wrapperStyle: undefined,
  setEditMode: undefined,
  stayOnCurrentPage: false
};

export default connect<MapStateToProps, MapDispatchToProps, OwnProps>(
  ({ userContext, clients, resources }: RootState, { id }) => {
    const resourceCentre =
      resources.resourceCentres.find((rc) => rc.id === userContext.resourceCentreId) ||
      userContext.resourceCentre;

    const restrictTestCreation = resourceCentre?.labSettings?.enableTestCreationFromBillOnly;
    return {
      permissionGroup: userContext.userCreds?.userGroups[0],

      clientVisits: clients.visits.filter(({ clientId }) => clientId === id),
      clientData: clients.collection?.find((c) => c.id === id),
      user: { ...userContext.user, role: userContext.mode },
      restrictTestCreation
    };
  },
  (dispatch: IThunkDispatch) => ({
    getClientVisits: (id: number) => dispatch(clientActions.getClientVisits(id)),
    navigateTo: (url) => dispatch(push(url)),
    loadFullClient: (id) => dispatch(clientActions.getClientById(id)),
    onDeleteClient: (id, clientName) => {
      dispatch(
        showDialog({
          title: "Delete customer",
          description: `Are you sure you want to delete ${clientName}?`,
          next: () => {
            dispatch(deleteClient(id));
            dispatch(navigateRemoveModal("Dialog"));
            dispatch(push("/clients"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    },
    postVitals: (data, clientId) => dispatch(VitalActions.postVitals(data, clientId)),
    getVitals: (clientId) => dispatch(VitalActions.getVitalsById(clientId))
  })
)(EditPatient);
