import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as billActions from "../../../actions/bill";
import * as NotificationActions from "../../../actions/notification";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import Modal from "../../../components/Modal/Modal";
import { tl } from "../../../components/translate";
import useIsAccountSubscribed from "../../../hooks/accounts";
import useTaxations from "../../../hooks/useTaxations";
import { BillDocumentType } from "../../../interfaces/BillInterfaces";
import { RootState } from "../../../store";
import Can from "../../Policy/Can";
import { getTaxationOptions } from "../../Stock/StockCreateEdit/CreateStockProduct";
import { BILL_EDITOR_WARNING_TYPE, BillEditorWarning, LOCALSTORAGE_BILL } from "./BillEditor";
import styles from "./BillEditor.module.css";
import { paymentOptionsEnum } from "./BillSummary";
import BillDraftPrint from "./PrintPreview/DraftBill";

interface ErrorProps {
  [key: string]: string;
}

enum Errors {
  TAXATION = "taxation"
}

const hasTaxationRuleMappedWithLedger = (item, salesTaxationRules) =>
  salesTaxationRules.find((taxRule) => taxRule.id === item.salesTaxationId)?.ledgerId;

const getLedgerMappingError = (
  billData,
  rcMappedPaymentMethods,
  salesTaxationOptions
): ErrorProps[] => {
  const errors = [] as ErrorProps[];
  if (billData.client?.id && !billData.client?.ledgerId) {
    errors.push({ message: "Client is Not Mapped with ledger." });
  }
  if (billData.referrerId && !billData.referrerLedgerId) {
    errors.push({ message: "Referrer is Not Mapped with ledger." });
  }
  if (
    !rcMappedPaymentMethods[billData.paymentInfo.paymentMethod]?.ledgerId &&
    billData.paymentInfo.paymentMethod !== paymentOptionsEnum.balance &&
    billData.paymentInfo.paymentMethod !== paymentOptionsEnum.noPayment
  ) {
    errors.push({ message: "Payment Method is not Mapped with ledger." });
  }
  if (billData.billItems.some((item) => !item.salesLedgerId)) {
    errors.push({ message: "Bill Item is not mapped with ledger" });
  }
  const serviceNotMappedWithTaxation = billData.billItems.some(
    (item) => item.salesTaxationId && !hasTaxationRuleMappedWithLedger(item, salesTaxationOptions)
  );
  if (serviceNotMappedWithTaxation) {
    errors.push({ message: "Sales Taxation is not mapped with ledger.", key: Errors.TAXATION });
  }
  return errors;
};

export const isRemarksValid = (draft: BillDocumentType): boolean =>
  !draft.settings.remarksCompulsoryForDiscountedBill ||
  (!!draft.summary.discount || !!draft.summary.discountPct ? !!draft.remarks.trim() : true);

interface BillActionsProps {
  draft: BillDocumentType;
  actions: () => void;
  isCreditNote: boolean;
  setWarningMsg: ({ message, type }: BillEditorWarning) => void;
  currentBillCounter: string;
  qr: {
    setOpenQrPaymentPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    isQrPaymentPanelOpen?: boolean;
    canPayWithQr?: boolean;
  };
}

const BillActions = ({
  draft,
  actions,
  isCreditNote,
  setWarningMsg,
  currentBillCounter,
  qr: { setOpenQrPaymentPanel, isQrPaymentPanelOpen, canPayWithQr }
}: BillActionsProps) => {
  let savable = draft.client?.id && draft.billNumber;
  const isTenderAmountValid =
    !draft.paymentInfo.tenderAmount ||
    isCreditNote ||
    draft.paymentInfo.tenderAmount === 0 ||
    draft.paymentInfo.tenderAmount >= draft.summary.totalAmount;

  if (isCreditNote) {
    savable = savable && draft.billItems.filter((item) => item.returned).length > 0;
  }
  const rcMappedPaymentMethods = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment || {}
  );

  const requireReferrer = useSelector(
    (state: RootState) =>
      state.resources?.resourceCentres[0]?.settings?.formSettings?.requireReferrer
  );
  const dispatch = useDispatch();
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);

  const rcSubscriptionFeatures =
    useSelector((s: RootState) => s.resources.resourceCentres[0]?.subscriptions?.features) || {};
  const { sms, email } = rcSubscriptionFeatures;
  const rcSettings = useSelector((s: RootState) => s.resources.resourceCentres[0]?.settings) || {};
  const { smsSettings, emailSettings } = rcSettings;
  const isAccountSubscribed = useIsAccountSubscribed();
  const taxations = useTaxations();
  const { salesTaxationOptions } = getTaxationOptions(taxations);
  const isCounterBillingEnabled = rcSettings?.billingSettings?.enableCounterBilling || false;
  const [loading, setLoading] = React.useState(false);

  const hasInvalidProductPackage = () =>
    draft.billItems
      .reduce((allItems, item) => (item.subItems ? [...allItems, ...item.subItems] : allItems), [])
      .some((subItem) => subItem.delivered === false);

  const hasInvalidProductQuantity = () => draft.billItems.some((item) => item.quantity === 0);

  const getBillingActionText = (selectedDraft: BillDocumentType, creditNote: boolean) => {
    if (selectedDraft.id) return tl("billing.updateDraft");
    if (creditNote) return tl("billing.cancel");
    return tl("billing.saveAsDraft");
  };

  const hasNoProductSelected = () =>
    draft?.billItems?.find((item) => !item.productId && !item.description?.trim());

  const initReminderDialogState = {
    show: false,
    message: "",
    openPrintWindow: false
  };
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<ErrorProps[]>([]);
  const [reminderDialogState, setReminderDialogState] = React.useState({
    ...initReminderDialogState
  });
  const [issueDateDialogState, setIssueDateDialogState] = React.useState({
    show: false,
    openPrintWindow: false
  });

  const finaliseInvoice = async ({ openPrintWindow = false }) => {
    if (isCounterBillingEnabled && !currentBillCounter) {
      return setWarningMsg({
        message:
          "Counter wise billing is enabled, but counter is not set for billing on this machine. Please select a counter from settings.",
        type: BILL_EDITOR_WARNING_TYPE.COUNTER
      });
    }
    if (hasInvalidProductQuantity()) {
      return setWarningMsg({ message: "Product quantity cannot be 0.", type: null });
    }
    if (hasInvalidProductPackage()) {
      return setWarningMsg({
        message: "Please remove undelivered package items inorder to finalise the invoice.",
        type: null
      });
    }
    if (!isRemarksValid(draft)) {
      return setWarningMsg({
        message: "Remarks is compulsory for discounted invoice.",
        type: null
      });
    }
    if (isAccountSubscribed) {
      const ledgerMappingErrors = getLedgerMappingError(
        draft,
        rcMappedPaymentMethods,
        salesTaxationOptions
      );
      setErrors(ledgerMappingErrors);
      if (ledgerMappingErrors.length) {
        setOpenModal(true);
        return null;
      }
    }
    setWarningMsg({ message: "", type: null });
    setLoading(true);
    localStorage.removeItem(LOCALSTORAGE_BILL);
    await actions.finaliseAsBill(draft, openPrintWindow);
    setLoading(false);
    return null;
  };

  const onDraftSave = React.useCallback(() => {
    if (isCreditNote) {
      actions.cancelDraft();
    }

    if (hasNoProductSelected()) {
      return setWarningMsg({ message: "Item is not selected for a row in the bill.", type: null });
    }
    if (hasInvalidProductQuantity()) {
      return setWarningMsg({ message: "Product quantity cannot be 0.", type: null });
    }
    localStorage.removeItem(LOCALSTORAGE_BILL);
    return actions.saveAsDraft(draft, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft, isCreditNote, actions]);

  const onFinalizeBill = ({ openPrintWindow = false }) => {
    if (hasNoProductSelected()) {
      return setWarningMsg({ message: "Item is not selected for a row in the bill.", type: null });
    }
    if (draft?.issueDate && !moment().isSame(draft.issueDate, "day")) {
      return setIssueDateDialogState({
        show: true,
        openPrintWindow
      });
    }
    if (draft?.remindOn?.duration > 0) {
      if (!sms.subscribed && !email.subscribed) {
        return setReminderDialogState({
          show: true,
          openPrintWindow,
          message:
            "The resource centre is not subscribed to SMS and email feature, Would you still like to proceed?"
        });
      }

      if (!smsSettings?.billReminder && !emailSettings?.billReminder) {
        return setReminderDialogState({
          show: true,
          openPrintWindow,
          message:
            "The resource centre has not subscribed to SMS and email for bill reminder feature, Would you still like to proceed?"
        });
      }

      if (smsSettings.billReminder && sms?.volumeLeft <= 0) {
        return setReminderDialogState({
          show: true,
          openPrintWindow,
          message:
            "Insufficient SMS balance to execute the transaction, Would you still like to proceed?"
        });
      }
    }
    return finaliseInvoice({ openPrintWindow });
  };

  // Overriding default behaviour of alt + d (focuses on browser's search tab by default)
  React.useEffect(() => {
    const handleShortcut = (event) => {
      if (event.altKey && event.key === "d") {
        event.preventDefault();
        event.stopPropagation();
        if (!loading) {
          onDraftSave();
        }
      }
    };
    window.addEventListener("keydown", handleShortcut);

    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, [onDraftSave, loading]);

  useHotkeys(
    "alt + s, ctrl + p",
    (event, handler) => {
      event.preventDefault();
      if (loading) return;
      if (handler.key === "alt+s") {
        onFinalizeBill({ openPrintWindow: false });
      }
      if (handler.key === "ctrl+p") {
        onFinalizeBill({ openPrintWindow: true });
      }
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  const getQrButtonDisabledMessage = () => {
    if (hasNoProductSelected()) {
      return "Please add items to the bill to pay using QR code.";
    }
    if (!canPayWithQr) {
      return "Currently selected payment method is not available for QR payment. Please, go to settings to enable QR payment.";
    }
    if (isCreditNote) {
      return "Credit note cannot be paid using QR code.";
    }
    if (loading) {
      return "Please wait while the bill is being saved.";
    }
    return "";
  };

  return (
    <Box
      className={styles.billActions}
      zIndex="1"
      position="fixed"
      bottom={isQrPaymentPanelOpen ? "20px" : 0}
      padding={isQrPaymentPanelOpen ? "8px" : "8px 32px"}
      backgroundColor={isQrPaymentPanelOpen ? "white" : "#f1f1f1"}
      boxShadow={isQrPaymentPanelOpen ? "none" : "1px 1px 6px 0px #00000038"}
    >
      {reminderDialogState.show && (
        <OkhatiDialog
          title="Warning"
          description={reminderDialogState.message}
          next={() => {
            finaliseInvoice({ openPrintWindow: reminderDialogState.openPrintWindow });
            setReminderDialogState({ ...initReminderDialogState });
          }}
          cancel={() => {
            setReminderDialogState({ ...initReminderDialogState });
          }}
          readMode={false}
        />
      )}
      {issueDateDialogState.show && (
        <OkhatiDialog
          title="Warning"
          description={
            <Typography>
              Bill <b>issue date</b> is different than today&apos;s date. <br /> Do you wish to
              continue ?
            </Typography>
          }
          next={() => {
            finaliseInvoice({ openPrintWindow: issueDateDialogState.openPrintWindow });
            setIssueDateDialogState({ show: false, openPrintWindow: false });
          }}
          cancel={() => {
            setIssueDateDialogState({ show: false, openPrintWindow: false });
          }}
          readMode={false}
        />
      )}
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        {!isQrPaymentPanelOpen && (
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-start"
            alignItems="center"
            fontSize="16px"
          >
            <Typography>
              <Box component="span" fontWeight={500} marginRight="8px">
                {tl("billing.total")}{" "}
              </Box>
              रू {draft.summary.totalAmount}
            </Typography>
          </Box>
        )}

        {!isQrPaymentPanelOpen && (
          <Tooltip arrow title={getQrButtonDisabledMessage()}>
            <div>
              <Button
                className={styles.billActionBtn}
                // eslint-disable-next-line max-len
                disabled={
                  Boolean(hasNoProductSelected()) || loading || isCreditNote || !canPayWithQr
                }
                onClick={() => {
                  setOpenQrPaymentPanel?.(() => true);
                }}
              >
                {tl("qrPayment.payUsingQrCode")}
              </Button>
            </div>
          </Tooltip>
        )}

        {draft.isDraft && (
          <Can policyAccessKey="bill:createDraft">
            <Tooltip title="Alt + D" arrow sx={{ fontSize: "14px" }}>
              <Button
                data-testmation="billingSaveAsDraft"
                className={styles.billActionBtn}
                disabled={loading || !isTenderAmountValid || (isCreditNote ? false : !savable)}
                onClick={() => {
                  onDraftSave();
                }}
              >
                {getBillingActionText(draft, isCreditNote)}
              </Button>
            </Tooltip>
          </Can>
        )}
        {draft && (
          <BillDraftPrint
            billData={{ ...draft, document: draft }}
            buttonText="Print Preview"
            labelStyle={{
              fontSize: "0.95em",
              textTransform: "none"
            }}
          />
        )}
        {draft.isDraft && (
          <Can policyAccessKey="bill:finalizeBill">
            <Tooltip title="Alt + S" arrow sx={{ fontSize: "14px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={
                  !savable ||
                  !isTenderAmountValid ||
                  loading ||
                  (!isCreditNote && requireReferrer && !draft.referredBy) ||
                  draft.billItems.length <= 0
                }
                onClick={async () => {
                  onFinalizeBill({ openPrintWindow: false });
                }}
                loading={loading}
                data-testmation="billingFinalizeInvoice"
                className={styles.billActionBtn}
              >
                <span>{tl("billing.finaliseInvoice")}</span>
              </LoadingButton>
            </Tooltip>
            <Tooltip title="Ctrl + P" arrow sx={{ fontSize: "14px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={
                  !savable ||
                  !isTenderAmountValid ||
                  loading ||
                  (!isCreditNote && requireReferrer && !draft.referredBy) ||
                  draft.billItems.length <= 0
                }
                onClick={async () => {
                  onFinalizeBill({ openPrintWindow: true });
                }}
                loading={loading}
                data-testmation="billingFinalizeInvoiceAndPrint"
                className={styles.billActionBtn}
              >
                <span>Finalise And Print</span>
              </LoadingButton>
            </Tooltip>
            <Modal
              open={openModal}
              title={
                <Box alignItems="centre" display="flex">
                  <IconButton
                    sx={{
                      color: "#f3b500"
                    }}
                  >
                    <WarningIcon />
                  </IconButton>
                  <Typography marginTop="8px">Warning!</Typography>
                </Box>
              }
              footer={
                <Button onClick={() => setOpenModal(false)} variant="contained">
                  Ok
                </Button>
              }
            >
              {errors.map((item, index) => (
                <Typography key={item.message}>{`${index + 1}. ${item.message}`}</Typography>
              ))}
              {errors.some((error) => error.key === Errors.TAXATION) && (
                <Link
                  component="button"
                  onClick={() =>
                    dispatch(push(`/resourcecentres/${rcId}/accountSettings?activeTab=Taxation`))
                  }
                >
                  Go to Taxation Mapping
                </Link>
              )}
            </Modal>
          </Can>
        )}
      </Box>
    </Box>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    actions: {
      saveAsDraft: async (draft, setLoading) => {
        if (draft.summary.totalAmount >= 1000000) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: tl("billing.totalAmountExceeded"),
              autoTimeout: true
            })
          );
        } else {
          setLoading(true);
          await dispatch(billActions.saveAsDraft(draft));
          setLoading(false);
          dispatch(push(`/billing/bills?filter=draft`));
        }
      },
      cancelDraft: () => {
        dispatch(billActions.clearDraft());
        dispatch(push(`/billing/bills`));
      },
      finaliseAsBill: async (draft, openPrintWindow) => {
        if (draft.summary.totalAmount >= 1000000) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: tl("billing.totalAmountExceeded"),
              autoTimeout: true
            })
          );
        } else {
          await dispatch(billActions.finaliseDraft("a", openPrintWindow));
        }
      }
    }
  })
)(BillActions);
