enum EVENT {
  LOGIN_SUCCESS = "Login success",
  LOGIN_FAILED = "Login failed",
  LOGIN_FB_SUCCESS = "Login FB success",
  LOGIN_FB_FAILED = "Login FB failed",
  SIDEBAR_GO_TO_CALENDAR = "Sidebar go to calendar",
  SIDEBAR_GO_TO_BILLING = "Sidebar go to billing",
  SIDEBAR_GO_TO_CLINICS = "Sidebar go to clinics",
  SIDEBAR_GO_TO_OPD = "Sidebar go to opd",
  SIDEBAR_GO_TO_LAB = "Sidebar go to lab",
  SIDEBAR_GO_TO_DASHBOARD = "SIDEBAR_GO_TO_DASHBOARD",
  SIDEBAR_GO_TO_SERVICES = "Sidebar go to services",
  SIDEBAR_GO_TO_ON_DEMAND_PRODUCT = "Sidebar go to on demand product",
  SIDEBAR_GO_TO_CLIENTS = "Sidebar go to clients",
  SIDEBAR_GO_TO_MASTER_DATA_CONTROL = "Sidebar go to master data control",
  SIDEBAR_GO_TO_STOCK_PRODUCTS = "Sidebar go to stock products",
  SIDEBAR_GO_TO_INTERNAL_INVENTORY = "Sidebar go to internal inventory",
  SIDEBAR_GO_TO_STOCK_TRANSACTIONS = "Sidebar go to stock transactions",
  SIDEBAR_GO_TO_STOCK = "Sidebar go to stock",
  SIDEBAR_GO_TO_SUPPLIER = "Sidebar go to supplier",
  SIDEBAR_GO_TO_CAMPAIGN = "Sidebar go to campaign",
  SIDEBAR_GO_TO_FEED = "Sidebar go to feed",
  SIDEBAR_GO_TO_MEDICAL = "Sidebar go to medical",
  SIDEBAR_GO_TO_REPORTS = "Sidebar go to reports",
  SIDEBAR_GO_TO_BOOKINGS = "Sidebar go to bookings",
  SIDEBAR_GO_TO_ACCOUNT_SETTINGS = "Sidebar go to account settings",
  SIDEBAR_GO_TO_IPD = "Sidebar go to ipd",
  SIDEBAR_GO_TO_HMIS = "Sidebar go to hmis",
  SIDEBAR_GO_TO_CLINIC_DETAILS = "Sidebar go to clinic details",
  SIDEBAR_GO_TO_NOTIFICATIONS = "Sidebar go to notifications",
  SIDEBAR_GO_TO_SUBSCRIPTION = "Sidebar go to subscriptions",
  SIDEBAR_GO_TO_ACCOUNT_VOUCHER_SALES = "Sidebar go to account voucher sales",
  SIDEBAR_GO_TO_DAYBOOK = "Sidebar go to Daybook",
  TOP_USER_MENU_GO_TO_PROFILE = "Top user menu go to profile",
  SETTINGS_GO_TO_PAYMENTS = "Settings go to payments",
  PRINT_BILL_DEFAULT = "Print bill default",
  PRINT_PREVIEW_BILL_DEFAULT = "Print preview bill default",
  PRINT_BILL_BORDERED = "Print bill bordered",
  PRINT_PREVIEW_BILL_BORDERED = "Print preview bill bordered",
  PRINT_BILL_A5 = "Print bill A5",
  PRINT_PREVIEW_BILL_A5 = "Print preview bill A5",
  PRINT_BILL_BANK_PAPER = "Print bill bank paper",
  PRINT_PREVIEW_BILL_BANK_PAPER = "Print preview bill bank paper",
  PRINT_BILL_80MM = "Print bill 80mm",
  PRINT_PREVIEW_BILL_80MM = "Print preview bill 80mm",
  PRINT_BILL_CORPORATE = "Print bill corporate",
  PRINT_PREVIEW_BILL_CORPORATE = "Print preview bill corporate",
  PRINT_LAB_BARCODE = "Print lab barcode",
  PRINT_LAB_DEFAULT = "Print lab default",
  PRINT_LAB_BORDERED = "Print lab bordered",
  PRINT_LAB_REPEATING_FOOTER = "Print lab repeating footer",
  PRINT_LAB_GOVERNMENT = "Print lab government",
  PRINT_LAB_A5 = "Print lab A5",
  PRINT_LAB_BLOCK_CATEGORY = "Print lab block category",
  BOOKING_STATUS_MARK_HANDLED = "Booking status mark handled",
  BOOKING_STATUS_MARK_HANDLED_CREATE_BILL = "Booking status mark handled and create bill",
  BOOKING_STATUS_MARK_REFERRED = "Booking status mark referred",
  BOOKING_STATUS_CONFIRMED = "Booking status confim booking",
  BOOKING_STATUS_WAITING = "Booking status mark waiting",
  BOOKING_STATUS_ENGAGED = "Booking status mark engaged",
  BOOKING_STATUS_CANCELED = "Booking status canceled",
  BOOKING_STATUS_DELETED = "Booking status deleted",
  LAB_RECORD_TYPE_ALL_TAB = "Lab record type all tab",
  LAB_RECORD_TYPE_PATHOLOGY_TAB = "Lab record type pathology tab",
  LAB_RECORD_TYPE_RADIOLOGY_TAB = "Lab record type radiology tab",
  REPORT_GO_TO_STOCK_AUDIT = "Report go to stock audit report",
  REPORT_GO_TO_SUPPLIER_LEDGER = "Report go to supplier ledger report",
  REPORT_GO_TO_STOCK_TRANSACTION = "Report go to stock transaction report",
  REPORT_GO_TO_STOCK_HISTORY = "Report go to stock history report",
  REPORT_GO_TO_STOCK_SUMMARY = "Report go to stock summary report",
  REPORT_GO_TO_STOCK_EXPIRY = "Report go to stock expiry report",
  REPORT_GO_TO_STOCK_LEDGER = "Report go to stock ledger report",
  REPORT_GO_TO_STOCK_CASHFLOW = "Report go to stock cashflow report",
  REPORT_GO_TO_ACCOUNT_DAY_BOOK = "Report go to day book report",
  REPORT_GO_TO_ACCOUNT_PROFIT_LOSS = "Report go to profit loss report",
  REPORT_GO_TO_ACCOUNT_BALANCE_SHEET = "Report go to balance sheet report",
  REPORT_GO_TO_ACCOUNT_BANK_RECONCILIATION = "Report go to bank reconciliation report",
  REPORT_GO_TO_ACCOUNT_VENDOR = "Report go to vendor report",
  REPORT_GO_TO_ACCOUNT_CUSTOMER = "Report go to customer report",
  REPORT_GO_TO_ACCOUNT_GENERAL_LEDGER = "Report go to general ledger report",
  REPORT_GO_TO_ACCOUNT_TRIAL_BALANCE = "Report go to trial balance report",
  REPORT_GO_TO_PATIENT_APPOINTMENT = "Report go to appointment report",
  REPORT_GO_TO_PATIENT_PRESCRIPTION = "Report go to patient prescription report",
  REPORT_GO_TO_PATIENT_CLIENT = "Report go to client report",
  REPORT_GO_TO_PATIENT_REMINDERS = "Report go to reminders report",
  REPORT_GO_TO_FINANCIAL_SALES = "Report go to sales report",
  REPORT_GO_TO_FINANCIAL_AND_REFERRAL = "Report go to financial and referral report",
  REPORT_GO_TO_FINANCIAL_SALES_BY_SERVICE = "Report go to sales by service report",
  REPORT_GO_TO_FINANCIAL_DUE = "Report go to due report",
  REPORT_GO_TO_FINANCIAL_RECEIPT = "Report go to receipt report",
  REPORT_GO_TO_FINANCIAL_CLIENT_LEDGER = "Report go to client ledger report",
  REPORT_GO_TO_FINANCIAL_SERVICE_PROVIDER_CHARGES = "Report go to service provider charges report",
  REPORT_GO_TO_OTHERS_SUMMARY = "Report go to other summary report",
  REPORT_GO_TO_OTHERS_HMIS_LAB = "Report go to himis lab report",
  REPORT_GO_TO_OTHERS_HMIS_MAIN = "Report go to hmis main report",
  REPORT_GO_TO_OTHERS_LAB_SERVICE_REPORTS = "Report go to lab service report",
  REPORT_GO_TO_OTHERS_ACTIVITY_HISTORY = "Report go to activity history report",
  REPORT_GO_TO_SUMMARY_REPORT = "Report go to summary report",
  REPORT_GO_TO_MESSAGE_REPORT = "Report go to message report",
  REPORT_GO_TO_LAB_REPORT = "Report go to lab report",
  REPORT_GO_TO_IRD_REPORT = "Report go to ird report",
  REPORT_GO_TO_CENTRAL_MONITORING_REPORT = "Report go to central monitoring report",
  REPORT_GO_TO_BRANCH_REPORT = "Report go to branch report",
  DASHBOARD_CREATE_BILL_USING_SHORTCUT = "Create bill from dashboard using shortcut",
  DASHBOARD_CREATE_LAB_USING_SHORTCUT = "Create lab from dashboard using shortcut",
  DASHBOARD_CREATE_CLIENT_USING_SHORTCUT = "Create client from dashboard using shortcut",
  DASHBOARD_CREATE_BILL = "Create bill from dashboard",
  DASHBOARD_CREATE_LAB = "Create lab from dashboard",
  DASHBOARD_CREATE_CLIENT = "Create client from dashboard",
  CREATE_BILL = "Create bill",
  CREATE_BILL_AND_PRINT = "Create bill and print",
  CREATE_ASSESSMENT = "Create assessment",
  CREATE_LAB_TEST = "Create lab test"
}

export const BOOKING_EVENT_MAPPING = {
  markHandled: EVENT.BOOKING_STATUS_MARK_HANDLED,
  markHandledAndCreateBill: EVENT.BOOKING_STATUS_MARK_HANDLED_CREATE_BILL,
  markReferred: EVENT.BOOKING_STATUS_MARK_REFERRED,
  confirmBooking: EVENT.BOOKING_STATUS_CONFIRMED,
  markWaiting: EVENT.BOOKING_STATUS_WAITING,
  markEngaged: EVENT.BOOKING_STATUS_ENGAGED
};

export default EVENT;
