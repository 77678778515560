import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import OkhatiDialog from "../../components/Dialog/Dialog";
import Panel from "../../components/Panel";
import { tl } from "../../components/translate";
import { patchMedicalTest } from "../../actions/medicalTest";
import { LabStatuses } from "../../interfaces/Lab";
import EntryCore from "../Lab/EntryV2/EntryCore";
import { createEntry } from "../../slices/labRecordSlice";
import { RootState } from "../../store";
import MedicalVitals, { getDefaultVitals } from "./MedicalVitals";
import { Vitals } from "../../interfaces/AssessmentInterfaces";
import { useAppSelector } from "../../store/hooks";

interface Props {
  handleClose: () => void;
  id: number;
  medicalTest: any;
  navigateTo: (v: string) => void;
  updateTest: (data, id) => void;
}

function EnterTestResult({ handleClose, id, medicalTest, navigateTo, updateTest }: Props) {
  const medicalLabTests = medicalTest?.results?.data[0]?.labTests || [];

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const entryTest = useSelector((state: RootState) => state.labRecords.entryDraft);

  const rc = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre
  );
  const vitalsOrder =
    useAppSelector(
      (state) => state.userContext?.resourceCentre?.settings?.assessmentSettings?.vitalsOrder
    ) || [];
  const vitalSettings = rc?.settings.vitalSettings;
  const defaultVitals = getDefaultVitals(vitalsOrder, vitalSettings);

  const [vitals, setVitals] = useState<Vitals[]>(medicalTest?.results?.vitals || defaultVitals);
  const [addVitals, setAddVitals] = useState<boolean>(
    !!medicalTest?.results?.vitals?.length || false
  );
  const authorizedSP = rc?.settings?.medicalSettings;

  const [saving, setSaving] = React.useState(false);
  const saveDraft = async () => {
    setSaving(true);
    const toPost = {
      attachments: [],
      authorizedSP1Id: authorizedSP.authorizedSP1,
      authorizedSP2Id: authorizedSP.authorizedSP2,
      authorizedSP3Id: authorizedSP.authorizedSP3,
      authorizedSP4Id: authorizedSP.authorizedSP4,
      final: false,
      updatedTestResults: { ...entryTest.results, vitals: addVitals ? vitals : [] }
    };

    await updateTest(toPost, id);
    setSaving(false);
    navigateTo(`/medical/details/${id}?openVitals=true`);
  };

  React.useEffect(() => {
    dispatch(createEntry(medicalTest));
  }, [medicalTest, dispatch]);
  const [skipInternalStock, setSkipInternalStock] = React.useState<boolean>(false);
  if (
    !medicalTest ||
    medicalTest.status === LabStatuses.RESULT_READY ||
    medicalTest.status === LabStatuses.CANCELED
  )
    return null;

  return (
    <Panel title="Medical Test Results" onClose={handleClose}>
      <Box>
        <Box px={5} pt={2}>
          <Typography>
            <Box display="flex">
              <Typography style={{ width: "120px" }}>{tl("lab.testId")}</Typography>
              <Typography>
                <Box component="span" fontWeight="600">
                  {id}
                </Box>
              </Typography>
            </Box>
            <Box display="flex" my="16px">
              <Box flexDirection="column" justifyContent="space-between" mr="20px">
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.client")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {`${medicalTest.client?.firstName} ${medicalTest.client?.lastName}`}
                    </Box>
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography style={{ width: "120px" }}>{tl("lab.phoneNo")}</Typography>
                  <Typography>
                    <Box component="span" fontWeight="600">
                      {medicalTest.client?.phone}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Typography>
          <Button
            onClick={() => setAddVitals(!addVitals)}
            startIcon={addVitals ? <RemoveIcon /> : <AddIcon />}
          >
            {addVitals ? tl("medical.removeVitals") : tl("medical.addVitals")}
          </Button>
          {addVitals && <MedicalVitals vitals={vitals} onChange={setVitals} />}
        </Box>
        <EntryCore />
        {medicalLabTests.length > 0 && (
          <Box display="flex" justifyContent="space-between" px={5} py={2}>
            <Box alignContent="start" display="flex" alignItems="center">
              <Checkbox
                checked={skipInternalStock}
                onChange={() => setSkipInternalStock(!skipInternalStock)}
              />
              <Typography>Skip process to decrease stock quantity.</Typography>
            </Box>
            <Box display="flex" alignContent="end">
              <Button color="primary" disabled={saving} onClick={saveDraft} data-testmation="draft">
                <Typography>{tl("medical.draft")}</Typography>
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
                data-testmation="finalize"
              >
                <Typography>{tl("medical.save")}</Typography>
              </Button>
            </Box>
          </Box>
        )}
        <OkhatiDialog
          disableConfirmBtn={saving}
          readMode={false}
          title="Confirm Lab Report"
          description="Are you sure you want to finalize the lab report and add vitals? This cannot be edited again!"
          cancel={() => {
            setOpen(false);
          }}
          next={async () => {
            setSaving(true);
            const toPost = {
              skipInternalStock,
              attachments: [],
              authorizedSP1Id: authorizedSP.authorizedSP1,
              authorizedSP2Id: authorizedSP.authorizedSP2,
              authorizedSP3Id: authorizedSP.authorizedSP3,
              authorizedSP4Id: authorizedSP.authorizedSP4,
              final: true,
              updatedTestResults: {
                ...entryTest.results,
                examDate: moment().toISOString(),
                vitals: addVitals ? vitals : []
              }
            };

            await updateTest(toPost, id);
            setOpen(false);
            setSaving(false);
            navigateTo(`/medical/details/${id}?openVitals=true`);
          }}
          open={open}
        />
      </Box>
    </Panel>
  );
}

function mapStateToProps(state, ownProps) {
  const medicalTest = state.medicalTest.collection.find((item) => item.id === Number(ownProps.id));
  return {
    medicalTest
  };
}

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => {
    dispatch(push(url));
  },
  updateTest: (data, id) => dispatch(patchMedicalTest(data, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterTestResult);
