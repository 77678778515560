import moment from "moment";
import { paymentOptionsEnum } from "../containers/Billing/Editor/BillSummary";
import { Client, Customer } from "./ClientInterface";
import { ResourceCentre } from "./ResourceCentreInterface";
import { StockTransactions } from "./StockInterfaces";

export const CurrentBillVersion = "2.0.0";

export enum VisitType {
  OPD = "opd",
  IPD = "ipd"
}

export type VisitTypes = VisitType.OPD | VisitType.IPD;

export enum DocumentTypes {
  INVOICE = "invoice",
  CREDITNOTE = "creditNote"
}

export enum BillStatus {
  draft = "draft",
  billed = "billed",
  cancelled = "cancelled"
}

export enum DiscountBasedOn {
  invoice = "invoice",
  inline = "inline"
}

export enum QRPaymentReceiptMethods {
  khalti = "khalti",
  esewa = "esewa",
  fonepay = "fonepay"
}

export enum DiscountTypes {
  percentage = "percent",
  amount = "amount"
}

export enum TransactionType {
  DEBIT = "debit",
  CREDIT = "credit"
}

export enum BillItemFieldTypes {
  delivered = "delivered",
  returned = "returned",
  description = "description",
  batchInfo = "batchInfo",
  quantity = "quantity",
  unit = "unit",
  perUnit = "perUnit",
  discountPercent = "discountPercent",
  discountAmt = "discountAmt",
  VATPercent = "vatPct",
  grossTotal = "grossTotal",
  department = "department",
  info = "info",
  type = "type"
}

export enum BatchInfoFieldTypes {
  batchId = "batchId",
  quantity = "quantity"
}

export enum PrintTemplate {
  A5 = "A5Template",
  B4 = "B4",
  BankPaper = "bankPaper",
  Default = "defaultTemplate",
  Bordered = "borderedTemplate",
  RepeatingFooter = "fixedFooterTemplate",
  Government = "governmentTemplate",
  BlockCategory = "blockCategoryTemplate"
}

export type DocumentType = DocumentTypes.INVOICE | DocumentTypes.CREDITNOTE;

export type DiscountType = DiscountTypes.percentage | DiscountTypes.amount;

export type BillItemFieldType =
  | BillItemFieldTypes.delivered
  | BillItemFieldTypes.returned
  | BillItemFieldTypes.description
  | BillItemFieldTypes.batchInfo
  | BillItemFieldTypes.quantity
  | BillItemFieldTypes.unit
  | BillItemFieldTypes.perUnit
  | BillItemFieldTypes.discountPercent
  | BillItemFieldTypes.discountAmt
  | BillItemFieldTypes.VATPercent
  | BillItemFieldTypes.grossTotal
  | BillItemFieldTypes.department
  | BillItemFieldTypes.info
  | BillItemFieldTypes.type;

export type BatchInfoFieldType = BatchInfoFieldTypes.batchId | BatchInfoFieldTypes.quantity;

export type BillItemCalculationBasis = "perUnit" | "grossTotal";

export interface BillSummaryType {
  taxableAmount: number;
  taxAmtWithRates: Record<string, number>;
  totalPerUnitTimesQty: number;
  taxAmount: number;
  totalAmount: number;
  roundOffAmt: string;
  inWords: string;
  discount: number;
  discountPct: number;
  discountType?: string;
}
export interface BatchInfoType {
  batchId: string;
  quantity: number;
  expiryDate: moment.Moment;
}

export enum StockUnitTypes {
  package = "package",
  unit = "unit"
}

export enum BillItemSource {
  stocks = "stocks",
  services = "services"
}

export interface BillItemType {
  sNo: number;
  returned?: boolean;
  delivered: boolean;
  description: string;
  serviceProviderId?: number;
  batchInfo: BatchInfoType;
  quantity: number;
  expiryDate: moment.Moment;
  unit: string;
  perUnit: number;
  discountPercent: number;
  discountAmt: number;
  vatPct: number;
  vatAmtBeforeDiscount: number;
  vatAmtAfterDiscount: number;
  grossTotal: number;
  serviceProviderRateUnit: "percentage";
  stockUnitType?: StockUnitTypes;
  stockProductUnitsPerPackage?: number;
  productId: number;
  nonDiscountable: boolean;
  source?: BillItemSource;
  productData?: {
    labCharge: number;
    materialCharge: number;
    name: string;
    id: number;
    source: string;
    servicePriceExcVAT: number;
    unitPriceExcVAT: number;
    isNarcotics: boolean;
  };
  subItems: {
    grossTotalPrice: number;
    description: string;
    productData: ProductData;
    productId: number;
    productisableId: number | null;
    productisableName: string;
    serviceProviderId: number;
  }[];
  salesLedgerId: number;
  departmentId: number;
  info?: string;
}

export interface ProductData {
  category: string;
  grossTotalPrice: string;
  id: number;
  labCharge: number;
  materialCharge: number;
  name: string;
  productType: string;
  rates: { id: number; rate: number; unit: string; serviceId: number; serviceProviderId: number }[];
  resourceCentreId: number;
  servicePriceExcVAT: number;
  serviceProviderRateUnit: string;
  vatPct: number;
}

export interface BillDocumentSettingsType {
  showFields: BillItemFieldType[];
  discountSettings: {
    discountBasis: DiscountBasedOn;
    discountType: DiscountType;
  };
  extraColumnSettings: { [key: string]: boolean };
  remarksCompulsoryForDiscountedBill: boolean;
}

export interface BillPaymentInfoType {
  paid: boolean;
  paidAmount: number;
  paymentMethod?: string;
  tenderAmount: number;
  changeAmount: number;
  paymentDistribution?: { additionalPayment; paymentFromBalance };
}

export interface BillClient {
  id: number;
  dob: string;
  email: string;
  phone: string;
  lastName: string;
  firstName: string;
}
export interface BillCustomer {
  id: number;
  clientId: number;
  customerNumber: string;
  externalIdentifier: string;
  client: { active: boolean };
}

export interface BillDocumentType {
  claimNumber: string;
  insuranceNumber: string;
  id: number;
  referencedBillNum?: number;
  version: string;
  type: DocumentType;
  isDraft: boolean;
  isInternal: boolean;
  title: string;
  billNumber: string;
  referredBy: string;
  issueDate: string;
  dueDate: string;
  remarks: string;
  fiscalYear: string;
  summary: BillSummaryType;
  billItems: Array<Partial<BillItemType>>;
  client: Client & { balance?: number };
  resourceCentre: {
    id: number;
    panNo: string;
  };
  settings: BillDocumentSettingsType;
  enteredBy: {
    userId: number;
    signature: string;
    name: string;
  };
  paymentInfo: BillPaymentInfoType;
  refundInfo?: {
    previousPaidAmt: number;
    previousDueAmt: number;
    maximumRefundable: number;
    previousPaymentMethod: paymentOptionsEnum;
  };
  referenceTo: number;
  referrerId?: number;
  isLocalStorageData?: boolean;
  extraReferrer?: { name: string; id: number };
  status: string;
  remindOn: {
    duration: number;
    format: string;
    note?: string;
  };
  createdLabTestIds?: Array<number>;
  createdLabTestNumbers?: Array<number>;
}

export interface BillForList {
  id: number;
  billNumber: string;
  client: BillClient;
  customer: BillCustomer;
  isDraft: boolean;
  issueDate: string; // ISO Date String
  insuranceNumber: string;
  claimNumber: string;
  paymentInfo: BillPaymentInfoType;
  referenceTo: number;
  referrers: string;
  related: number;
  status: BillStatus;
  summary: BillSummaryType;
  total: string;
}

export interface BillType {
  client?: Client;
  id: number;
  paymentInfo: BillPaymentInfoType;
  document: BillDocumentType;
  status: BillStatus;
  referenceTo: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  type: DocumentTypes;
  billNumber: number;
  insuranceNumber: string;
  claimNumber: string;
  printCount: number;
  customer: Customer;
  labId: number;
  related: BillType;
  issueDate: string;
  paidAmount: number;
  summary: BillSummaryType;
  isDraft: boolean;
  uuid: string;
  relatedPurchaseEntry: Partial<StockTransactions>;
}

export interface BillTypeByUUID extends BillType {
  resourceCentre: ResourceCentre;
  clientBalance: number;
}

export enum TrackingNumType {
  RECEIPT_NUMBER = "receiptNumber",
  REFUND_NUMBER = "refundNumber"
}

export interface TrackingNumInterface {
  active: boolean;
  id: number;
  lastNumber: number;
  nextNumber: number;
  type: string;
}

export interface Receipt {
  receiptId: number;
  paidAmount: number;
  billId: number;
  paymentMethod: string;
  receivedOn: string;
}
