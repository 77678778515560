import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { match as MatchProps } from "react-router";
import { Box, Button, Typography } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { getService, serviceProviderActions, deleteServices, getServices } from "../../actions";
import Panel from "../../components/Panel";
import Dialog from "../../components/Dialog/Dialog";
import { tl, t } from "../../components/translate";
import { spFullNameSelector, serviceProvidersSortedSelector } from "../../reducers/serviceProvider";
import { rupeeDisplay } from "../../helpers/rupee";
import { LabTest } from "../../interfaces/LabInterfaces";
import { getBatchLabTests } from "../../actions/assessments";
import Can from "../Policy/Can";
import { IThunkDispatch } from "../../store";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import styles from "./Service.module.css";
import { ProductInterface } from "../../interfaces/ProductInterface";
import { getCategory, isServicePublic } from "./ServiceList";
import { PublicServiceStatus } from ".";

interface ProductInfoInterface {
  product: ProductInterface;
  productId: number;
  actions: {
    getProduct: (productId: number) => void;
    goBack: (isPublicTab: boolean) => void;
    loadServiceProviders: (id) => void;
    navigateTo: (url) => void;
    deleteService: (id, isPublicTab: boolean) => void;
    loadServices: () => void;
    loadLabTests: () => void;
  };
  resourceCentreId: number;
  serviceProviders: Array<ServiceProvider>;
  productServiceProviders: Array<ServiceProvider>;
  handleClose: () => void;
  labTests: Array<LabTest>;
  match: MatchProps;
  isPublicTab?: boolean;
  onEditClick?: () => void;
}

const ProductInfo: React.FC<ProductInfoInterface> = (props) => {
  const {
    product,
    productId,
    actions,
    resourceCentreId,
    serviceProviders,
    productServiceProviders,
    handleClose,
    labTests,
    match,
    isPublicTab = false,
    onEditClick
  } = props;
  React.useEffect(() => {
    if (!serviceProviders.length) {
      actions.loadServiceProviders(resourceCentreId);
    }
    actions.getProduct(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, actions, resourceCentreId]);

  React.useEffect(() => {
    if (!labTests.length) {
      actions.loadLabTests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dialogToggle, setDialogToggle] = React.useState(false);
  const dialogToggler = () => {
    setDialogToggle(!dialogToggle);
  };

  const deleteServiceFn = async () => {
    await actions.deleteService(productId, isPublicTab);
    actions.loadServices();
  };

  const deleteButton = !(isPublicTab && !product?.resourceCentreId) ? (
    <Can policyAccessKey="services:deleteProduct">
      <Delete
        style={{ cursor: "pointer" }}
        data-testmation="productInfoDeleteButton"
        onClick={() => setDialogToggle(true)}
      />
    </Can>
  ) : null;

  const editButton = !(isPublicTab && !product?.resourceCentreId) ? (
    <Can policyAccessKey="services:editProduct">
      <Edit
        style={{ cursor: "pointer" }}
        data-testmation="productInfoEditButton"
        onClick={onEditClick ? () => onEditClick() : () => actions.navigateTo(`${match.url}/edit`)}
      />
    </Can>
  ) : (
    <Can policyAccessKey="services:editProduct">
      <Button
        style={{
          backgroundColor: "primary"
        }}
        variant="contained"
        onClick={() => actions.navigateTo(`${match.url}/edit`)}
      >
        <Typography>Make It Public</Typography>
      </Button>
    </Can>
  );

  if (!product) return null;

  return (
    <Panel
      onClose={() => {
        handleClose();
        actions.goBack(isPublicTab);
      }}
      deleteButton={deleteButton}
      editButton={editButton}
    >
      {dialogToggle && (
        <Dialog
          title={tl("services.deleteProduct")}
          description={tl("services.deleteProductDescription")}
          next={deleteServiceFn}
          cancel={dialogToggler}
          readMode={false}
        />
      )}
      <Box pl={5} pr={5} width="100%" className={styles.boxHeight}>
        <div>
          <Box display="flex">
            <Typography fontSize="1.4em" fontWeight={600} pb={1}>
              {product.name}
            </Typography>

            {isPublicTab && (
              <Typography
                fontWeight={400}
                ml={3}
                sx={{
                  backgroundColor: isServicePublic(product) ? "#009654" : "#e39130",
                  minWidth: "80px",
                  borderRadius: "20px",
                  color: "#fff"
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {isServicePublic(product)
                  ? PublicServiceStatus.PUBLIC
                  : PublicServiceStatus.NOTPUBLIC}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Box component="span" display="flex" fontSize="1rem" fontWeight={300}>
              {getCategory(product.category)}
            </Box>
            {product.productisableId && labTests && (
              <Typography
                style={{
                  marginLeft: "8px",
                  fontSize: "0.75rem",
                  fontWeight: 300
                }}
              >
                ({labTests.find((test) => test.id === product.productisableId)?.name})
              </Typography>
            )}
          </Box>
        </div>

        <Box display="flex" marginTop="16px">
          <Box width="30%">
            <Typography>{tl("services.vatPct")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">{`${product.vatPct} %`} </Box>
          </Typography>
        </Box>

        <Box display="flex">
          <Box width="30%">
            <Typography>{tl("services.clinicPriceExVAT")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">
              {rupeeDisplay(
                Number(product.vatPct) !== 0 ? product.servicePriceExcVAT : product.grossTotalPrice
              )}
            </Box>
          </Typography>
        </Box>

        <Box display="flex">
          <Box width="30%">
            <Typography>{tl("services.clinicPrice")}</Typography>
          </Box>

          <Typography>
            <Box fontWeight="600">{rupeeDisplay(product.grossTotalPrice)} </Box>
          </Typography>
        </Box>

        <Box display="flex">
          <Box width="30%">
            <Typography>{tl("unit")}</Typography>
          </Box>

          <Typography>
            {" "}
            <Box fontWeight="600">{product.unit}</Box>
          </Typography>
        </Box>

        {product?.materialCharge && Number(product.materialCharge) !== 0 && (
          <Box display="flex">
            <Box width="30%">
              <Typography>{tl("services.materialCharge")}</Typography>
            </Box>
            <Typography>
              <Box fontWeight="600">{product.materialCharge} %</Box>
            </Typography>
          </Box>
        )}
        {product?.labCharge && Number(product.labCharge) !== 0 && (
          <Box display="flex">
            <Box width="30%">
              <Typography>{tl("services.labCharge")}</Typography>
            </Box>
            <Typography>
              <Box fontWeight="600">Rs {product.labCharge}</Box>
            </Typography>
          </Box>
        )}
        <Can policyAccessKey="services:addPayables">
          {product.document?.rates?.length > 0 && productServiceProviders.length > 0 && (
            <Box pt={5}>
              <Box marginBottom="10px">
                <Typography>
                  <Box fontWeight="500" color="gray">
                    {tl("services.serviceProviderPayables")}
                  </Box>
                </Typography>
              </Box>

              {product.document?.rates?.map((rate) => (
                <Box
                  key={rate.id}
                  display="flex"
                  borderBottom="1.5px solid lightgray"
                  pt={1}
                  pb={1}
                >
                  <Box width="50%">
                    <Typography>
                      {productServiceProviders?.map((sp) => {
                        if (sp && Number(sp.id) === Number(rate.serviceProviderId)) {
                          return spFullNameSelector(sp);
                        }
                        return "";
                      })}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {product?.serviceProviderRateUnit === "rupee"
                        ? `${t("rs")} ${rate.rate}`
                        : `${rate.rate} %`}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Can>
        {product.parentPackages?.length ? (
          <Box mt={5}>
            <Box fontWeight="500" color="gray" marginBottom="10px">
              <Typography>{tl("service.belongsToPackages")}</Typography>
            </Box>
            <Box paddingLeft="15px">
              <ol>
                {product.parentPackages?.map(({ name, referenceId }, index) =>
                  isPublicTab && referenceId ? null : (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} pb={0.5}>
                      <Typography>
                        <li>{name}</li>
                      </Typography>
                    </Box>
                  )
                )}
              </ol>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Panel>
  );
};

function mapStateToProps(state, { productId, isPublicTab }) {
  let product = [
    ...(isPublicTab ? state?.publicServices?.collection : []),
    ...state.services.collection
  ].find(({ id }) => id === productId);
  const serviceProviders = serviceProvidersSortedSelector(state);
  let productServiceProviders = [];
  if (product && serviceProviders?.length) {
    productServiceProviders = serviceProviders.filter((sp) => {
      const productSP = product.document?.rates?.map((rate) => rate.serviceProviderId);
      if (productSP?.includes(sp.id)) return sp;
      return false;
    });
    const productServiceProvidersIds = productServiceProviders.map((sp) => sp.id);
    product = {
      ...product,
      document: {
        ...product.document,
        rates: product.document?.rates?.filter((rate) => {
          if (productServiceProvidersIds.includes(rate.serviceProviderId)) {
            return rate;
          }
          return false;
        })
      }
    };
  }
  return {
    product,
    productServiceProviders,
    serviceProviders,
    resourceCentreId: state.userContext.resourceCentreId,
    labTests: state.assessments.labTests
  };
}

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  actions: {
    getProduct: (productId) => dispatch(getService(productId)),
    goBack: (isPublicTab) => dispatch(push(isPublicTab ? `/publicServices` : `/services`)),
    loadServiceProviders: (id) =>
      dispatch(
        serviceProviderActions.getResourceCentreServiceProviders({
          resourceCentreId: id
        })
      ),
    navigateTo: (url) => {
      dispatch(push(url));
    },
    deleteService: async (id, isPublicTab) => {
      await dispatch(deleteServices(id));
      dispatch(push(isPublicTab ? "/publicServices" : "/services"));
    },
    loadServices: () => dispatch(getServices()),
    loadLabTests: () => dispatch(getBatchLabTests())
  }
}))(ProductInfo);

ProductInfo.defaultProps = {
  isPublicTab: false
};
