import { Alert, Avatar, Box, Button, TextField, Typography } from "@mui/material";
import produce from "immer";
import { has, pickBy } from "lodash";
import * as moment from "moment";
import * as React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateReferrerAction } from "../../../actions/bill";
import * as NotificationActions from "../../../actions/notification";
import { patchReferrer } from "../../../actions/referrers";
import { getClientBalance } from "../../../api/bill";
import { updateClient } from "../../../api/client";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import ClientSearch from "../../../components/ClientSearch";
import Modal from "../../../components/Modal/Modal";
import { t, tl } from "../../../components/translate";
import { ChildGeneralLedger, LedgerType } from "../../../interfaces/Accounts";
import { VisitType } from "../../../interfaces/BillInterfaces";
import { getCurrentSubscription } from "../../../slices/subscriptionSlice";
import { RootState } from "../../../store";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../accounts/hooks";
import { paymentOptionsEnum } from "./BillSummary";
import { getIpdDetailsByClientId } from "../../../api/ipd";
import { commonErrorMessage } from "../../../helpers/messages";
import useIpdBeds from "../../../hooks/useIpdBeds";
import ClientCreateEdit from "../../Client/ClientCreateEdit";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";

const Label = ({ children }) => (
  <Typography component="span" style={{ display: "flex" }}>
    <Box
      component="span"
      display="flex"
      fontSize="1.1em"
      fontWeight={600}
      alignItems="center"
      width="120px"
      marginRight="20px"
    >
      {children}
    </Box>
  </Typography>
);

const BillHeader = ({
  draft,
  updateDraft,
  isCreditNote,
  policies,
  visitType,
  isExtraReferrerEnabled
}) => {
  const dispatch = useDispatch();
  const { rcId, currentSubscription, isAccountSubscribed } = useSelector((state: RootState) => ({
    rcId: state.userContext.resourceCentreId,
    currentSubscription: state.subscriptions.currentSubscription,
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed
  }));

  const [showLedgerSelect, setShowLedgerSelect] = React.useState<{
    client: boolean;
    referrer: boolean;
  }>({
    client: false,
    referrer: false
  });
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const [isChangingClient, setIsChangingClient] = React.useState<boolean>(false);
  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);

  const [focusReferrer, setFocusReferrer] = React.useState<boolean>(false);
  const [focusClient, setFocusClient] = React.useState<boolean>(false);

  const { equityAndLiabilities, assets } = extractSecondaryData(useCoaAll());
  const allEquityAndLiabilities = getAllChildGl(equityAndLiabilities);
  const allAssetsLedgers = getAllChildGl(assets);

  const insuranceNumRef = React.useRef<HTMLInputElement>(null);
  const beds = useIpdBeds();
  React.useEffect(() => {
    (async () => {
      if (
        !draft?.client?.isWalkInCustomer &&
        draft?.client?.id &&
        draft?.visitType === VisitType.IPD
      ) {
        try {
          const ipdDetails = await getIpdDetailsByClientId(draft?.client?.id);
          updateDraft({
            ...draft,
            admissionDate: ipdDetails.dateOfAdmission || "",
            dischargeDate: ipdDetails.dateOfDischarge || "",
            ...(beds.length && {
              bedId: ipdDetails.bedId,
              bed: beds.find((b) => b.id === ipdDetails.bedId)
            })
          });
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getTime(),
              message: commonErrorMessage,
              variant: "error",
              autoTimeout: true
            })
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.client?.id, draft?.visitType, beds.length]);

  React.useEffect(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [currentSubscription, dispatch, rcId]);
  const [showAlert, setShowAlert] = React.useState<{
    client: boolean;
    referrer: boolean;
  }>({
    client: false,
    referrer: false
  });

  React.useEffect(() => {
    if (draft?.client?.id && !has(draft, "insuranceNumber")) {
      updateDraft({ ...draft, insuranceNumber: draft.client.insuranceNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft?.client]);

  React.useEffect(() => {
    if (draft?.client?.id && !draft?.client?.ledgerId && isAccountSubscribed && !showAlert.client) {
      setShowAlert({ ...showAlert, client: true });
    }
  }, [draft?.client, isAccountSubscribed, showAlert]);

  // Focus on client input if client is walkin or empty else focus on referrer input
  React.useEffect(() => {
    if (!isCreditNote) {
      if (draft?.client && (draft.client.isWalkInCustomer || !draft.client.id)) {
        setIsChangingClient(true);
        setFocusClient(true);
        setFocusReferrer(false);
      } else {
        setFocusReferrer(true);
        setFocusClient(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreditNote]);

  // When focused on client input and pressed enter, close the client changing interface
  useHotkeys(
    "return",
    () => {
      if (focusClient && draft.client.id) {
        setIsChangingClient(false);
      }
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"]
    }
  );
  React.useEffect(() => {
    if (
      draft?.referrerId &&
      !draft?.referrerLedgerId &&
      isAccountSubscribed &&
      !showAlert.referrer
    ) {
      setShowAlert({ ...showAlert, referrer: true });
    }
  }, [draft?.referrerId, draft?.referrerLedgerId, isAccountSubscribed, showAlert]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box display="flex">
          <div>
            {isCreditNote && (
              <Box display="flex">
                <Label>{tl("billing.creditNoteForBill")}</Label>
                <Box fontWeight={500}>{draft.referencedBillNum}</Box>
              </Box>
            )}
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box alignItems="center" display="flex" data-testmation="billingClientSelect">
                {!isChangingClient && (
                  <div>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography
                        sx={{
                          fontSize: "1em",
                          fontWeight: "400",
                          ...(!draft?.client?.isWalkInCustomer && {
                            "&:hover": { textDecoration: "underline", cursor: "pointer" }
                          })
                        }}
                        onClick={
                          !draft?.client?.isWalkInCustomer
                            ? () => setShowClientInfoPanel(true)
                            : () => null
                        }
                      >
                        {draft.client?.firstName} {draft.client?.lastName}
                      </Typography>
                      {!isCreditNote && (
                        <Typography
                          onClick={() => {
                            setIsChangingClient(true);
                            setFocusClient(true);
                          }}
                          marginLeft={2}
                          color="primary"
                          sx={{
                            cursor: "pointer"
                          }}
                        >
                          change
                        </Typography>
                      )}
                    </Box>
                    <Box component="div" sx={{ fontSize: "1em", fontWeight: "400" }}>
                      <Box component="div">
                        {draft.client?.email || ""}{" "}
                        {draft.client?.email && draft.client?.phone ? "," : " "}{" "}
                        {draft.client?.phone || ""}
                        {draft.client?.registrationNo ? `, ${draft.client?.registrationNo}` : ""}
                      </Box>
                      {draft.client?.balance >= 0 && (
                        <Box component="div" display="flex" alignItems="center">
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "1em"
                            }}
                          >
                            Balance Deposit
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "400",
                              marginLeft: "20px"
                            }}
                          >
                            {draft.client.balance}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </div>
                )}
                {isChangingClient && (
                  <Box display="flex" alignItems="center">
                    <ClientSearch
                      style={{ minWidth: "200px" }}
                      label={tl("billing.client")}
                      variant="outlined"
                      margin="dense"
                      visitType={visitType}
                      isDisabled={isCreditNote}
                      client={draft.client}
                      fromBill
                      afterClientCreation={() => {
                        setIsChangingClient(false);
                        setFocusClient(false);
                        setFocusReferrer(true);
                      }}
                      autoFocus={focusClient}
                      onBlur={() => {
                        if (draft.client?.id) {
                          setIsChangingClient(false);
                        }
                      }}
                      secondaryText={[
                        {
                          type: "customerNumber"
                        },
                        {
                          type: "externalIdentifier",
                          label: t("client.externalIdentifier.short")
                        }
                      ]}
                      setClient={async (v) => {
                        if (v?.id) {
                          setIsChangingClient(false);
                        }
                        const paymentInfo = {} as {
                          paymentMethod: paymentOptionsEnum;
                          paymentDistribution: {
                            paymentFromBalance: number;
                            additionalPayment: number;
                          };
                          paid: boolean;
                          paidAmount: number;
                        };
                        setShowAlert({ ...showAlert, client: false });
                        if (v?.id && !isCreditNote) {
                          const clientLedger = await getClientBalance(v?.id);
                          const client = produce(v, (draftItem) => {
                            draftItem.balance =
                              Number(clientLedger.balance) > 0 ? Number(clientLedger.balance) : 0;
                            if (
                              draftItem.balance > 0 &&
                              (draft.summary.totalBalance || 0) <= draftItem.balance
                            ) {
                              // if balance is enough to cover totalAmount of the bill,
                              // set it by default
                              paymentInfo.paymentMethod = paymentOptionsEnum.balance;
                              paymentInfo.paymentDistribution = {
                                paymentFromBalance: draft.summary.totalBalance,
                                additionalPayment: 0
                              };
                              paymentInfo.paid = true;
                              paymentInfo.paidAmount = draft.summary.totalBalance || 0;
                            }
                          });
                          updateDraft({
                            ...draft,
                            client,
                            insuranceNumber: v.insuranceNumber || "",
                            paymentInfo: {
                              ...draft.paymentInfo,
                              ...paymentInfo
                            }
                          });
                        } else {
                          updateDraft({
                            ...draft,
                            insuranceNumber: v.insuranceNumber || "",
                            client: v,
                            ...(draft.visitType === VisitType.IPD && {
                              bedId: null,
                              bed: null,
                              admissionDate: null,
                              dischargeDate: null
                            })
                          });
                        }
                        if (v?.id) {
                          setFocusReferrer(true);
                          setIsChangingClient(false);
                          setFocusClient(false);
                        }
                      }}
                    />
                    {!isCreditNote && (
                      <Typography
                        onClick={() => {
                          setIsChangingClient(false);
                          setFocusClient(false);
                          setFocusReferrer(true);
                        }}
                        marginLeft={2}
                        color="primary"
                        sx={{
                          cursor: "pointer"
                        }}
                      >
                        cancel
                      </Typography>
                    )}
                  </Box>
                )}
                {draft.client.customerDetails?.profileImageS3Url && (
                  <Avatar
                    sx={{
                      marginLeft: "20px",
                      width: "72px",
                      height: "72px"
                    }}
                    src={draft.client.customerDetails?.profileImageS3Url}
                  />
                )}
                {showAlert.client && (
                  <Alert
                    action={
                      <Button
                        onClick={() => setShowLedgerSelect({ ...showLedgerSelect, client: true })}
                        variant="outlined"
                        color="inherit"
                        size="small"
                      >
                        Map Ledger
                      </Button>
                    }
                    severity="warning"
                  >
                    This client is not mapped to any Account Gl.
                  </Alert>
                )}

                <Modal
                  open={showLedgerSelect.client}
                  title="Select Ledger for Supplier"
                  footer={
                    <>
                      <Button
                        onClick={() => {
                          setShowLedgerSelect({ ...showLedgerSelect, client: false });
                          setSelectedLedger(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!selectedLedger}
                        onClick={async () => {
                          try {
                            const res = await updateClient(
                              draft.client.id,
                              pickBy({
                                ...draft.client,
                                ledgerId: selectedLedger.id,
                                gender: Number(draft.client.gender)
                              })
                            );
                            if (res?.ledgerId) {
                              updateDraft({
                                ...draft,
                                client: { ...draft.client, ledgerId: res.ledgerId }
                              });
                            }
                            setShowLedgerSelect({ ...showLedgerSelect, client: false });
                            setShowAlert({ ...showAlert, client: false });
                            setSelectedLedger(null);
                          } catch (error) {
                            dispatch(
                              NotificationActions.notificationAdd({
                                id: new Date().getUTCMilliseconds(),
                                variant: "error",
                                message: "Can't update the client ledger.",
                                autoTimeout: true
                              })
                            );
                          }
                        }}
                      >
                        Save
                      </Button>
                    </>
                  }
                >
                  <LedgerSelect
                    options={
                      allAssetsLedgers?.filter(
                        (lg) => lg.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER
                      ) || []
                    }
                    selected={selectedLedger}
                    onChange={(value) => setSelectedLedger(value)}
                  />
                </Modal>
              </Box>
            </Box>
            <Box display="flex">
              <Label>{tl("billing.issueDate")}</Label>
              <CalendarDropdown
                disable={isCreditNote || !policies["bill:changeBillIssueDate"]}
                width="210px"
                TextFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  margin: "dense"
                }}
                date={new Date(draft.issueDate)}
                maxValidDate={new Date()}
                onChange={(newDate) => {
                  updateDraft({
                    ...draft,
                    issueDate: moment(newDate).toISOString()
                  });
                }}
              />
            </Box>

            {!isCreditNote && (
              <Box display="flex">
                <Label>Referrer</Label>
                <Box maxWidth="300px" flexBasis="200px">
                  <ReferrerCreateSelect
                    width="210px"
                    textFieldWidth="215px"
                    isFocusedInitially={focusReferrer}
                    onReferrerChange={(referrer) => {
                      setShowAlert({ ...showAlert, referrer: false });
                      dispatch(
                        updateReferrerAction({
                          ...draft,
                          referredBy: referrer?.referrer ?? null,
                          referrerId: referrer?.id ?? null,
                          referrerLedgerId: referrer?.ledgerId ?? null
                        })
                      );
                    }}
                    referrerId={draft?.referrerId}
                    showLabel
                    useSettings
                  />
                  {showAlert.referrer && (
                    <Alert
                      action={
                        <Button
                          onClick={() =>
                            setShowLedgerSelect({ ...showLedgerSelect, referrer: true })
                          }
                          variant="outlined"
                          color="inherit"
                          size="small"
                        >
                          Map Ledger
                        </Button>
                      }
                      severity="warning"
                    >
                      This referrer is not mapped to any Account Gl.
                    </Alert>
                  )}
                  <Modal
                    open={showLedgerSelect.referrer}
                    title="Select Ledger for Supplier"
                    footer={
                      <>
                        <Button
                          onClick={() => {
                            setShowLedgerSelect({ ...showLedgerSelect, referrer: false });
                            setSelectedLedger(null);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={!selectedLedger}
                          onClick={() => {
                            dispatch(
                              patchReferrer(
                                { id: draft.referrerId, ledgerId: selectedLedger.id },
                                draft.referrerId
                              )
                            );
                            updateDraft({
                              ...draft,
                              referrerLedgerId: selectedLedger.id
                            });
                            setShowLedgerSelect({ ...showLedgerSelect, referrer: false });
                            setShowAlert({ ...showAlert, referrer: false });
                            setSelectedLedger(null);
                          }}
                        >
                          Save
                        </Button>
                      </>
                    }
                  >
                    <LedgerSelect
                      options={allEquityAndLiabilities || []}
                      selected={selectedLedger}
                      onChange={(value) => setSelectedLedger(value)}
                    />
                  </Modal>
                </Box>
                {isExtraReferrerEnabled && (
                  <Box maxWidth="300px" flexBasis="200px">
                    <ReferrerCreateSelect
                      width="210px"
                      textFieldWidth="215px"
                      onReferrerChange={(referrer) => {
                        setShowAlert({ ...showAlert, referrer: false });
                        dispatch(
                          updateReferrerAction({
                            ...draft,
                            extraReferrer: {
                              name: referrer?.referrer ?? null,
                              id: referrer?.id ?? null
                            }
                          })
                        );
                      }}
                      referrerId={draft?.extraReferrer?.id}
                      showLabel
                      label="Extra referrer"
                      useSettings
                    />
                  </Box>
                )}
              </Box>
            )}
          </div>
          {draft?.visitType === VisitType.IPD && (
            <div>
              {draft?.bed?.id && (
                <Box display="flex">
                  <Label>Ward-Bed</Label>
                  <Typography>{draft?.bed?.name}</Typography>
                </Box>
              )}
              {draft?.admissionDate && (
                <Box display="flex">
                  <Label>Admitted Date: </Label>
                  <Typography>
                    {convertADtoBS(new Date(draft?.admissionDate)).formatted4}
                  </Typography>
                </Box>
              )}
              {draft?.dischargeDate && (
                <Box display="flex">
                  <Label>Discharge Date: </Label>
                  <Typography>
                    {convertADtoBS(new Date(draft?.dischargeDate)).formatted4}
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </Box>
        <div>
          <Box display="flex" mt={1}>
            <Label>
              {isCreditNote ? tl("billing.creditNoteNumber") : tl("billing.invoiceNumber")}
            </Label>
            <Box fontWeight={500}>
              {draft.billNumber ?? "Choose another date for voucher number"}
            </Box>
          </Box>
          <Box display="flex">
            <Label>{tl("billing.insuranceNumber")}</Label>
            <TextField
              inputRef={insuranceNumRef}
              disabled={isCreditNote}
              sx={{ marginTop: "10px" }}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              placeholder={t("billing.insuranceNumber")}
              value={draft.insuranceNumber}
              onChange={({ target }) => updateDraft({ ...draft, insuranceNumber: target.value })}
            />
          </Box>
          <Box display="flex">
            <Label>{tl("billing.claimNumber")}</Label>
            <TextField
              disabled={isCreditNote}
              sx={{ marginTop: "10px" }}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              placeholder={t("billing.claimNumber")}
              value={draft.claimNumber}
              onChange={({ target }) => updateDraft({ ...draft, claimNumber: target.value })}
            />
          </Box>
        </div>
      </Box>
      {showClientInfoPanel &&
        draft.client &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={draft.client.id}
            mode="edit"
            afterSave={() => {
              dispatch((_, getState: RootState) => {
                const currentState = getState();
                updateDraft({ ...draft, client: { ...currentState.clients.lastTouched } });
              });
              setShowClientInfoPanel(false);
            }}
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
          />
        ) : (
          <ClientInfo
            wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
            id={draft.client.id}
            handleViewClose={() => setShowClientInfoPanel(false)}
            setEditMode={setClientEditMode}
            stayOnCurrentPage
          />
        ))}
    </div>
  );
};

export default connect(
  (state: RootState) => ({ policies: state.userContext.policies }),
  null
)(BillHeader);
