import * as React from "react";
import { Grid, Box, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import { push } from "connected-react-router";
import EditIcon from "@mui/icons-material/Edit";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import moment from "moment-timezone";
import { tl } from "../../components/translate";
import { ageFormatter, separateByComma } from "../../helpers/formatters";
import { clientFullNameSelector } from "../../reducers/client";
import Authorize from "../../auth/authorization";
import ActionBar from "../../components/ActionBar/ActionBar";
import { uploadClientImage } from "../../actions/client";
import { getRCFromState } from "../../actions/resourceCentre";
import { IThunkDispatch, RootState } from "../../store";
import useIsAccountSubscribed from "../../hooks/accounts";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { MESSAGE_TYPE, MessageType } from "../Campaign/InstantMessage/MessageCreate";
import { Client } from "../../interfaces/ClientInterface";
import { IUser } from "../../interfaces/User";
import ClientImageUploader from "../../components/ImageUploader/ClientImageUploader";
import useAssociateCompanies from "../../hooks/useAssociateCompanies";
import { getCustomerNumber } from "./ClientList";

enum ClientDetailKey {
  CUSTOMER_NUMBER = "customerNumber",
  PHONE = "phone",
  SECONDARY_PHONE = "secondaryPhone",
  EMAIL = "email",
  OPENING_BALANCE = "openingBalance",
  OPENING_BALANCE_DATE = "openingBalanceDate",
  TRANSACTION_TYPE = "transactionType",
  IPD_NO = "ipdNo",
  REGISTRATION_NO = "registrationNo"
}

const commonDetailKey = [
  ClientDetailKey.CUSTOMER_NUMBER,
  ClientDetailKey.PHONE,
  ClientDetailKey.SECONDARY_PHONE,
  ClientDetailKey.EMAIL,
  ClientDetailKey.IPD_NO,
  ClientDetailKey.REGISTRATION_NO
];

const detailKeyForAccountUser = [
  ClientDetailKey.OPENING_BALANCE,
  ClientDetailKey.OPENING_BALANCE_DATE,
  ClientDetailKey.TRANSACTION_TYPE
];

interface ClientShowProps {
  client: Client;
  user: IUser;
  navigateTo: (url: string) => void;
  showActionbar: boolean;
  uploadPhoto: (id: number, image: Blob) => void;
  allowUpload: boolean;
  onMessageBtnClick: (mType: MessageType) => void;
}
const ClientShow = ({
  client,
  user,
  navigateTo,
  showActionbar,
  uploadPhoto,
  allowUpload,
  onMessageBtnClick
}: ClientShowProps) => {
  const age = ageFormatter(client.dob);
  let actions = [];
  if (Authorize(user).can("admin", "Patient", { id: client.id })) {
    actions = actions.concat([
      {
        label: tl("clients.edit"),
        action: () => navigateTo(`/clients/${client.id}/edit`),
        Icon: EditIcon,
        accessKey: "clients:editClient"
      }
    ]);
  }

  const companies = useAssociateCompanies();

  const isAccountSubscribed = useIsAccountSubscribed();
  return (
    <>
      {showActionbar && <ActionBar actions={actions} />}
      <Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6"> {clientFullNameSelector(client).toUpperCase()} </Typography>
              {!client.isCorporateClient && allowUpload && (
                <Box ml="32px">
                  <ClientImageUploader
                    action={(image) => {
                      uploadPhoto(client.id, image);
                    }}
                    initialImage={client.customerDetails?.profileImageS3Url || ""}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {age} {client.gender ? tl(`clients.gender.${client.gender}`) : ""}
            </Typography>
          </Grid>
        </Grid>
        <Box marginTop="32px" width="100%">
          <Box display="flex">
            <Typography
              sx={{
                width: "25%",
                fontWeight: 600,
                fontSize: "0.875rem",
                order: 1
              }}
            >
              {tl("clients.created_at")}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.875rem",
                order: 2
              }}
            >
              {moment(client.created_at).format("YYYY-MM-DD (hh:mm A)")}
            </Typography>
          </Box>
          {[...commonDetailKey, ...(isAccountSubscribed ? detailKeyForAccountUser : [])].map(
            (attr) =>
              client[attr] ? (
                <Box key={attr} display="flex">
                  <Box order={1} width="25%" fontSize="14px">
                    <Typography style={{ fontWeight: 600 }}>{tl(attr)}</Typography>
                  </Box>
                  <Box order={2} fontSize="14px">
                    {attr === ClientDetailKey.OPENING_BALANCE_DATE ? (
                      <Typography>{convertADtoBS(new Date(client[attr])).formatted4}</Typography>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center"
                        }}
                      >
                        <Typography>
                          {attr === ClientDetailKey.CUSTOMER_NUMBER
                            ? getCustomerNumber(client[attr])
                            : client[attr]}
                        </Typography>
                        {attr === ClientDetailKey.EMAIL && (
                          <Tooltip title="Send Email">
                            <ForwardToInboxIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => onMessageBtnClick(MESSAGE_TYPE.EMAIL)}
                            />
                          </Tooltip>
                        )}
                        {attr === ClientDetailKey.PHONE && (
                          <Tooltip title="Send SMS">
                            <SmsIcon
                              sx={{ cursor: "pointer", width: "0.9rem" }}
                              onClick={() => onMessageBtnClick(MESSAGE_TYPE.SMS)}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                ""
              )
          )}

          {(client.city || client.address) && (
            <>
              <Box display="flex">
                <Box order={1} width="25%" fontSize="14px">
                  <Typography style={{ fontWeight: 600 }}>{tl("clients.address")}</Typography>
                </Box>
                <Box order={2} fontSize="14px">
                  <Typography>{separateByComma(client.address, client.city)}</Typography>
                </Box>
              </Box>
            </>
          )}
          {client.associatedCompanyId && (
            <>
              <Box display="flex">
                <Box order={1} width="25%" fontSize="14px">
                  <Typography style={{ fontWeight: 600 }}>
                    {tl("clients.associatedCompany")}
                  </Typography>
                </Box>
                <Box order={2} fontSize="14px">
                  <Typography>
                    {companies.find((company) => company.id === client.associatedCompanyId)?.name}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default connect(
  (state: RootState) => {
    const rc = getRCFromState(state);
    return {
      user: { ...state.userContext.user, role: state.userContext.mode },
      allowUpload: rc?.settings.clientSettings.enablePictureUpload
    };
  },
  (dispatch: IThunkDispatch) => ({
    navigateTo: (url: string) => dispatch(push(url)),
    uploadPhoto: (id: number, image: Blob) => dispatch(uploadClientImage(id, image))
  })
)(ClientShow);
