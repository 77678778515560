import { merge } from "lodash";
import produce from "immer";

export const getDefaultKeyValuesColumns = (
  defaultColumns: string[],
  defaultHiddenColumns?: string[],
  hideDepartmentColumn?: boolean
): { [key: string]: boolean } => {
  const mergedCols = merge(
    (defaultColumns || []).reduce((acc, col) => ({ ...acc, [col]: true }), {}),
    (defaultHiddenColumns || []).reduce((acc, col) => ({ ...acc, [col]: false }), {})
  );

  return produce(mergedCols, (draft) => {
    if (hideDepartmentColumn && draft.department) {
      delete draft.department;
    }
  });
};

// salesByServiceReport Columns
export const salesByServiceDefaultCols = [
  "billingDate",
  "department",
  "packageName",
  "productCategory",
  "productName",
  "billInfo",
  "source",
  "quantity",
  "price",
  "billTotal"
];

export const salesByServiceReportHiddenCols = [
  "clientFullName",
  "serviceProvider",
  "enteredBy",
  "info"
];

// Stock Audit Report
export const stockAuditDefaultCols = [
  "productName",
  "supplierName",
  "supplierId",
  "expiryDate",
  "minimumStockQuantity",
  "avgPurchasePrice",
  "totalPurchasedQuantity",
  "quantity",
  "avgTotalPurchasePrice"
];
export const stockAuditHiddenCols = [
  "genericName",
  "productType",
  "unit",
  "package",
  "unitsPerPackage",
  "category",
  "manufactureBy"
];

// Stock Cash Flow Report
export const stockCashFlowDefaultCols = [
  "productName",
  "batchId",
  "date",
  "invoiceNumber",
  "transactionType",
  "quantity",
  "unit",
  "salesAmount",
  "discount",
  "netSalesAmount",
  "costOfSales",
  "profitLoss"
];

// Supplier Ledger Report
export const supplierLedgerDefaultCols = [
  "date",
  "supplierName",
  "agingDate",
  "voucherType",
  "reference",
  "paymentMode",
  "debit",
  "credit",
  "balance"
];

// Stock Transaction Report
export const stockTransactionDefaultCols = [
  "productName",
  "supplierName",
  "date",
  "batchId",
  "unitPriceIncVAT",
  "purchasePrice",
  "quantity",
  "discountAmt",
  "vatAmt",
  "grossTotal"
];

// patient flow report
export const patientFlowDefaultCols = [
  "name",
  "serviceProvider",
  "date",
  "services",
  "status",
  "reasonOfVisit"
];
export const patientFlowHiddenCols = [
  "clientPhone",
  "clientEmail",
  "clientDOB",
  "clientGender",
  "clientAddress",
  "clientName",
  "department"
];

// Patient Assessment Report
export const patientAssessmentDefaultCols = [
  "name",
  "serviceProvider",
  "date",
  "symptoms",
  "attachmentCount",
  "opdDuration",
  "diagnosis",
  "medication",
  "medicationRemark"
];
export const patientAssessmentHiddenCols = [
  "clientPhone",
  "clientGender",
  "clientEmail",
  "pastHistoryOfAllergy",
  "hopi",
  "status",
  "investigation",
  "presentHealthStatus",
  "treatmentPlan",
  "treatmentGiven",
  "advice",
  "others",
  "department"
];

// Lab Report
export const labReportDefaultCols = [
  "id",
  "clientName",
  "tests",
  "created_at",
  "type",
  "category",
  "collectionDate",
  "status",
  "turnAroundTime",
  "referrers"
];
export const labReportHiddenCols = ["clientAge", "clientGender", "testGroup"];

// Sales Report
export const salesDefaultCols = [
  "clientName",
  "billInfo",
  "registrationNo",
  "billingDate",
  "enteredBy",
  "paidAmount",
  "dueAmount",
  "totalAmount"
];
export const salesHiddenCols = [
  "services",
  "admissionDate",
  "dischargeDate",
  "bed",
  "creditNoteFor",
  "paymentMethod",
  "discount",
  "grossAmount"
];

// Commission Report
export const commissionDefaultCols = [
  "vendorName",
  "service",
  "client",
  "billNo",
  "rate",
  "labCharge",
  "netPayableToVendor"
];
export const commissionHiddenCols = [
  "serviceProvider",
  "qty",
  "productPriceExcVat",
  "serviceProviderRate",
  "discountPercent",
  "discountAmount",
  "netAmount",
  "paidAmount",
  "materialChargePercent",
  "materialChargeAmount",
  "standardPayable",
  "tds",
  "created_at"
];

// Due Report
export const dueReportDefaultCols = [
  "referrers",
  "clientName",
  "services",
  "billInfo",
  "enteredBy",
  "billingDate",
  "ageingDays",
  "receivableDue"
];
export const dueReportHiddenCols = ["taxableAmount", "cumPayment", "taxAmount", "totalAmount"];

// Receipt Report
export const receiptReportDefaultCol = [
  "documentNumber",
  "clientName",
  "enteredBy",
  "voucherType",
  "transactionType",
  "transactionDateBS",
  "paymentMethod",
  "amount",
  "serviceProviderCharge"
];

// Client Ledger Report
export const clientLedgerDefaultCols = [
  "transactionDate",
  "clientFullName",
  "voucherType",
  "documentNumber",
  "paymentMethod",
  "debit",
  "credit",
  "balance"
];

// Service Provider Charge Report
export const serviceProviderChargeDefaultCols = [
  "spName",
  "serviceName",
  "quantity",
  "billInfo",
  "billingDate",
  "discountedSpPayableAfterAllCharge",
  "unDiscountedSpPayableAfterAllCharge"
];
export const serviceProviderChargeHiddenCols = [
  "client",
  "productPrice",
  "discountPct",
  "discountAmt",
  "netAmount"
];

// client report
export const clientReportDefaultCols = [
  "id",
  "clientName",
  "age",
  "gender",
  "phone",
  "email",
  "knownUsFrom",
  "address",
  "lastVisitedAt"
];

export const clientReportHiddenCols = [
  "bloodGroup",
  "city",
  "ethnicity",
  "maritalStatus",
  "nationality",
  "occupation",
  "insuranceNumber",
  "internalNotes",
  "dob",
  "nationalIdentityNumber",
  "clientType",
  "customerNumber"
];
